import { useMemo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useContactService } from 'services';
import { PaymentProfileType } from 'type';
import { FormDataType } from '../../type';

const NO_CARD_DATA: PaymentProfileType = {
  id: '',
  name: "I'd like to use a new card",
  cardNumber: "I'd like to use a new card",
  preferred: false,
  expiryDate: '',
  expired: false,
};

const usePaymentProfile = () => {
  const { setValue, watch } = useFormContext<FormDataType>();

  const paymentProfile = watch('paymentProfile');
  const paymentProfilesData = useContactService((s) => s.paymentProfiles.data);

  const paymentProfilesSelections = useMemo(() => {
    const cards = paymentProfilesData ?? [];
    const cardsPreferredTop = cards.sort((a, b) => +b.preferred - +a.preferred);
    return [...cardsPreferredTop, NO_CARD_DATA];
  }, [paymentProfilesData]);

  const paymentProfileRef = useMemo(() => {
    return paymentProfilesSelections.find((s) => s.id === paymentProfile?.id);
  }, [paymentProfilesSelections, paymentProfile]);

  const handleSelectedPaymentProfile = (selected: PaymentProfileType) => {
    setValue('paymentProfile', selected);
  };

  useEffect(() => {
    if (paymentProfilesSelections[0]) {
      setValue('paymentProfile', paymentProfilesSelections[0]);
    }
  }, [paymentProfilesSelections, setValue]);

  return {
    paymentProfileRef,
    paymentProfilesSelections,
    handleSelectedPaymentProfile,
  };
};

export default usePaymentProfile;
