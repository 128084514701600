import { Dropdown } from 'components';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import tw from 'twin.macro';
import {
  EditFieldWrapper,
  Label,
  LabelWrapper,
  Section,
  Title,
} from '../../../../styles';

import { FormValues } from '../../types';

const PreferencesTitle = styled(Title)`
  ${tw`mb-10`}
`;

export const PreferencesForm = () => {
  const { control } = useFormContext<FormValues>();

  return (
    <Section>
      <PreferencesTitle>Preferences</PreferencesTitle>
      <EditFieldWrapper>
        <LabelWrapper>
          <Label>VRC Marketing</Label>
        </LabelWrapper>
        <Controller
          control={control}
          name="allowVrcMembershipMarketing"
          render={({ field: { onChange, value } }) => (
            <Dropdown
              selections={['Yes', 'No']}
              selection={value}
              onSelected={onChange}
              maxWidth="1000px"
              defaultSelection=""
              required
            />
          )}
        />
      </EditFieldWrapper>
    </Section>
  );
};
