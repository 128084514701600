import Dashboard from 'pages/dashboard/Dashboard';
import MemberRenewal from 'pages/membership/memberRenewal/MemberRenewal';
import MemberRenewalResponse from 'pages/membership/memberRenewal/MemberRenewalResponse';
import TransactionHistory from 'pages/transactionhistory/TransactionHistory';
import TransactionDetails from 'pages/transactionhistory/transaction/TransactionDetails';
import MembershipDetail from 'pages/membership/detail/MembershipDetail';
import PaymentRedirect from 'pages/payment/PaymentRedirect';
import { Greeting } from 'pages/greeting/Greeting';

type ProtectedRouteListProps = {
  path: string;
  name: string;
  component: JSX.Element;
};

export const getSideNavRoutes = (membershipId: string) => {
  const sideNavRoutes = MAIN_PROTECTED_ROUTE_LIST;
  return sideNavRoutes.map((route) => {
    return {
      ...route,
      path: route.path.replace(':membershipId', membershipId),
    };
  });
};

export const MAIN_PROTECTED_ROUTE_LIST: ProtectedRouteListProps[] = [
  {
    path: '/',
    name: 'MY VRC',
    component: <Dashboard />,
  },
  {
    path: '/membership/:membershipId/renewal',
    name: 'MY MEMBERSHIP',
    component: <MemberRenewal />,
  },
  {
    path: '/membership',
    name: 'MY DETAILS',
    component: <MembershipDetail />,
  },
  {
    path: '/transactions',
    name: 'TRANSACTION HISTORY',
    component: <TransactionHistory />,
  },
];

export const PROTECTED_ROUTE_LIST: ProtectedRouteListProps[] = [
  ...MAIN_PROTECTED_ROUTE_LIST,
  {
    path: '/transactions/:transactionId',
    name: 'TRANSACTION',
    component: <TransactionDetails />,
  },
  {
    path: '/membership/:memberId/detail',
    name: 'MEMBER RENEWAL',
    component: <MembershipDetail />,
  },
  {
    path: '/membership/renewal/response',
    name: 'MEMBER RENEWAL RESPONSE',
    component: <MemberRenewalResponse variant="response" />,
  },
  {
    path: '/membership/renewal/cancel',
    name: 'MEMBER RENEWAL CANCEL',
    component: <MemberRenewalResponse variant="cancel" />,
  },
  {
    path: '/membership/renewal/declined',
    name: 'MEMBER RENEWAL DECLINED',
    component: <MemberRenewalResponse variant="declined" />,
  },
  {
    path: '/payment/response',
    name: 'PAYMENT RESPONSE',
    component: <PaymentRedirect />,
  },
  {
    path: '/greetings',
    name: 'Greetings',
    component: <Greeting />,
  },
];
