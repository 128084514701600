import { useCallback, useRef } from 'react';

const useDebounce = (duration = 10) => {
  const debounceTimer = useRef<ReturnType<typeof setTimeout>>();

  const debounce = useCallback(async () => {
    await new Promise((resolve) => {
      if (debounceTimer.current) clearTimeout(debounceTimer.current);
      debounceTimer.current = setTimeout(resolve, duration);
      return debounceTimer.current;
    });
  }, [duration]);

  return { debounce };
};

export default useDebounce;
