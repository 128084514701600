import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RenewalResponse } from '..';
import { SaveSettingsFieldType } from 'type';
import { PageContainer } from 'components';

type HeaderType = {
  [key in SaveSettingsFieldType['action']]: {
    [key in SaveSettingsFieldType['decision']]: string;
  };
};

const HEADER_MAP: HeaderType = {
  payment: {
    ACCEPT: 'Payment Completed',
    CANCEL: 'Payment Cancelled',
    DECLINED: 'Payment Declined',
  },
  token: {
    ACCEPT: 'Payment Details Saved',
    CANCEL: 'Payment Details Cancelled',
    DECLINED: 'Payment Details Declined',
  },
};

interface IMemberRenewalResponseProps {
  variant: SaveSettingsFieldType['decision_url'];
}

const MemberRenewalResponse: React.FC<IMemberRenewalResponseProps> = ({
  variant,
}) => {
  const [params] = useSearchParams();
  const [headerText, setHeaderText] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');

  useEffect(() => {
    const action = params.get('action') as
      | SaveSettingsFieldType['action']
      | null;
    const decision = params.get('decision') as
      | SaveSettingsFieldType['decision']
      | null;
    const message = params.get('message') as string | null;

    if (action && decision) {
      setHeaderText(HEADER_MAP[action][decision]);
      setErrorText(decision === 'DECLINED' ? message ?? '' : '');
    }
  }, [params]);

  const iconVariant = variant === 'response' ? 'success' : 'fail';

  return (
    <PageContainer noSidebar>
      <RenewalResponse
        iconVariant={iconVariant}
        headerText={headerText}
        errorText={errorText}
      />
    </PageContainer>
  );
};

export default MemberRenewalResponse;
