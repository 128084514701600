import React, { useEffect, useRef } from 'react';
import { PaymentGatewayTransactionRequestType } from 'type';

type FieldType = keyof PaymentGatewayTransactionRequestType['payload'];

interface ICybersourceFormProps {
  data: PaymentGatewayTransactionRequestType;
}

const CybersourceForm: React.FC<ICybersourceFormProps> = ({
  data: { targetUrl, payload },
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    formRef.current?.submit();
  }, []);

  return (
    <form ref={formRef} action={targetUrl} method="post">
      {payload.signed_field_names.split(',').map((field) => {
        return (
          <input
            key={field}
            name={field}
            type="hidden"
            value={payload[field as FieldType] ?? ''}
          />
        );
      })}
      <input type="hidden" name="signature" value={payload.signature} />
    </form>
  );
};

export default CybersourceForm;
