import { PropsWithChildren, useMemo } from 'react';
import { useContactService } from 'services';
import {
  AppContainer,
  ContainerWrapper,
  ContentWrapper,
} from './CommonWrappers';
import LoginFailed from './LoginFailed';
import ErrorComponent from './ErrorComponent';
import { ScreenLoader } from 'components';
import { Header } from './Header';
import { SideBar } from './SideBar';
import { Greeting } from 'pages/greeting/Greeting';

interface IPageContainerProps extends PropsWithChildren<{}> {
  noSidebar?: boolean;
}

const PageContainer = ({
  children,
  noSidebar = false,
}: IPageContainerProps) => {
  const myContact = useContactService((s) => s.myContact);
  const myMemberships = useContactService((s) => s.myMemberships);

  const loading = myContact.loading || myMemberships.loading;
  const noData = !myContact.data || !myMemberships.data;

  const Content = useMemo(() => {
    if (noData) {
      return <LoginFailed />;
    }
    if (typeof myContact.data?.allowVrcMembershipMarketing !== 'boolean') {
      return <Greeting />;
    }
    return (
      <AppContainer>
        <Header />
        <ContainerWrapper>
          {!noSidebar && <SideBar />}
          <ContentWrapper>{children}</ContentWrapper>
        </ContainerWrapper>
      </AppContainer>
    );
  }, [
    noData,
    myContact.data?.allowVrcMembershipMarketing,
    noSidebar,
    children,
  ]);

  return (
    <>
      <ScreenLoader visible={loading} />
      <ErrorComponent />
      {Content}
    </>
  );
};

export default PageContainer;
