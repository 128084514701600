import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import 'styled-components/macro';
import { Text } from 'styles';
import { useDeviceSize } from 'hooks';
import { BorderButton } from 'components/shared/Button';
import { MoonLoader } from 'react-spinners';

const Container = styled.div<{ show: boolean }>(({ show }) => [
  tw`
  fixed
  inset-0
  z-50
  flex
  justify-center
  items-center
  transition
  duration-1000
  `,
  show ? tw`flex` : tw`hidden`,
]);

const Overlay = tw.div`
  absolute
  inset-0
  bg-black
  opacity-25
`;

const ContentContainer = tw.div`
  relative
  bg-secondaryCream
  max-w-lg
  w-full
  shadow
  m-3
  px-10 sm:px-24
  py-16
  flex
  flex-col
  justify-center
  items-center
`;

const SVGWrapper = tw.button`
  absolute
  top-0
  right-0
  m-4
`;

const Title = styled.h1`
  ${tw`mb-4`}
`;

const ButtonsContainer = tw.div`
  flex
  mt-6
`;

const CancelButton = styled(BorderButton)`
  letter-spacing: 0.1em;
  &:hover > p {
    color: white;
  }
  ${tw`
    uppercase mx-2 h-11 transition duration-300
    text-primaryPurple hover:text-white hover:bg-primaryPurple
  `}
`;

const ConfirmButton = styled(BorderButton)`
  letter-spacing: 0.1em;
  > p {
    color: white;
  }
  &:hover > p {
    color: white;
  }
  ${tw`
    uppercase mx-2 h-11 transition duration-300
    bg-primaryPurple hover:text-primaryPurple hover:bg-primaryBlue
  `}
`;

const LoaderContainer = tw.div`
  flex
  justify-center
  items-center
  w-32
`;

interface IPromptModalProps {
  title: string;
  description: string;
  buttonText: string;
  show: boolean;
  loading: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

const PromptModal: React.FC<IPromptModalProps> = ({
  title,
  description,
  buttonText,
  show,
  loading,
  handleClose,
  handleConfirm,
}) => {
  const { isMobile } = useDeviceSize();
  const responsiveTitle = isMobile ? Text.H5 : Text.H3;

  return (
    <Container show={show}>
      <Overlay />
      <ContentContainer>
        <SVGWrapper onClick={handleClose} disabled={loading}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            tw="h-8 w-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </SVGWrapper>
        <Title as={responsiveTitle} textAlign="center">
          {title}
        </Title>
        <Text.Normal textAlign="center">{description}</Text.Normal>
        <ButtonsContainer>
          <CancelButton onClick={handleClose} disabled={loading} type="button">
            <Text.SmallBold>Cancel</Text.SmallBold>
          </CancelButton>
          {loading ? (
            <LoaderContainer>
              <MoonLoader size={20} />
            </LoaderContainer>
          ) : (
            <ConfirmButton
              onClick={handleConfirm}
              disabled={loading}
              type="button"
            >
              <Text.SmallBold>{buttonText}</Text.SmallBold>
            </ConfirmButton>
          )}
        </ButtonsContainer>
      </ContentContainer>
    </Container>
  );
};

export default PromptModal;
