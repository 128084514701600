import { ItemType } from 'pages/membership';
import { PurchaseType } from 'type';

const formatUpgrade = (upgrade: ItemType | null) => {
  if (!upgrade) return null;
  if (upgrade.status === 'Purchased') return null;
  const product: PurchaseType['products'][0] = {
    id: upgrade.id,
    gst: upgrade.gst,
    quantity: upgrade.quantity,
    unitPriceExcGst: upgrade.priceExcGst,
    unitPriceIncGst: upgrade.priceIncGst,
  };
  return product;
};

export const purchaseHelpers = {
  formatUpgrade,
};
