import React from 'react';
import tw from 'twin.macro';
import { Text } from 'styles';
import { IMembershipProps } from './MembershipComponent';
import { MembershipHeaderWrapper } from './styles';
import { useContactService } from 'services';

const MemberName = tw(Text.H1)`
  text-h2 md:text-h1 font-Soehne
`;

export const MembershipHeader: React.FC<IMembershipProps> = () => {
  const myContact = useContactService((state) => state.myContact);
  const memberName = `${myContact.data?.personalDetails?.firstName || ''} ${
    myContact.data?.personalDetails?.lastName || ''
  }`;

  return (
    <MembershipHeaderWrapper>
      <MemberName color="white">{memberName}</MemberName>
    </MembershipHeaderWrapper>
  );
};
