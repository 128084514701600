import tw from 'twin.macro';
import styled from 'styled-components';

interface IInteractableProps {
  disabled?: boolean;
}

const Container = styled.div<IInteractableProps>(({ disabled }) => [
  tw`
    grid
    grid-cols-3
    divide-x
    divide-gray-300
    justify-between
    border
    bg-white
    w-24
    h-10
  `,
  disabled ? tw`opacity-80` : tw`opacity-100`,
  disabled ? tw`border-gray-300` : tw`border-primaryPurple`,
]);

const Control = styled.div<IInteractableProps>(({ disabled: _ }) => [
  tw`
    text-base
    text-primaryPurple
    flex
    justify-center
    items-center
    flex-col
    w-full
  `,
]);

const Button = styled.button<IInteractableProps>(({ disabled }) => [
  tw`
    w-full
    h-full
    text-lg
  `,
  disabled ? tw`opacity-50` : tw`opacity-100`,
  disabled ? tw`text-gray-500` : tw`text-primaryPurple`,
]);

interface IQuantitySelectorProps {
  quantityMax: number;
  quantityMin: number;
  quantity: number;
  onChangeQuantity: (nextQuantity: number) => void;
  disabled?: boolean;
}

const QuantitySelector: React.FC<IQuantitySelectorProps> = ({
  quantityMax,
  quantityMin,
  quantity = 0,
  onChangeQuantity,
  disabled = false,
}) => {
  const onChange = (increment = false) => {
    if (!disabled) {
      if (increment) {
        const nextQuantity = quantity + 1;
        if (nextQuantity <= quantityMax) {
          onChangeQuantity(nextQuantity);
        }
      } else {
        const nextQuantity = quantity - 1;
        if (nextQuantity >= quantityMin) {
          onChangeQuantity(nextQuantity);
        }
      }
    }
  };

  return (
    <Container disabled={disabled}>
      <Control disabled={disabled}>
        <Button
          disabled={disabled || quantity === quantityMin}
          type="button"
          onClick={() => onChange(false)}
        >
          -
        </Button>
      </Control>
      <Control>{quantity}</Control>
      <Control disabled={disabled}>
        <Button
          disabled={disabled || quantity === quantityMax}
          type="button"
          onClick={() => onChange(true)}
        >
          +
        </Button>
      </Control>
    </Container>
  );
};

export default QuantitySelector;
