import { PageContainer } from 'components';
import { MembershipDetailContent } from './MembershipDetailContent';

const MembershipDetail: React.FC = () => {
  return (
    <PageContainer>
      <MembershipDetailContent />
    </PageContainer>
  );
};
export default MembershipDetail;
