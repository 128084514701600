import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LogoAnimation } from 'components/Loaders/LogoAnimation';
import { useCheckSalesOrder, useDeviceSize } from 'hooks';
import { useContactService, usePersistentService } from 'services';
import { SaveSettingsFieldType } from 'type';
import { Text } from 'styles';
import { PaymentSalesOrderPending } from '.';

const Container = tw.div`
  mt-[5rem]
  sm:mt-[9.5rem]
  bg-secondaryCream
  fixed
  inset-0
  z-20
  flex
  flex-col
  justify-center
  items-center
  pb-72
`;

const Header = styled.h1`
  ${tw`mt-5 mb-3 sm:mb-8 relative z-20 text-center`}
`;

const HEADER_MAP: { [key in SaveSettingsFieldType['action']]: string } = {
  payment: 'Finalising Payment...',
  token: 'Saving Payment Details...',
};

const PATH_MAP: {
  [key in SaveSettingsFieldType['decision']]: SaveSettingsFieldType['decision_url'];
} = {
  ACCEPT: 'response',
  CANCEL: 'cancel',
  DECLINED: 'declined',
};

const SALES_ORDER_CHECK_SWITCH = 2;

interface IPaymentRedirectContentProps {}

const PaymentRedirectContent: React.FC<IPaymentRedirectContentProps> = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { isMobile } = useDeviceSize();
  const responsiveHeader = isMobile ? Text.H5 : Text.H2;

  const [headerText, setHeaderText] = useState<string>('');
  const [rotateAngle, setRotateAngle] = useState(0);
  const [salesOrderCheckCount, setSalesOrderCheckCount] = useState(0);

  const setPaymentProfileReload = usePersistentService(
    (s) => s.setPaymentProfileReload,
  );
  const shouldRefetchMembershipAfterSalesOrder = usePersistentService(
    (s) => s.shouldRefetchMembershipAfterSalesOrder,
  );
  const setShouldRefetchMembershipAfterSalesOrder = usePersistentService(
    (s) => s.setShouldRefetchMembershipAfterSalesOrder,
  );

  const action = params.get('action') as SaveSettingsFieldType['action'] | null;
  const message = params.get('message') as string | null;
  const decision = params.get('decision') as
    | SaveSettingsFieldType['decision']
    | null;

  const navPath: SaveSettingsFieldType['decision_url'] = decision
    ? PATH_MAP[decision]
    : 'declined';

  const finalPath = `/membership/renewal/${navPath}?action=${action}&decision=${decision}&message=${message}`;

  const membershipsData = useContactService((s) => s.myMemberships.data);
  const membershipId = membershipsData?.[0]?.id;
  const { salesOrderPending } = useCheckSalesOrder({
    membershipId,
    startAsPending: true,
    immediateFetch: false,
    checkInterval:
      salesOrderCheckCount < SALES_ORDER_CHECK_SWITCH ? 5000 : 10000,
    skip: action === 'token' || !shouldRefetchMembershipAfterSalesOrder,
    updateCheckCount: setSalesOrderCheckCount,
  });

  useEffect(() => {
    // if transaction is cancelled, immediately navigate to cancelled screen (no loading screen)
    if (decision === 'CANCEL') {
      return navigate(finalPath);
    }
    if (action) setHeaderText(HEADER_MAP[action]);
  }, [navigate, setHeaderText, decision, finalPath, action]);

  useEffect(() => {
    // if transaction is about payment profile, flag for profile refetching.
    if (action === 'token') {
      if (decision === 'ACCEPT') setPaymentProfileReload(true);
      setTimeout(() => navigate(finalPath), 3000);
    }
  }, [navigate, setPaymentProfileReload, action, decision, finalPath]);

  useEffect(() => {
    if (action === 'payment') {
      setShouldRefetchMembershipAfterSalesOrder(true);
      if (!salesOrderPending) navigate(finalPath);
    }
  }, [
    navigate,
    setShouldRefetchMembershipAfterSalesOrder,
    action,
    finalPath,
    salesOrderPending,
  ]);

  useEffect(() => {
    const rotate = setInterval(() => setRotateAngle((angle) => angle + 4), 15);
    return () => {
      clearInterval(rotate);
    };
  }, []);

  if (salesOrderCheckCount >= SALES_ORDER_CHECK_SWITCH) {
    return <PaymentSalesOrderPending visible />;
  }

  return (
    <Container>
      <div style={{ transform: `rotate(${rotateAngle}deg)` }}>
        <LogoAnimation visible size={100} />
      </div>
      <Header as={responsiveHeader}>{headerText}</Header>
    </Container>
  );
};

export default PaymentRedirectContent;
