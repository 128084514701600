import create from 'zustand';
import { DEFAULTPAGESTART, DEFAULTPAGECOUNT } from 'configs/constants';
import { formatError, getMembership } from 'helpers';
import { http } from 'configs';
import {
  APIResponseType,
  ErrorType,
  TransactionDetailType,
  TransactionListItemType,
  TransactionListType,
} from 'type';

const initialState = {
  transactions: {
    total: 0 as number,
    data: null as null | TransactionListItemType[],
    start: DEFAULTPAGESTART,
    count: DEFAULTPAGECOUNT,
    loading: true as boolean,
  },
  transaction: {
    data: null as null | TransactionDetailType,
    loading: true as boolean,
  },
  loading: {
    transactionId: null as null | string,
  },
  errors: null as null | ErrorType | undefined | string,
};

type TransactionsStoreState = typeof initialState;
type TransactionsStore = TransactionsStoreState & {
  getTransactions: ({
    membershipId,
    isNew,
    forceLoad,
  }: {
    membershipId?: string;
    isNew?: boolean;
    forceLoad?: boolean;
  }) => Promise<void>;
  getTransaction: ({
    membershipId,
    transactionId,
    isCaching,
  }: {
    membershipId?: string;
    transactionId: string;
    isCaching?: boolean;
  }) => Promise<TransactionDetailType | null>;
  viewTransaction: ({
    membershipId,
    transactionId,
  }: {
    membershipId: string;
    transactionId: string;
  }) => Promise<string | null>;
  setLoading: (loaders: Partial<typeof initialState.loading>) => void;
  clearTransactions: () => void;
  clearTransaction: () => void;
  clearErrors: () => void;
};

const useTransactionsService = create<TransactionsStore>((set, get) => {
  return {
    ...initialState,
    getTransactions: async ({ membershipId, isNew = false }) => {
      const safeMembershipId = membershipId ?? getMembership()?.id;
      if (!safeMembershipId) return;
      if (get().transactions.data && isNew) return;
      try {
        set({ transactions: { ...get().transactions, loading: true } });

        const start = isNew
          ? DEFAULTPAGESTART
          : get().transactions?.start + DEFAULTPAGECOUNT;

        const {
          data: { data, errors },
        }: APIResponseType<TransactionListType> = await http.get(
          `/memberships/${safeMembershipId}/transactions?start=${start}&count=${
            get().transactions?.count
          }`,
        );

        const newData = isNew
          ? data?.records
          : get().transactions.data?.concat(data.records);

        set({
          transactions: {
            data: newData || null,
            total: data?.total,
            start: start,
            count: data?.count,
            loading: false,
          },
          errors,
        });
      } catch (error) {
        set({ errors: formatError(error) });
      }
    },
    getTransaction: async ({
      membershipId,
      transactionId,
      isCaching = true,
    }) => {
      try {
        // TODO: Remove these mocks when having API responses for them.
        // This API is currently not used since not showing a transaction page, will deprecate.
        const { data, errors } = MOCK_TRANSACTION;
        // const safeMembershipId = membershipId ?? getMembership()?.id;
        // if (!safeMembershipId) return null;
        // const {
        //   data: { data, errors },
        // }: APIResponseType<TransactionDetailType> = await http.get(
        //   `/memberships/${safeMembershipId}/transactions/${transactionId}`,
        // );
        set({
          transaction: { data: isCaching ? data : null, loading: false },
          errors,
        });
        return data;
      } catch (e) {
        set({ errors: formatError(e) });
        return null;
      }
    },
    viewTransaction: async ({ membershipId, transactionId }) => {
      try {
        get().setLoading({ transactionId });
        const {
          data: { data, errors },
        }: APIResponseType<string> = await http.get(
          `/memberships/${membershipId}/transactions/${transactionId}/document`,
        );
        get().setLoading({ transactionId: null });
        if (errors) set({ errors });
        return data;
      } catch (error) {
        get().setLoading({ transactionId: null });
        set({ errors: formatError(error) });
        return null;
      }
    },
    setLoading: (loaders) => set({ loading: { ...get().loading, ...loaders } }),
    clearTransactions: () => {
      set({
        transactions: {
          data: null,
          total: 0,
          start: DEFAULTPAGESTART,
          count: DEFAULTPAGECOUNT,
          loading: false,
        },
        errors: null,
      });
    },
    clearTransaction: () => {
      set({ transaction: { data: null, loading: true }, errors: null });
    },
    clearErrors: () => set({ errors: null }),
  };
});

export default useTransactionsService;

// const MOCK_TRANSACTIONS = {
//   data: {
//     total: 4,
//     start: 1,
//     count: 4,
//     records: [
//       {
//         id: '6af4a9de-9903-ef11-9f89-000d3ae0c0b3',
//         reference: 'PMT-8020',
//         date: '1901-01-01T00:00:00',
//         amountIncGst: 41.25,
//         type: 'Invoice',
//       },
//       {
//         id: 'd6722c2d-9a03-ef11-9f89-000d3ae0c0b3',
//         reference: 'PMT-8021',
//         date: '1901-01-01T00:00:00',
//         amountIncGst: 27.5,
//         type: 'Invoice',
//       },
//       {
//         id: '9cf93f92-9903-ef11-9f8a-000d3ae1a958',
//         reference: 'PMT-8019',
//         date: '1901-01-01T00:00:00',
//         amountIncGst: 579.0,
//         type: 'Invoice',
//       },
//       {
//         id: 'c6f79b9a-5904-ef11-9f8a-000d3ae1ae48',
//         reference: 'PMT-8026',
//         date: '1901-01-01T00:00:00',
//         amountIncGst: 27.5,
//         type: 'Invoice',
//       },
//     ],
//   },
//   errors: null,
// };

const MOCK_TRANSACTION = {
  data: {
    id: '12345',
    type: 'Invoice',
    items: [
      {
        id: '12345',
        name: 'Product 1',
        quantity: 2,
        cashTotal: 10.99,
      },
      {
        id: 'P2',
        name: 'Product 2',
        quantity: 1,
        cashTotal: 5.99,
      },
    ],
    date: '2021-09-01',
    number: 123,
    price: {
      gst: 1.5,
      shipping: 'N/A',
      subTotal: 13.98,
      total: 16.98,
    },
  },
  errors: null,
};
