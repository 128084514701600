export const styles = {
  bmBurgerButton: {
    position: 'absolute',
    width: '48px',
    height: '48px',
    left: '-14px',
    top: '20px',
    padding: '14px',
  },
  bmBurgerBars: {
    background: '#201547',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '48px',
    width: '48px',
    top: '48px',
    left: '15px',
  },
  bmCross: {
    background: '#fff',
    height: '20px',
    top: '6px',
    left: '-10px',
  },
  bmMenuWrap: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: '0px',
    top: '0px',
  },
  bmMenu: {
    background: '#201547',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
  },
  bmItem: {
    display: 'inline-block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};
