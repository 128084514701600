import React from 'react';
import moment from 'moment';
import tw from 'twin.macro';
import 'styled-components/macro';
import { Text } from 'styles';
import { Button } from 'components';
import { useMediaQuery } from 'react-responsive';
import { deviceSize } from 'components/shared/types';
import { pdfHelpers } from 'helpers';
import { MoonLoader } from 'react-spinners';
import { useTransactionsService } from 'services';
import {
  StyledTransactionCard,
  DateContainer,
  TotalContainer,
  HiddenTextContainer,
  StyledButtonsContainer,
  StyledActionButton,
  Refund,
  DateInner,
  DateWrapper,
} from '../..';
import { InvoiceType } from 'configs/constants';
import { MembershipType, TransactionListItemType } from 'type';

const LoaderContainer = tw.span`flex text-left`;
const TransactionCardDetails = tw(Text.XSmallBold)`
  text-xs tracking-widest sm:mr-0.5 md:mr-2
`;

interface ITransactionCardProps {
  transaction: TransactionListItemType;
  myMembership: MembershipType | undefined;
}

const TransactionCard: React.FC<ITransactionCardProps> = ({
  transaction: { id, date, amountIncGst, reference, type },
  myMembership,
}) => {
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });
  const isTablet = useMediaQuery({ maxWidth: deviceSize.tablet });

  const viewTransaction = useTransactionsService((s) => s.viewTransaction);
  const loadingId = useTransactionsService((s) => s.loading.transactionId);
  const currentLoading = id === loadingId;

  const handleTransaction = async (download = false) => {
    const base64 = await viewTransaction({
      membershipId: myMembership?.id ?? '',
      transactionId: id,
    });
    if (!base64) return;
    if (!download) {
      pdfHelpers.preview(base64);
    } else {
      pdfHelpers.download(base64, `vrc_transaction_${reference}.pdf`);
    }
  };

  if (!myMembership) return null;

  return (
    <StyledTransactionCard
      isCenter={type === InvoiceType.RETURN ? true : false}>
      <DateContainer>
        <HiddenTextContainer>
          <Text.SmallBold color="#51504D">DATE</Text.SmallBold>
        </HiddenTextContainer>
        <DateWrapper>
          <DateInner>
            {isTablet ? (
              <Text.Normal color="#4C4581">
                {moment(date, 'YYYY-MM-DD').format('D MMMM YYYY')}
              </Text.Normal>
            ) : (
              <Text.Large color="#4C4581">
                {moment(date, 'YYYY-MM-DD').format('D MMMM YYYY')}
              </Text.Large>
            )}
          </DateInner>
          {type === InvoiceType.RETURN && <Refund>REFUND</Refund>}
        </DateWrapper>
      </DateContainer>
      <TotalContainer>
        <HiddenTextContainer>
          <Text.SmallBold color="#51504D">TOTAL</Text.SmallBold>
        </HiddenTextContainer>
        {isTablet ? (
          <Text.Normal color="#4C4581">{`$${amountIncGst.toFixed(
            2,
          )}`}</Text.Normal>
        ) : (
          <Text.Large color="#4C4581">{`$${amountIncGst.toFixed(
            2,
          )}`}</Text.Large>
        )}
      </TotalContainer>

      <StyledButtonsContainer>
        {currentLoading ? (
          <Button
            variant="secondary"
            borderWidth="0.18rem"
            paddingVertical={isMobile ? '0.4rem' : '0.6rem'}
            maxWidth={isTablet ? '100%' : '11.75rem'}
            disabled>
            <LoaderContainer>
              <MoonLoader size={18.5} speedMultiplier={0.6} />
            </LoaderContainer>
          </Button>
        ) : (
          <StyledActionButton
            variant="secondary"
            borderWidth="0.18rem"
            paddingVertical={isMobile ? '0.4rem' : '0.6rem'}
            maxWidth={isTablet ? '100%' : '11.75rem'}
            disabled={!!loadingId}
            onClick={() => handleTransaction(true)}>
            <TransactionCardDetails textAlign="center">
              DOWNLOAD PDF
            </TransactionCardDetails>
            {DOWNLOAD_SVG()}
          </StyledActionButton>
        )}
      </StyledButtonsContainer>
    </StyledTransactionCard>
  );
};

export default TransactionCard;

const DOWNLOAD_SVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    tw="h-4 w-4 sm:w-6 sm:h-6 text-primaryPurple flex-shrink-0">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
    />
  </svg>
);
