import React from 'react';

interface IScreenLoaderProps {
  visible: boolean;
}

const LoadingIndicator: React.FC<IScreenLoaderProps> = ({ visible }) => {
  if (!visible) return null;

  return (
    <div className="loading-container">
      <div className="loading loading-1">
        <div className="shape1"></div>
      </div>
      <div>
        <div className="loading loading-3">
          <div className="shape2"></div>
        </div>
        <div className="loading loading-4">
          <div className="shape2"></div>
        </div>
      </div>

      <div className="loading loading-2">
        <div className="shape2"></div>
        <div className="shape2"></div>
      </div>
    </div>
  );
};

export default LoadingIndicator;
