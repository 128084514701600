import { useMemo } from 'react';
import { PaymentOptionType } from 'pages/membership';
import {
  CalculatedInstalmentsType,
  MemberPaymentPlanType,
  PaymentPlanInstalmentType,
} from 'type';

type ReturnType = {
  payablePaymentPlan: MemberPaymentPlanType | null;
  payableInstalments: PaymentPlanInstalmentType[];
  payableInstalmentsAmount: number;
};

const getInstalmentPurchaseData = (
  paymentPlan: MemberPaymentPlanType | null,
  calculatedInstalments: CalculatedInstalmentsType | null,
  selectedInstalments: PaymentPlanInstalmentType[],
  payBalanceRemaining: boolean,
  paymentOption: PaymentOptionType,
): ReturnType => {
  const newOrUpdatedInstalments =
    calculatedInstalments?.newOrUpdatedPaymentPlan?.instalments ?? [];

  const selectedInstalmentsIds = selectedInstalments.map((i) => i.id);
  const mergedIds = newOrUpdatedInstalments
    .filter((i) => selectedInstalmentsIds.includes(i.id))
    .flatMap((i) => {
      return i.mergedInstalmentIds?.length ? i.mergedInstalmentIds : i.id;
    });

  const payablePaymentPlanInstalments = updateSelectedInstalmentsToPayNow(
    calculatedInstalments?.existingInstalments,
    mergedIds,
  );
  const payablePaymentPlanInstalmentsAmount = calculateInstalmentsAmount(
    payablePaymentPlanInstalments,
  );

  const payableInstalments = updateSelectedInstalmentsToPayNow(
    calculatedInstalments?.newInstalments,
    mergedIds,
  );
  const payableInstalmentsAmount =
    calculateInstalmentsAmount(payableInstalments);

  if (payBalanceRemaining) {
    return {
      payablePaymentPlan:
        paymentPlan && selectedInstalments.length
          ? {
              ...paymentPlan,
              ...(payablePaymentPlanInstalments.length
                ? { instalments: payablePaymentPlanInstalments }
                : {}),
            }
          : null,
      payableInstalments: !selectedInstalments.length ? [] : payableInstalments,
      payableInstalmentsAmount:
        paymentPlan?.status === 'Active'
          ? payablePaymentPlanInstalmentsAmount + payableInstalmentsAmount
          : payableInstalmentsAmount,
    };
  } else {
    return {
      payablePaymentPlan: null,
      payableInstalments: [],
      payableInstalmentsAmount: payableInstalmentsAmount,
    };
  }
};

const getOverdueInstalmentsData = (
  instalments: PaymentPlanInstalmentType[] | undefined,
) => {
  const overdueInstalments = updateSelectedInstalmentsToPayNow(
    instalments,
    instalments?.map((i) => i.id) ?? [],
  );
  const overdueInstalmentsAmount =
    calculateInstalmentsAmount(overdueInstalments);
  return { overdueInstalments, overdueInstalmentsAmount };
};

const updateSelectedInstalmentsToPayNow = (
  instalments: PaymentPlanInstalmentType[] | undefined,
  mergedIds: string[],
) => {
  return (instalments ?? []).map((instalment) => {
    return {
      ...instalment,
      status:
        mergedIds.includes(instalment.id) && instalment.status !== 'Paid'
          ? 'PayingNow'
          : instalment.status,
    };
  });
};

const calculateInstalmentsAmount = (
  instalments: PaymentPlanInstalmentType[] | undefined,
) => {
  return (instalments ?? []).reduce((acc, i) => {
    const amount = i.status === 'PayingNow' ? i.amountIncGst : 0;
    return acc + amount;
  }, 0);
};

const useInstalments = (
  instalments: PaymentPlanInstalmentType[],
  statuses: PaymentPlanInstalmentType['status'][],
  inverse: boolean = false,
) => {
  return useMemo(() => {
    return instalments.filter((i) => {
      const included = statuses.includes(i.status);
      return inverse ? !included : included;
    });
  }, [instalments, statuses, inverse]);
};

const hasInstalmentIds = (
  instalments: PaymentPlanInstalmentType[],
  id: string,
) => {
  return instalments.map((i) => i.id).includes(id);
};

export const instalmentHelpers = {
  getInstalmentPurchaseData,
  getOverdueInstalmentsData,
  calculateInstalmentsAmount,
  useInstalments,
  hasInstalmentIds,
};
