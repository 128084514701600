import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useContactService } from 'services';
import { FormDataType } from 'pages/membership';
import { PaymentPlanInstalmentType } from 'type';
import { instalmentHelpers } from './instalmentHelpers';

const useGapPayment = (
  newInstalments: PaymentPlanInstalmentType[] | undefined,
  missedInstalments: PaymentPlanInstalmentType[],
) => {
  const { watch } = useFormContext<FormDataType>();
  const myMembershipsData = useContactService((s) => s.myMemberships.data);
  const membership = myMembershipsData?.[0];

  const overduePlan = watch('overduePlan');
  const { overdueInstalmentsAmount } =
    instalmentHelpers.getOverdueInstalmentsData(overduePlan?.instalments);

  const missedInstalmentsAmount = useMemo(() => {
    return missedInstalments.reduce((acc, i) => {
      return acc + i.amountIncGst;
    }, 0);
  }, [missedInstalments]);

  const gapPaymentNotice = useMemo(() => {
    const isAutoRenew = membership?.autoRenew;
    const isCurrentSeason = membership?.season === 'Current';
    const newHasMissed = newInstalments?.some((i) => i.status === 'Overdue');
    return overduePlan ||
      (isCurrentSeason && newHasMissed) ||
      (isCurrentSeason && !isAutoRenew)
      ? 'overdue'
      : 'catchUp';
  }, [overduePlan, newInstalments, membership]);

  const gapPaymentAmount = useMemo(() => {
    return missedInstalmentsAmount + overdueInstalmentsAmount;
  }, [missedInstalmentsAmount, overdueInstalmentsAmount]);

  return {
    gapPaymentNotice,
    gapPaymentAmount,
    overdueInstalmentsAmount,
  } as const;
};

const useNextPayment = (
  selectedInstalments: PaymentPlanInstalmentType[],
  pendingInstalments: PaymentPlanInstalmentType[],
) => {
  return useMemo(() => {
    return selectedInstalments.length
      ? selectedInstalments.reduce((acc, i) => acc + i.amountIncGst, 0)
      : pendingInstalments[0]?.amountIncGst;
  }, [selectedInstalments, pendingInstalments]);
};

export const paymentHelpers = {
  useGapPayment,
  useNextPayment,
};
