import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { LogoAnimation } from './LogoAnimation';
import { VRCMemberHubPhoneLogo1 } from 'components/shared/svgs/logo/VRCMemberHubPhoneLogo1';

const MainContainer = styled.div<{ visible: boolean }>(({ visible }) => [
  tw`
    fixed inset-0 flex justify-center items-center
    bg-secondaryCream pb-16
    transition duration-500
    z-50
  `,
  visible ? tw`opacity-100` : tw`opacity-0`,
]);

const Container = tw.div`
  relative flex justify-center items-center
`;

const LogoContainer = tw.div`
  absolute transform scale-125
`;

interface IScreenLoaderProps {
  visible: boolean;
  size?: number;
  showLogo?: boolean;
}

const ScreenLoader: React.FC<IScreenLoaderProps> = ({
  visible,
  size = 250,
  showLogo = true,
}) => {
  const [rotateAngle, setRotateAngle] = useState(0);
  const [hideLoader, setHideLoader] = useState(false);

  useEffect(() => {
    const rotate = setInterval(() => setRotateAngle((angle) => angle + 4), 15);
    return () => {
      clearInterval(rotate);
    };
  }, []);

  useEffect(() => {
    if (visible) return;
    const timeout = setTimeout(() => {
      setHideLoader(true);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [visible, setHideLoader]);

  if (hideLoader) return null;

  return (
    <MainContainer visible={visible}>
      <Container>
        <div style={{ transform: `rotate(${rotateAngle}deg)` }}>
          <LogoAnimation visible={visible} size={size} />
        </div>
        {showLogo && (
          <LogoContainer>
            <VRCMemberHubPhoneLogo1 />
          </LogoContainer>
        )}
      </Container>
    </MainContainer>
  );
};

export default ScreenLoader;
