import tw from 'twin.macro';
import { Text } from 'styles';

export const RenewalBoxContainer = tw.div`
  bg-white
  border-2
  border-primaryPurple
  p-6 sm:p-8
`;

export const RenewalBoxTextContainer = tw.div`
  flex
  flex-col
`;

export const RenewalBoxTitle = tw(Text.LargeBold)`mb-2`;

const TextStyle = `
  text-primaryBlue
  align-top
  text-base
`;

export const RenewalBoxSpan = tw.span`${TextStyle}`;

export const RenewalBoxBoldSpan = tw(RenewalBoxSpan)`
  font-semibold
`;

export const RenewalBoxHyperLink = tw.a`${TextStyle} underline text-primaryPurple hover:text-primaryBlue`;
export const RenewalBoxHyperLinkUnderline = tw(RenewalBoxHyperLink)`underline`;
