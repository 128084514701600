import tw from 'twin.macro';
import styled from 'styled-components';
import 'styled-components/macro';
import { Text } from 'styles';
import { toUpper } from 'lodash';
import { CustomLink, ExternalLink, StyledLinkType } from 'components';
import { useMediaQuery } from 'react-responsive';
import { MemberElement } from './ShareCTA';
import { deviceSize } from 'components/shared/types';

import { DashboardLinkButton } from 'components/shared/CommonWrappers';
import { NavTileType } from 'type';

const Medium = styled(Text.Medium)`
  margin-bottom: 1.625rem;
  ${tw`
    text-white text-greyWhite mt-2 w-full
  `}
`;

const CTALinkContent: React.FC<{ member: NavTileType }> = ({ member }) => {
  return (
    <DashboardLinkButton color="white">
      {toUpper(member.ctaText)}
    </DashboardLinkButton>
  );
};

const linkStyle: StyledLinkType = {
  width: 'auto',
  borderWidth: '2px',
  borderColor: 'white',
  paddingVertical: '1rem',
  paddingHorizontal: '2.25rem',
  justifyContent: 'center',
};

export interface IMemberProps {
  member: NavTileType;
}
export const Member: React.FC<IMemberProps> = ({ member }) => {
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });
  return (
    <MemberElement>
      {isMobile ? (
        <Text.H5 color="white">{member.title}</Text.H5>
      ) : (
        <Text.H2 color="white">{member.title}</Text.H2>
      )}
      <Medium>{member.body}</Medium>
      {member.ctaLinkExternal ? (
        <ExternalLink
          to={member.ctaLink}
          {...linkStyle}
          color="#201547"
          hoverBorderColor="white"
        >
          <CTALinkContent member={member} />
        </ExternalLink>
      ) : (
        <CustomLink
          to={member.ctaLink}
          {...linkStyle}
          color="#201547"
          hoverBorderColor="white"
        >
          <CTALinkContent member={member} />
        </CustomLink>
      )}
    </MemberElement>
  );
};
