import create from 'zustand';
import { http } from 'configs';
import { formatError, getMembership } from 'helpers';
import {
  APIResponseType,
  CalculateInstalmentsRequestBodyType,
  CalculatedInstalmentsType,
  ErrorType,
  MemberPaymentPlanType,
  OffersPackageType,
  PaymentGatewayResponseType,
  PurchaseType,
} from 'type';

const initialState = {
  offers: {
    data: null as null | OffersPackageType,
    loading: true as boolean,
  },
  paymentPlans: {
    data: null as null | MemberPaymentPlanType[],
    loading: true as boolean,
  },
  preferredPlan: {
    data: null as null | MemberPaymentPlanType,
  },
  calculatedInstalments: {
    data: null as null | CalculatedInstalmentsType,
    loading: false as boolean,
  },
  loading: {
    purchase: false as boolean,
  },
  errors: null as ErrorsType,
};

type ErrorsType = null | ErrorType | undefined | string;

type MembershipsStoreState = typeof initialState;
type MembershipsStore = MembershipsStoreState & {
  getOffers: (membershipId?: string) => Promise<OffersPackageType | null>;
  getPaymentPlans: (membershipId?: string) => Promise<void>;
  getPreferredPlan: (membershipId?: string) => Promise<void>;
  postPurchase: (
    purchase: PurchaseType,
  ) => Promise<PaymentGatewayResponseType | null>;
  postCalculateInstalments: (
    membershipId: string,
    paymentPlanId: string,
    currentPlanAndItemsToAdd: CalculateInstalmentsRequestBodyType,
  ) => Promise<CalculatedInstalmentsType | null>;
  setLoading: (loaders: Partial<typeof initialState.loading>) => void;
  clearCalculatedInstalments: () => void;
  clearData: () => void;
  clearErrors: () => void;
};

const useMembershipService = create<MembershipsStore>((set, get) => {
  return {
    ...initialState,
    getOffers: async (membershipId) => {
      if (!membershipId) return null;
      try {
        set({ offers: { ...get().offers, loading: true } });
        const {
          data: { data, errors },
        }: APIResponseType<OffersPackageType> = await http.get(
          `/memberships/${membershipId}/offers`,
        );
        set({ offers: { data, loading: false }, errors });
        return data;
      } catch (error) {
        if ((error as any).response.status !== 400) {
          set({ errors: formatError(error) });
        }
        return null;
      }
    },
    getPaymentPlans: async (membershipId) => {
      if (!membershipId) return;
      const activePlanId =
        getMembership()?.paymentPlanInfo?.paymentPlanId ?? '';
      try {
        set({ paymentPlans: { ...get().paymentPlans, loading: true } });
        const {
          data: { data: rawData, errors },
        }: APIResponseType<MemberPaymentPlanType[]> = await http.get(
          `/memberships/${membershipId}/paymentplans?activePaymentPlanId=${activePlanId}`,
        );
        const data = activePlanId
          ? rawData
          : rawData.filter((p) => p.status !== 'Active');
        set({ paymentPlans: { data, loading: false }, errors });
      } catch (error) {
        set({ errors: formatError(error) });
      }
    },
    getPreferredPlan: async (membershipId) => {
      if (!membershipId) return;
      const activePlanId = getMembership()?.paymentPlanInfo?.paymentPlanId;
      const preferredPlanId = get().offers.data?.paymentPlanId;
      const data =
        get().paymentPlans.data?.find(
          (p) => p.id === (activePlanId ?? preferredPlanId),
        ) ?? null;
      set({ preferredPlan: { data } });
    },
    postCalculateInstalments: async (
      membershipId: string,
      paymentPlanId: string,
      currentPlanAndItemsToAdd: CalculateInstalmentsRequestBodyType,
    ) => {
      const instalments = get().calculatedInstalments;
      try {
        set({ calculatedInstalments: { ...instalments, loading: true } });
        const {
          data: { data, errors },
        }: APIResponseType<CalculatedInstalmentsType> = await http.post(
          `/memberships/${membershipId}/paymentplans/${paymentPlanId}/instalments`,
          currentPlanAndItemsToAdd,
        );
        set({ calculatedInstalments: { data, loading: false }, errors });
        return data;
      } catch (error) {
        set({
          calculatedInstalments: { ...instalments, loading: false },
          errors: formatError(error),
        });
        return null;
      }
    },
    postPurchase: async (purchase) => {
      try {
        get().setLoading({ purchase: true });
        const {
          data: { data, errors },
        }: APIResponseType<PaymentGatewayResponseType> = await http.post(
          `/transaction/purchase`,
          purchase,
        );
        if (errors) set({ errors });
        return data;
      } catch (error) {
        get().setLoading({ purchase: false });
        set({ errors: formatError(error) });
        return null;
      }
    },
    setLoading: (loaders) => set({ loading: { ...get().loading, ...loaders } }),
    clearCalculatedInstalments: () =>
      set({ calculatedInstalments: initialState.calculatedInstalments }),
    clearData: () => set(initialState),
    clearErrors: () => set({ errors: null }),
  };
});

export default useMembershipService;
