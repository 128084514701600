import { OutlinedButton } from 'pages/membership/detail/MyDetails/styles';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MoonLoader } from 'react-spinners';
import { useContactService } from 'services';
import tw from 'twin.macro';
import { PaymentGatewayTransactionRequestType } from 'type';
import { ConfirmAddUpdateModal } from './ConfirmAddUpdateModal';

const Container = tw.div`ml-3 py-3 md:py-7`;

export const LoaderContainer = tw.div`
  flex
  justify-center
  items-center
  w-28
`;

interface IAddNewCardProps {
  handleSetPaymentData: (data: PaymentGatewayTransactionRequestType) => void;
}

export const AddNewCard: React.FC<IAddNewCardProps> = ({
  handleSetPaymentData,
}) => {
  const { postPaymentProfile } = useContactService();
  const [loading, setLoading] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const {
    formState: { isDirty },
  } = useFormContext();

  const handleAddNewCard = async () => {
    setLoading(true);
    const res = await postPaymentProfile();
    res?.data && handleSetPaymentData(res.data);
    res?.errors && setLoading(false);
  };

  return (
    <Container>
      {loading ? (
        <LoaderContainer>
          <MoonLoader size={16} />
        </LoaderContainer>
      ) : (
        <OutlinedButton
          type="button"
          onClick={() => {
            if (isDirty) {
              setShowConfirmModal(true);
            } else {
              handleAddNewCard();
            }
          }}>
          Add New Card
        </OutlinedButton>
      )}
      <ConfirmAddUpdateModal
        show={showConfirmModal}
        loading={loading}
        handleClose={() => setShowConfirmModal(false)}
        handleConfirm={handleAddNewCard}
      />
    </Container>
  );
};
