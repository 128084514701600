import styled from 'styled-components';
import tw from 'twin.macro';
import { deviceSize } from 'components/shared/types';
import { Text } from 'styles';
import * as Constants from '../../configs/constants';

export const MembershipWrapper = styled.div<{ me: boolean }>(
  ({ me = false }) => [
    tw`
      relative p-6 md:pl-9 md:pr-5 md:pt-8 md:pb-7 min-h-[310px]
    `,
    me ? tw`bg-primaryPurple` : tw`bg-primaryBlue`,
  ],
);

export const MembershipRealWrapper = styled(MembershipWrapper)`
  &:before {
    position: absolute;
    content: '';
    background-image: url(${Constants.BASE_ABSOLUTE_URL}/images/vrc_bg_logo.svg);
    right: 0;
    width: 30vh;
    height: 100%;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 55vh;
    overflow: hidden;
    top: 0;
    opacity: 0.1;
  }
`;

export const MembershipDetailRealWrapper = styled(MembershipRealWrapper)`
  ${tw`overflow-hidden md:flex md:flex-col md:min-h-[216px]`}
`;

export const DetailedWrapper = styled.div<{ isBorder?: boolean }>(
  ({ isBorder = true }) => [
    tw`
    relative pt-3 md:pt-5 text-left w-full block md:flex pb-10 border-b
  `,
    isBorder
      ? `border-color: #362c59;`
      : `border:none !important; padding-bottom:0 !important`,
  ],
);

export const Detailed = styled.div`
  @media only screen and (min-width: ${deviceSize.desktop}px) {
    margin-right: 4.625rem !important;
  }
  ${tw`
    flex md:block text-left flex-shrink md:mr-8 my-2 items-center grid grid-cols-3
  `}
`;

export const LoadDetailed = styled(Detailed)`
  @media only screen and (min-width: ${deviceSize.tablet}px) {
    max-width: 6.25rem;
  }
`;

export const DetailedLast = styled(Detailed)`
  margin-right: 0 !important;
  ${tw`
    flex-grow
  `}
`;

export const PaymentAlertWrapper = styled.div`
  ${tw`
    flex flex-wrap pt-6 justify-start items-center relative
  `}
`;

export const Renewal = styled.span<{ me: boolean }>(({ me = false }) => [
  `
  font-size:0.625rem;
  width:fit-content;
  `,
  tw`
    mt-4 md:mt-0 flex bg-primaryBlue rounded-md py-1 px-3 text-white text-center items-center tracking-widest font-semibold
  `,
  me ? tw`bg-primaryBlue` : tw`bg-primaryPurple`,
]);

export const LinkedMember = styled(Renewal)`
  background-color: #403871;
  ${tw`
    mt-0
    ml-10
  `}
`;

export const MembershipHeaderWrapper = styled.div`
  ${tw`
    relative flex justify-start
  `}
`;

export const Arrow = styled.div`
  ${tw`
    absolute right-4 text-white text-lg
  `}
  &:before {
    position: absolute;
    content: '\f105';
    top: 50%;
    transform: translateY(-50%);
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
  }
`;

export const MembershipDate = styled(Text.Medium)`
  margin: 0 0.625rem;
  ${tw`
    text-sm md:text-md text-white
  `}
`;
