import tw from 'twin.macro';
import { LoaderCard, LoaderCardWrapper } from 'components/Loaders/LoaderCard';
import { FieldList, FieldWrapper, LabelWrapper } from './styles';

export const Wrapper = tw.div`
  relative flex flex-col h-full
  bg-none md:bg-white
`;

export const Loader = () => {
  return (
    <Wrapper>
      <FieldList>
        <FieldWrapper>
          <LabelWrapper>
            <LoaderCardWrapper width="5rem">
              <LoaderCard
                height={20}
                backgroundColor="#efeee9"
                foregroundColor="#f9f8f2"
              />
            </LoaderCardWrapper>
          </LabelWrapper>
          <LoaderCardWrapper width="8rem">
            <LoaderCard
              height={20}
              backgroundColor="#efeee9"
              foregroundColor="#f9f8f2"
            />
          </LoaderCardWrapper>
        </FieldWrapper>
        <FieldWrapper>
          <LabelWrapper>
            <LoaderCardWrapper width="5rem">
              <LoaderCard
                height={20}
                backgroundColor="#efeee9"
                foregroundColor="#f9f8f2"
              />
            </LoaderCardWrapper>
          </LabelWrapper>
          <LoaderCardWrapper width="10rem">
            <LoaderCard
              height={20}
              backgroundColor="#efeee9"
              foregroundColor="#f9f8f2"
            />
          </LoaderCardWrapper>
        </FieldWrapper>
        <FieldWrapper>
          <LabelWrapper>
            <LoaderCardWrapper width="5rem">
              <LoaderCard
                height={20}
                backgroundColor="#efeee9"
                foregroundColor="#f9f8f2"
              />
            </LoaderCardWrapper>
          </LabelWrapper>
          <LoaderCardWrapper width="8rem">
            <LoaderCard
              height={20}
              backgroundColor="#efeee9"
              foregroundColor="#f9f8f2"
            />
          </LoaderCardWrapper>
        </FieldWrapper>
      </FieldList>
    </Wrapper>
  );
};
