import { useState } from 'react';
import { BodyWrapper, Title, TitleWrapper, Wrapper } from '../styles';
import { EditPersonalInformation } from './EditPersonalInformation/EditPersonalInformation';
import { ViewPersonalInformation } from './ViewPersonalInformation/ViewPersonalInformation';
import { EditButtons } from './components/EditButtons';

export const PersonalInformation = () => {
  const [isEdit, setIsEdit] = useState(false);

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>Personal Information</Title>
        {!isEdit && <EditButtons handleEdit={() => setIsEdit(true)} />}
      </TitleWrapper>
      <BodyWrapper>
        {isEdit ? (
          <EditPersonalInformation handleFinishEdit={() => setIsEdit(false)} />
        ) : (
          <ViewPersonalInformation />
        )}
      </BodyWrapper>
    </Wrapper>
  );
};
