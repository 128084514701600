import tw from 'twin.macro';

export const RowSubContainer = tw.div`
  flex
  items-center justify-between gap-2
  flex-col items-end
  sm:flex-row sm:items-center
  w-full
`;

export const RowButtonsContainer = tw.div`
  flex
  justify-end
`;

export const ButtonContainer = tw.div`
  flex
  justify-center
  items-center
  w-14
`;

export const LoaderContainer = tw.div`
  flex
  justify-center
  items-center
  w-28
`;

export const PreferredCol = tw.div`w-[52px] md:w-[64px] flex items-center justify-center -mt-12 md:-mt-5`;
