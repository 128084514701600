import React from 'react';
import { LoaderCard } from 'components/Loaders/LoaderCard';
import { Divider } from 'components';
import { PRICES, useResponsivePrice } from '../utils';
import { Container, FlexRowContainer, Price, PriceText } from './styles';

interface IRenewalMembershipLoaderProps {}

const RenewalMembershipLoader: React.FC<IRenewalMembershipLoaderProps> = () => {
  const { responsivePriceLabel } = useResponsivePrice();

  return (
    <Container>
      <FlexRowContainer>
        <LoaderCard
          width="10rem"
          height={40}
          backgroundColor="#efeee9"
          foregroundColor="#f9f8f2"
        />
        <LoaderCard
          width="5rem"
          height={40}
          backgroundColor="#efeee9"
          foregroundColor="#f9f8f2"
        />
      </FlexRowContainer>

      <Divider height="2px" marginVertical="4rem" bgColor="#201547" />
      <Divider marginVertical="-0.5rem" bgColor="transparent" />

      <FlexRowContainer>
        <LoaderCard
          width="10rem"
          height={40}
          backgroundColor="#efeee9"
          foregroundColor="#f9f8f2"
        />
        <LoaderCard
          width="5rem"
          height={40}
          backgroundColor="#efeee9"
          foregroundColor="#f9f8f2"
        />
      </FlexRowContainer>

      <Divider height="2px" marginVertical="3rem" bgColor="#E1E1DF" />
      <Divider marginVertical="-0.5rem" bgColor="transparent" />

      <FlexRowContainer>
        <LoaderCard
          width="10rem"
          height={40}
          backgroundColor="#efeee9"
          foregroundColor="#f9f8f2"
        />
        <LoaderCard
          width="5rem"
          height={40}
          backgroundColor="#efeee9"
          foregroundColor="#f9f8f2"
        />
      </FlexRowContainer>

      <Divider height="3px" marginVertical="4rem" bgColor="#201547" />
      <Divider marginVertical="-1rem" bgColor="transparent" />

      {PRICES.map(({ label, isShown }, index) => {
        return isShown ? (
          <Price key={`price_${index}`}>
            <PriceText as={responsivePriceLabel}>{label}</PriceText>
            <LoaderCard
              width="5rem"
              height={40}
              backgroundColor="#efeee9"
              foregroundColor="#f9f8f2"
            />
          </Price>
        ) : null;
      })}
    </Container>
  );
};

export default RenewalMembershipLoader;
