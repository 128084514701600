import { TransitionOpacityExitContainer } from 'components/Animations/Animation';
import { TransationEnterType } from 'components/Animations/type';
import { SplashLogo } from 'components/shared/svgs/logo/SplashLogo';

export const LogoAnimation: React.FC<TransationEnterType> = ({
  visible = false,
  duration = 500,
  size = 266,
}) => {
  return (
    <TransitionOpacityExitContainer
      duration={duration}
      startOpacity={1}
      endOpacity={0}
    >
      {visible && <SplashLogo size={size} />}
    </TransitionOpacityExitContainer>
  );
};
