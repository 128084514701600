import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useContactService, useTransactionsService } from 'services';
import { Divider, PageContainer } from 'components';
import { useForceRedirect } from 'hooks';
import MemberRenewalContent from './MemberRenewalContent';

interface IMemberRenewalProps {}

const MemberRenewal: React.FC<IMemberRenewalProps> = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { clearTransactions } = useTransactionsService();

  const myMembershipsData = useContactService((s) => s.myMemberships.data);
  const membership = myMembershipsData?.[0];
  const isMembershipOffer = membership?.status === 'Offer';
  const noSidebar = !isMembershipOffer;

  useEffect(() => {
    const shouldRedirect = pathname === `/membership/${membership?.id}/renewal`;
    if (isMembershipOffer && shouldRedirect) {
      navigate('/', { replace: true });
    }
  }, [isMembershipOffer, pathname, membership, navigate]);

  useEffect(clearTransactions, [clearTransactions]);

  useForceRedirect({ triggerCondition: () => isMembershipOffer });

  return (
    <PageContainer noSidebar={noSidebar}>
      {!isMembershipOffer && (
        <>
          <MemberRenewalContent />
          <Divider marginVertical="-3rem" bgColor="transparent" />
        </>
      )}
    </PageContainer>
  );
};

export default MemberRenewal;
