import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import 'styled-components/macro';

interface IStyledCheckboxProps {
  checked: boolean;
  showTick?: boolean;
  showOutline?: boolean;
  disabled?: boolean;
}

const StyledCheckbox = styled.div<ICheckboxProps>(
  ({ checked, showTick, showOutline, disabled }) => [
    tw`
    flex
    flex-shrink-0
    justify-center
    items-center
    w-[33px]
    h-[33px]
    transition
    duration-200
    cursor-pointer
  `,
    showTick
      ? tw`bg-transparent cursor-default`
      : tw`border-2 border-primaryPurple`,
    showTick ? tw`` : checked ? tw`bg-primaryPurple` : tw`bg-white`,
    showOutline ? tw`border-2 border-secondaryWarmGrey` : tw``,
    disabled ? tw`opacity-10 bg-transparent cursor-default` : tw``,
  ],
);

interface ICheckboxProps extends IStyledCheckboxProps {}

const Checkbox: React.FC<ICheckboxProps> = ({
  checked,
  showTick,
  showOutline,
  disabled,
}) => {
  if (showTick) {
    return (
      <StyledCheckbox checked showTick showOutline={showOutline}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          tw="h-6 w-6 text-primaryPurple">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 13l4 4L19 7"
          />
        </svg>
      </StyledCheckbox>
    );
  }

  return (
    <StyledCheckbox checked={checked} disabled={disabled}>
      {checked && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          tw="h-6 w-6 text-white">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 13l4 4L19 7"
          />
        </svg>
      )}
    </StyledCheckbox>
  );
};

export default Checkbox;
