import { useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { BannerElement } from './BannerElement';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../../styles/scss/custom.banner.scss';

import { LoaderFull } from 'components/Loaders/BannerLoader/LoaderFull';
import { useDashboardService } from 'services';
import { BASE_ABSOLUTE_URL } from 'configs';

const BannerWrapper = styled.div`
  ${tw`
    w-full
    flex
    flex-col
    relative
  `}
`;
const PageIndicator = styled.div`
  top: 85.5%;
  @media only screen and (max-width: 800px) {
    top: 87%;
  }
  ${tw`
    flex absolute text-white text-sm px-6 md:px-12 items-center -translate-y-1/2
  `}
`;
const Line = styled.span`
  ${tw`
    w-4 h-0.5 m-2 mt-2 bg-white opacity-40
  `}
`;
const EmptyLoaderWrapper = styled.div`
  ${tw`
    relative
    w-full
    max-h-[445px]
    overflow-hidden
  `}
`;
const EmptyLoaderInnerWrapper = styled.div`
  padding-top: 36.5%;
  @media only screen and (max-width: 800px) {
    padding-top: 80%;
  }
  ${tw`
    w-full
  `}
`;
export function Banner() {
  const { data } = useDashboardService();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  return (
    <BannerWrapper>
      {(!data || !imageLoaded) && (
        <EmptyLoaderWrapper>
          <EmptyLoaderInnerWrapper>
            <LoaderFull />
          </EmptyLoaderInnerWrapper>
        </EmptyLoaderWrapper>
      )}
      {data?.banners && (
        <Carousel
          preventMovementUntilSwipeScrollTolerance
          swipeScrollTolerance={50}
          dynamicHeight={false}
          showIndicators={false}
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          renderArrowPrev={(clickHandler) => (
            <BannerArrow direction="left" clickHandler={clickHandler} />
          )}
          renderArrowNext={(clickHandler) => (
            <BannerArrow direction="right" clickHandler={clickHandler} />
          )}
          onChange={setCurrentIndex}>
          {data?.banners?.map((banner, index) => (
            <BannerElement
              key={`banner_${index}`}
              banner={banner}
              updateMeLoaded={setImageLoaded}
            />
          ))}
        </Carousel>
      )}
      {data && (
        <PageIndicator>
          {currentIndex + 1}
          <Line />
          {data?.banners?.length}
        </PageIndicator>
      )}
    </BannerWrapper>
  );
}

interface IBannerArrowProps {
  direction: 'left' | 'right';
  clickHandler: () => void;
}

const BannerArrow = ({ direction, clickHandler }: IBannerArrowProps) => {
  return (
    <button
      type="button"
      onClick={clickHandler}
      className={`control-arrow ${
        direction === 'left' ? 'control-prev' : 'control-next'
      }`}
      style={{
        transform: direction === 'left' ? 'rotate(180deg)' : 'rotate(0deg)',
      }}
      data-gtm-banner-arrow={direction}>
      <img
        src={`${BASE_ABSOLUTE_URL}/icons/arrow.png`}
        alt={`banner_arrow_${direction}`}
      />
    </button>
  );
};
