import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from './Form';
import { schema } from '../schema';
import { FormValues } from '../types';
import { ContactType } from 'type';

interface IFormWrapperProps {
  data: ContactType;
  handleFinishEdit: () => void;
}

export const FormWrapper: React.FC<IFormWrapperProps> = ({
  data,
  handleFinishEdit,
}) => {
  const methods = useForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      phoneNumbers: data.phoneNumbers,
      addresses: data.addresses,
      industry: data.industry,
      occupation: data.occupation,
    },
  });

  return (
    <FormProvider {...methods}>
      <Form handleFinishEdit={handleFinishEdit} data={data} />
    </FormProvider>
  );
};
