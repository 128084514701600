import tw from 'twin.macro';
import { useContactService } from 'services';
import styled from 'styled-components';
import { Text } from 'styles';
import { AlertTriangleIcon } from 'components/shared/svgs/AlertTriangleIcon';
import { AlertCircleIcon } from 'components/shared/svgs/AlertCircleIcon';

const Wrapper = styled.div<{
  showPayNow?: boolean;
}>(({ showPayNow = false }) => [
  tw`flex gap-4 mt-6 p-4 items-center`,
  showPayNow
    ? tw`bg-primaryPink text-primaryPurple`
    : tw`bg-primaryBlue text-white`,
]);

const SVGWrapper = tw.div`w-5 h-5 flex-none`;

export const MembershipAlert = () => {
  const myMemberships = useContactService((state) => state.myMemberships.data);
  const membership = myMemberships?.[0];
  const renewalMessage = membership?.renewalMessage;

  if (!renewalMessage) return null;

  return (
    <Wrapper showPayNow={renewalMessage.showPayNow}>
      <SVGWrapper>
        {renewalMessage.showPayNow ? (
          <AlertTriangleIcon />
        ) : (
          <AlertCircleIcon />
        )}
      </SVGWrapper>
      <Text.Medium color="inherit">{renewalMessage.message}</Text.Medium>
    </Wrapper>
  );
};
