import {
  OfferType,
  AdditionalProductGroupType,
  PurchasedProductType,
} from 'type';

const getPurchasedOffer = (offers: OfferType[] | null | undefined) => {
  return offers?.find(({ status }) => status === 'Purchased');
};

const available = ({ status }: { status: OfferType['status'] }) => {
  return status !== 'Disabled';
};

const offersComparator = (a: OfferType, b: OfferType) => {
  const alphabetComparator = a.name.localeCompare(b.name);
  const typeComparator = a.type === b.type ? 0 : a.type === 'Primary' ? -2 : 2;
  return alphabetComparator + typeComparator;
};

const productGroupsComparator = (
  a: AdditionalProductGroupType,
  _b: AdditionalProductGroupType,
) => {
  const typeMap: {
    [key in AdditionalProductGroupType['groupingType']]: number;
  } = {
    AllMandatory: -2,
    OneMandatory: -1,
    AllOptional: 1,
    OneOptional: 2,
  };
  return typeMap[a.groupingType];
};

const purchasedProductsComparator = (
  a: PurchasedProductType,
  _b: PurchasedProductType,
) => {
  return a.group === 'JoiningFee' ? 1 : -1;
};

const purchasedProductsSorted = (purchasedProducts: PurchasedProductType[]) => {
  const lastGroup = 'JoiningFee';
  const last = purchasedProducts.find((p) => p.group === lastGroup);
  const rest = purchasedProducts.filter((p) => p.group !== lastGroup);
  return [...rest, last].filter(Boolean) as PurchasedProductType[];
};

export const offerHelpers = {
  getPurchasedOffer,
  available,
  offersComparator,
  productGroupsComparator,
  purchasedProductsComparator,
  purchasedProductsSorted,
};
