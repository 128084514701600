import { useContactService } from 'services';
import { Loader } from '../../Loader';
import { FormWrapper } from './components/FormWrapper';

interface IEditPersonalInformationProps {
  handleFinishEdit: () => void;
}

export const EditPersonalInformation: React.FC<
  IEditPersonalInformationProps
> = ({ handleFinishEdit }) => {
  const myContactData = useContactService((state) => state.myContact.data);

  if (!myContactData) {
    return <Loader />;
  }

  return (
    <FormWrapper handleFinishEdit={handleFinishEdit} data={myContactData} />
  );
};
