import tw from 'twin.macro';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { LogoAnimation } from 'components/Loaders/LogoAnimation';
import { useDeviceSize } from 'hooks';
import { Text } from 'styles';

const Container = styled.div(({ visible }: IPaymentSalesOrderPendingProps) => [
  tw`
  mt-[5rem]
  sm:mt-[9.5rem]
  bg-secondaryCream
  fixed
  inset-0
  z-20
  flex
  flex-col
  justify-center
  items-center
  pb-40
  transition
  duration-700
  `,
  visible ? tw`opacity-100` : tw`opacity-0 pointer-events-none`,
]);

const Header = styled.h1`
  ${tw`mt-5 mb-3 relative z-20 text-center`}
`;

const P = styled.p`
  ${tw`my-3 relative z-20 text-center`}
`;

interface IPaymentSalesOrderPendingProps {
  visible: boolean;
}

const PaymentSalesOrderPending = ({
  visible,
}: IPaymentSalesOrderPendingProps) => {
  const { isMobile } = useDeviceSize();
  const responsiveHeader = isMobile ? Text.H5 : Text.H2;
  const responsiveP = isMobile ? Text.Small : Text.Normal;

  const [rotateAngle, setRotateAngle] = useState(0);

  useEffect(() => {
    const rotate = setInterval(() => setRotateAngle((angle) => angle + 4), 15);
    return () => {
      clearInterval(rotate);
    };
  }, []);

  return (
    <Container visible={visible}>
      <div style={{ transform: `rotate(${rotateAngle}deg)` }}>
        <LogoAnimation visible size={100} />
      </div>
      <Header as={responsiveHeader}>Finalising payment...</Header>
      <P as={responsiveP}>
        We're still processing your order,
        <br />
        this won't be long!
      </P>
      <P as={responsiveP}>
        Please do not refresh the page
        <br />
        or close this screen.
      </P>
    </Container>
  );
};

export default PaymentSalesOrderPending;
