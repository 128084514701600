import { NO_PREFEREED_PHONE_LABEL } from 'configs';
import { formatAddress } from 'helpers';
import { useContactService } from 'services';
import { Loader } from '../../Loader';
import { PersonalContentCell, PersonalLabelPreferred } from '../../styles';
import {
  BorderedSections,
  Section,
  FieldWrapper,
  FieldList,
  Label,
  LabelWrapper,
} from '../../styles';
import { BasicInfo } from '../components/BasicInfo';
import { useMemo } from 'react';

export const ViewPersonalInformation = () => {
  const myContactData = useContactService((state) => state.myContact.data);

  const isPersonalMobilePreferred =
    myContactData?.phoneNumbers?.personalMobile.label ===
    myContactData?.phoneNumbers?.preferredPhone;

  const isBusinessPhonePreferred =
    myContactData?.phoneNumbers?.businessPhone.label ===
    myContactData?.phoneNumbers?.preferredPhone;

  const isBusinessMobilePreferred =
    myContactData?.phoneNumbers?.businessMobile.label ===
    myContactData?.phoneNumbers?.preferredPhone;

  const physicalAddress = formatAddress(myContactData?.addresses?.physical);

  const postalAddress = useMemo(() => {
    const address = formatAddress(myContactData?.addresses?.postal);
    if (!address) return '';
    return address === physicalAddress ? 'Same as Physical Address' : address;
  }, [physicalAddress, myContactData?.addresses?.postal]);

  const billingAddress = useMemo(() => {
    const address = formatAddress(myContactData?.addresses?.billing);
    if (!address) return '';
    return address === physicalAddress ? 'Same as Physical Address' : address;
  }, [physicalAddress, myContactData?.addresses?.billing]);

  return (
    <BorderedSections>
      <Section first>
        <BasicInfo isEdit={false} />
      </Section>

      {/* Phone Numbers */}
      <Section>
        {myContactData && (
          <FieldList>
            <FieldWrapper>
              <LabelWrapper>
                <Label>
                  {myContactData?.phoneNumbers?.personalMobile.label}
                </Label>
                {isPersonalMobilePreferred && (
                  <PersonalLabelPreferred>
                    {NO_PREFEREED_PHONE_LABEL}
                  </PersonalLabelPreferred>
                )}
              </LabelWrapper>

              <PersonalContentCell>
                {myContactData?.phoneNumbers?.personalMobile.value}
              </PersonalContentCell>
            </FieldWrapper>
            {myContactData?.phoneNumbers?.businessPhone && (
              <FieldWrapper>
                <LabelWrapper>
                  <Label>
                    {myContactData?.phoneNumbers?.businessPhone.label}
                  </Label>
                  {isBusinessPhonePreferred && (
                    <PersonalLabelPreferred>
                      {NO_PREFEREED_PHONE_LABEL}
                    </PersonalLabelPreferred>
                  )}
                </LabelWrapper>

                <PersonalContentCell>
                  {myContactData?.phoneNumbers?.businessPhone.value}
                </PersonalContentCell>
              </FieldWrapper>
            )}
            {myContactData?.phoneNumbers?.businessMobile && (
              <FieldWrapper>
                <LabelWrapper>
                  <Label>
                    {myContactData?.phoneNumbers?.businessMobile.label}
                  </Label>
                  {isBusinessMobilePreferred && (
                    <PersonalLabelPreferred>
                      {NO_PREFEREED_PHONE_LABEL}
                    </PersonalLabelPreferred>
                  )}
                </LabelWrapper>

                <PersonalContentCell>
                  {myContactData?.phoneNumbers?.businessMobile.value}
                </PersonalContentCell>
              </FieldWrapper>
            )}
          </FieldList>
        )}
        {!myContactData && <Loader />}
      </Section>

      {/* Address */}
      <Section>
        {myContactData && (
          <FieldList>
            {myContactData.addresses?.physical && (
              <FieldWrapper>
                <LabelWrapper>
                  <Label>{myContactData.addresses?.physical?.label}</Label>
                </LabelWrapper>
                <PersonalContentCell>{physicalAddress}</PersonalContentCell>
              </FieldWrapper>
            )}

            {myContactData.addresses?.postal && (
              <FieldWrapper>
                <LabelWrapper>
                  <Label>{myContactData.addresses?.postal?.label}</Label>
                </LabelWrapper>
                <PersonalContentCell>{postalAddress}</PersonalContentCell>
              </FieldWrapper>
            )}

            {myContactData.addresses?.billing && (
              <FieldWrapper>
                <LabelWrapper>
                  <Label>{myContactData.addresses?.billing?.label}</Label>
                </LabelWrapper>
                <PersonalContentCell>{billingAddress}</PersonalContentCell>
              </FieldWrapper>
            )}
          </FieldList>
        )}
        {!myContactData && <Loader />}
      </Section>

      {/* Other */}
      <Section last>
        {myContactData && (
          <FieldList>
            <FieldWrapper>
              <LabelWrapper>
                <Label>Industry</Label>
              </LabelWrapper>

              <PersonalContentCell>
                {myContactData.industry?.name}
              </PersonalContentCell>
            </FieldWrapper>
            <FieldWrapper>
              <LabelWrapper>
                <Label>Occupation</Label>
              </LabelWrapper>

              <PersonalContentCell>
                {myContactData.occupation}
              </PersonalContentCell>
            </FieldWrapper>
          </FieldList>
        )}
        {!myContactData && <Loader />}
      </Section>
    </BorderedSections>
  );
};
