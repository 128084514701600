import { useEffect, useRef, useState } from 'react';
import {
  useContactService,
  useMembershipService,
  usePersistentService,
} from 'services';

interface IUseCheckSalesOrderProps {
  membershipId: string | undefined;
  startAsPending?: boolean;
  immediateFetch?: boolean;
  skip?: boolean;
  checkInterval?: number;
  updateCheckCount?: (func: (value: number) => number) => void;
}

const useCheckSalesOrder = ({
  membershipId,
  startAsPending = false,
  immediateFetch = true,
  checkInterval = 5000,
  skip = false,
  updateCheckCount,
}: IUseCheckSalesOrderProps) => {
  const immediateFetchRef = useRef(immediateFetch);
  const initialIntervalRef = useRef(checkInterval);
  const [salesOrderPending, setSalesOrderPending] = useState(startAsPending);

  const getOffers = useMembershipService((s) => s.getOffers);
  const getMyMemberships = useContactService((s) => s.getMyMemberships);
  const getPaymentProfiles = useContactService((s) => s.getPaymentProfiles);

  const shouldRefetchMembershipAfterSalesOrder = usePersistentService(
    (s) => s.shouldRefetchMembershipAfterSalesOrder,
  );
  const setShouldRefetchMembershipAfterSalesOrder = usePersistentService(
    (s) => s.setShouldRefetchMembershipAfterSalesOrder,
  );

  useEffect(() => {
    if (!membershipId || skip) return;
    let interval: ReturnType<typeof setInterval>;
    const tryGetOffers = async () => {
      if (checkInterval !== initialIntervalRef.current) return;
      if (!immediateFetchRef.current) {
        immediateFetchRef.current = true;
        return false;
      }
      const res = await getOffers(membershipId);
      if (res) {
        clearInterval(interval);
        // Membership at BE will not update immediately, delay needed.
        await new Promise((resolve) => setTimeout(resolve, 3000));
        await getPaymentProfiles({ refetch: true });
        if (shouldRefetchMembershipAfterSalesOrder) {
          await getMyMemberships({ refetch: true });
          setShouldRefetchMembershipAfterSalesOrder(false);
        }
      }
      setSalesOrderPending(!res);
      updateCheckCount?.((prev) => prev + 1);
      return !!res;
    };
    (async () => {
      const hasOffer = await tryGetOffers();
      if (!hasOffer) {
        initialIntervalRef.current = checkInterval;
        interval = setInterval(tryGetOffers, checkInterval);
      }
    })();
    return () => {
      clearInterval(interval);
    };
  }, [
    membershipId,
    checkInterval,
    skip,
    shouldRefetchMembershipAfterSalesOrder,
    getOffers,
    getMyMemberships,
    getPaymentProfiles,
    setSalesOrderPending,
    setShouldRefetchMembershipAfterSalesOrder,
    updateCheckCount,
  ]);

  return { salesOrderPending };
};

export default useCheckSalesOrder;
