import React, { useMemo } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Text } from 'styles';
import { Divider, Dropdown } from 'components';
import { FormDataType, PaymentOptionType } from '../type';
import { useFormContext } from 'react-hook-form';
import { useMembershipService } from 'services';
import { LoaderCard } from 'components/Loaders/LoaderCard';
import { PaymentProfileExpiry } from 'pages/membership/detail/MyDetails/styles';

import usePaymentProfile from './hooks/usePaymentProfile';
import usePaymentPlan from './hooks/usePaymentPlan';

import PaymentOptionCard from './PaymentOptionCard';
import PaymentFull from './PaymentFull';
import PaymentInstalment from '../paymentInstalments/PaymentInstalment';

const PAYMENT_OPTIONS: PaymentOptionType[] = [
  'Pay in full',
  'Pay in instalments',
];

const CardsContainer = styled.div<{ hide: boolean }>(({ hide }) => [
  tw`
  grid
  grid-cols-2
  gap-x-2
  md:gap-x-4
  gap-y-2
  my-8 md:my-10`,
  hide ? tw`hidden` : tw``,
]);

const PaymentCardContainer = tw.div`
  grid
  grid-cols-8
  items-center
  mt-10 md:mt-16 mb-6
`;

const PaymentCardLabelContainer = tw.div`
  col-span-8 md:col-span-3
  flex
  flex-col
  pr-4
`;

const PaymentCardDropdownContainer = tw.div`
  col-span-8 md:col-span-5
`;

const PaymentCardDropdownNameContainer = tw.div`
  flex flex-col gap-y-0.5 md:flex-row md:items-center
`;

const PaymentCardDropdownNameCardData = tw.div`
  max-w-[15.5rem] w-full
`;

const PaymentCardExpiredText = tw(Text.Small)`
  col-span-8 md:col-span-5 text-failedRed -mt-6 md:-mt-20
`;

const PaymentCardLabel = tw(Text.Large)`
  flex-shrink-0
  mr-24
  mb-2 md:mb-2
`;

const PaymentCardLabelDescription = tw(Text.Small)`
  mb-5 md:-mb-1.5
`;

const CardContainer = styled.label<{ selected: boolean }>(({ selected }) => [
  tw`
  col-span-2
  sm:col-span-1
  transition
  duration-300
  border-[0.1rem]
  hover:border-primaryPurple
`,
  selected ? tw`border-primaryPurple` : tw`border-transparent`,
]);

const Input = tw.input`hidden`;

export const AsteriskContainer = tw.span`absolute left-[-7px] top-[2px]`;

interface IPaymentOptionsProps {}

const PaymentOptions: React.FC<IPaymentOptionsProps> = () => {
  const { register, watch } = useFormContext<FormDataType>();

  const offersPackageData = useMembershipService((s) => s.offers.data);
  const paymentPlansData = useMembershipService((s) => s.paymentPlans.data);
  const calculatingInstalments = useMembershipService(
    (s) => s.calculatedInstalments.loading,
  );

  const paymentOption = watch('paymentOption');
  const currentTotalAmount = +watch('totalAmount');
  const canPurchase = currentTotalAmount > 0;

  const {
    paymentProfileRef,
    paymentProfilesSelections,
    handleSelectedPaymentProfile,
  } = usePaymentProfile();

  const { handleUpdatePaymentPlan } = usePaymentPlan();

  const allPaidInFull = useMemo(() => {
    if (offersPackageData) {
      const purchasedProducts = offersPackageData.purchasedProducts;
      return purchasedProducts.every((p) => !p.purchasedOnPaymentPlan);
    } else {
      return false;
    }
  }, [offersPackageData]);

  const hidePaymentOptions = useMemo(() => {
    if (!offersPackageData) return true;
    if (canPurchase) return false;
    if (allPaidInFull) return true;
    return false;
  }, [offersPackageData, canPurchase, allPaidInFull]);

  if (!paymentPlansData) return null;

  return (
    <section>
      <Text.LargeBold>Payment</Text.LargeBold>
      <Divider marginVertical="-1rem" bgColor="transparent" />

      {!hidePaymentOptions && (
        <Divider marginVertical="2.2rem" bgColor="transparent" />
      )}
      <CardsContainer hide={hidePaymentOptions}>
        {PAYMENT_OPTIONS.map((name) => {
          const selected = name === paymentOption;
          return (
            <CardContainer key={name} htmlFor={name} selected={selected}>
              <Input
                {...register('paymentOption')}
                id={name}
                type="radio"
                name="paymentOption"
                value={name}
                disabled={calculatingInstalments}
              />
              <PaymentOptionCard text={name} selected={selected} />
            </CardContainer>
          );
        })}
      </CardsContainer>

      <PaymentCardContainer>
        <PaymentCardLabelContainer>
          <PaymentCardLabel>Payment details</PaymentCardLabel>
          <PaymentCardLabelDescription
            color="#51504D"
            style={{ position: 'relative' }}>
            <AsteriskContainer>*</AsteriskContainer>You can change your card at
            any time. Changing your payment details will set this as your
            preferred card.
          </PaymentCardLabelDescription>
        </PaymentCardLabelContainer>
        <PaymentCardDropdownContainer>
          {!paymentProfileRef ? (
            <LoaderCard
              height={42}
              backgroundColor="#efeee9"
              foregroundColor="#f9f8f2"
            />
          ) : (
            <Dropdown
              selections={paymentProfilesSelections}
              selection={paymentProfileRef}
              onSelected={handleSelectedPaymentProfile}
              keyExtractor={({ id }) => id}
              nameExtractor={(card, index) => {
                const { id, cardNumber, expiryDate, expired } = card;
                return (
                  <PaymentCardDropdownNameContainer>
                    <PaymentCardDropdownNameCardData>
                      {id ? `Card ${index + 1} - ${cardNumber}` : cardNumber}
                    </PaymentCardDropdownNameCardData>
                    {!!id && (
                      <PaymentProfileExpiry expired={expired}>
                        {expired ? 'Expired' : 'Expires'} {expiryDate}
                      </PaymentProfileExpiry>
                    )}
                  </PaymentCardDropdownNameContainer>
                );
              }}
              maxWidth="100%"
            />
          )}
        </PaymentCardDropdownContainer>
      </PaymentCardContainer>

      {paymentProfileRef?.expired && (
        <PaymentCardContainer>
          <PaymentCardLabelContainer />
          <PaymentCardExpiredText>
            The selected payment card has expired. You will be required to
            update it during checkout.
          </PaymentCardExpiredText>
        </PaymentCardContainer>
      )}

      {paymentOption === 'Pay in full' && (
        <PaymentFull hasInstalmentPlan={!allPaidInFull} />
      )}
      {paymentOption === 'Pay in instalments' && (
        <PaymentInstalment onChangePaymentPlan={handleUpdatePaymentPlan} />
      )}
    </section>
  );
};

export default PaymentOptions;
