import create from 'zustand';
import { persist } from 'zustand/middleware';
import { AuthenticationResult } from '@azure/msal-browser';

type AuthorityType = 'signIn' | 'passwordReset';

const initialState = {
  authToken: null as null | AuthenticationResult,
  authority: 'signIn' as AuthorityType,
  paymentProfileReload: false as boolean,
  loginRedirectPath: '' as string,
  shouldRefetchMembershipAfterSalesOrder: false as boolean,
};

type PersistentStoreState = typeof initialState;
type PersistentStore = PersistentStoreState & {
  setAuthToken: (authToken: null | AuthenticationResult) => void;
  setAuthority: (authority: AuthorityType) => void;
  setPaymentProfileReload: (paymentProfileReload: boolean) => void;
  setLoginRedirectPath: (loginRedirectPath: string) => void;
  setShouldRefetchMembershipAfterSalesOrder: (
    shouldRefetchMembershipAfterSalesOrder: boolean,
  ) => void;
  reset: () => void;
};

const usePersistentService = create<PersistentStore>(
  persist(
    (set, _get) => ({
      ...initialState,
      setAuthToken: (authToken) => set({ authToken }),
      setAuthority: (authority) => set({ authority }),
      setPaymentProfileReload: (paymentProfileReload) => {
        set({ paymentProfileReload });
      },
      setLoginRedirectPath: (loginRedirectPath) => set({ loginRedirectPath }),
      setShouldRefetchMembershipAfterSalesOrder: (
        shouldRefetchMembershipAfterSalesOrder,
      ) => {
        set({ shouldRefetchMembershipAfterSalesOrder });
      },
      reset: () => set(initialState),
    }),
    {
      name: 'vrc.persistent.store',
      getStorage: () => localStorage,
    },
  ),
);

export default usePersistentService;
