import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import 'styled-components/macro';
import { CustomLink } from 'components';
import { Text } from 'styles';

interface IStyledChevronProps {
  color: string;
}

interface IStyledLinkContainerProps extends IStyledChevronProps {
  hoverColor: string;
}

const StyledLinkContainer = styled.div<IStyledLinkContainerProps>(
  ({ color, hoverColor }) => [
    tw`flex`,
    `
  > p {
    color: ${color};
    transition-duration: 0.3s;
  }
  :hover > p {
    color: ${hoverColor};
  }
  `,
  ],
);

const StyledChevron = styled.p<IStyledChevronProps>(({ color }) => [
  `color: ${color};
    `,
  tw`
      mt-1
    `,
]);

const StyledNormalText = styled(Text.Normal)<{ color: string }>(
  ({ color = '#201547' }) => [
    tw`
  relative font-normal tracking-normal
  `,
    `
  &:after {
    content:'';
    background:${color};
    position:absolute;
    width:100%;
    height:1px;
    bottom:0;
    left:0;
  `,
  ],
);
const StyledLargeText = styled(Text.XLarge)`
  ${tw`
    relative font-normal tracking-normal
  `}
  &:after {
    content: '';
    ${tw`
      absolute w-full h-px bg-white bottom-0 left-0
    `}
  }
`;
const StyledSmallText = styled(Text.Small)`
  ${tw`
  relative font-normal tracking-normal
  `}
  &:after {
    content: '';
    ${tw`
    absolute w-full h-px bg-white bottom-0 left-0
  `}
  }
`;

interface IBackLinkProps {
  to: string;
  text: string;
  textSize?: 'xl' | 'md' | 'sm';
  color?: string;
  hoverColor?: string;
}

const BackLink: React.FC<IBackLinkProps> = ({
  to,
  text,
  color = '#201547',
  hoverColor = '#4c4581',
  textSize = 'md',
}) => {
  return (
    <CustomLink to={to}>
      <StyledLinkContainer color={color} hoverColor={hoverColor}>
        <StyledChevron color={color}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            tw="h-4 w-4 mr-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </StyledChevron>
        {textSize === 'md' && (
          <StyledNormalText color={color}>{text}</StyledNormalText>
        )}
        {textSize === 'xl' && (
          <StyledLargeText color={color}>{text}</StyledLargeText>
        )}
        {textSize === 'sm' && (
          <StyledSmallText color={color}>{text}</StyledSmallText>
        )}
      </StyledLinkContainer>
    </CustomLink>
  );
};

export default BackLink;
