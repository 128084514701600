import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTransactionsService } from 'services';
import { BackLink } from 'components';
import {
  Container,
  TransactionDetailsData,
  TransactionDetailsLoadingData,
} from '..';

interface ITransactionDetailsContentProps {}

const TransactionDetailsContent: React.FC<
  ITransactionDetailsContentProps
> = () => {
  const { transactionId } = useParams<{ transactionId: string }>();
  const {
    transaction: { data, loading },
    getTransaction,
    clearTransaction,
  } = useTransactionsService();

  useEffect(() => {
    if (transactionId) getTransaction({ transactionId });
  }, [getTransaction, transactionId]);

  useEffect(() => clearTransaction, [clearTransaction]);

  return (
    <Container>
      <BackLink to="/transactions" text="Back to Transaction History" />
      {loading ? (
        <TransactionDetailsLoadingData />
      ) : data ? (
        <TransactionDetailsData data={data} />
      ) : null}
    </Container>
  );
};

export default TransactionDetailsContent;
