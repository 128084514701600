import React from 'react';
import tw from 'twin.macro';
import { Text } from 'styles';
import { useFormContext } from 'react-hook-form';
import { useDeviceSize } from 'hooks';
import { Toggle } from 'components';
import { FormDataType } from '../type';
import {
  RenewalBoxContainer,
  RenewalBoxTextContainer,
  RenewalBoxTitle,
  RenewalBoxSpan,
} from '../styles';

const StyledPayBalanceRemainingCard = tw.div`
  bg-neutralMidGrey
  flex
  justify-between
  sm:items-center
  p-4
  sm:px-8 sm:py-7
  mt-3 mb-4 -mx-3
`;

interface IPayBalanceRemainingCardProps {}

const PayBalanceRemainingCard: React.FC<IPayBalanceRemainingCardProps> = () => {
  const { setValue, watch } = useFormContext<FormDataType>();
  const { isMobile } = useDeviceSize();

  const payBalanceRemaining = watch('payBalanceRemaining');

  return (
    <RenewalBoxContainer>
      <RenewalBoxTextContainer>
        <RenewalBoxTitle>Pay balance remaining</RenewalBoxTitle>

        <StyledPayBalanceRemainingCard>
          <Text.Normal>
            Yes, I'd like to also pay my remaining instalment balance
          </Text.Normal>
          <Toggle
            toggle={payBalanceRemaining}
            onToggle={() =>
              setValue('payBalanceRemaining', !payBalanceRemaining)
            }
            scale={isMobile ? 0.9 : 1.2}
          />
        </StyledPayBalanceRemainingCard>

        <p>
          <RenewalBoxSpan>
            By selecting this option, your existing instalment balance will also
            be paid in full alongside your additional optional products.
          </RenewalBoxSpan>
        </p>
      </RenewalBoxTextContainer>
    </RenewalBoxContainer>
  );
};

export default PayBalanceRemainingCard;
