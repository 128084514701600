import styled from 'styled-components';
import tw from 'twin.macro';
import { Text } from 'styles';
import { toUpper } from 'lodash';
import { ANNOUNCEMENT_THEME_TYPE } from 'configs/constants';
import { ExternalLink, CustomLink, StyledLinkType } from 'components';
import { DashboardLinkButton } from 'components/shared/CommonWrappers';
import { AnnouncementType } from 'type';
import { AnnouncementPageType, useDashboardService } from 'services';
import { useMemo } from 'react';
import { trackGTMEvent } from 'helpers/trackGTMEvent';

const chooseTheme = (theme: string = ANNOUNCEMENT_THEME_TYPE.GREEN) => {
  switch (theme) {
    case ANNOUNCEMENT_THEME_TYPE.GREY:
      return tw`bg-neutralGrey text-primaryPurple`;
    case ANNOUNCEMENT_THEME_TYPE.PINK:
      return tw`bg-primaryPink text-primaryPurple`;
    case ANNOUNCEMENT_THEME_TYPE.RED:
      return tw`bg-lightRed text-white`;
    default:
      return tw`bg-lightGreen text-primaryPurple`;
  }
};

const chooseColor = (theme: string = ANNOUNCEMENT_THEME_TYPE.RED) => {
  switch (theme) {
    case ANNOUNCEMENT_THEME_TYPE.RED:
      return 'white';
    default:
      return '#201547';
  }
};

export interface AnnouncementTheme {
  theme: ANNOUNCEMENT_THEME_TYPE;
}

const NoticeWrapper = styled.div<AnnouncementTheme>(
  ({ theme = ANNOUNCEMENT_THEME_TYPE.GREEN }) => [
    tw`
      mt-6 mx-4 md:mx-8 p-6 md:p-8 block lg:flex items-center justify-between
  `,
    chooseTheme(theme),
  ],
);

const NoticeContentWrapper = styled.div`
  ${tw`
    mr-6
  `}
`;

const NoticeContentTitle = styled(Text.H5)`
  ${tw`
    tracking-wide md:text-h4
  `}
`;

const Small = styled(Text.Small)`
  ${tw`
    mt-3 mb-5 lg:mb-0
  `}
`;

const linkStyle: StyledLinkType = {
  borderWidth: '2px',
  paddingVertical: '1rem',
  paddingHorizontal: '2.25rem',
  justifyContent: 'center',
};

const AnnouncementContent: React.FC<{
  theme: string | ANNOUNCEMENT_THEME_TYPE;
  announcement: AnnouncementType;
}> = ({ theme, announcement }) => (
  <DashboardLinkButton textAlign="center" color={chooseColor(theme)}>
    {toUpper(announcement.ctaText) || ``}
  </DashboardLinkButton>
);

interface AnnouncementProps {
  type: AnnouncementPageType;
}

export const Announcement: React.FC<AnnouncementProps> = ({ type }) => {
  const data = useDashboardService((state) => state.data);
  const announcement = useMemo(() => {
    return data?.announcements?.find(({ page }) => page === type);
  }, [data, type]);

  const showButton = !!announcement?.ctaText && !!announcement?.ctaLink;
  const theme = announcement?.theme || ANNOUNCEMENT_THEME_TYPE.PINK;
  const color = chooseColor(theme);

  return (
    <>
      {announcement && (
        <NoticeWrapper theme={theme}>
          <NoticeContentWrapper>
            <NoticeContentTitle color={color}>
              {announcement.title}
            </NoticeContentTitle>
            <Small color={color}>{announcement.body}</Small>
          </NoticeContentWrapper>
          <div
            onClick={() =>
              trackGTMEvent('announcement_cta_click', {
                header_text: announcement.title,
                cta_text: announcement.ctaText,
              })
            }>
            {showButton &&
              (announcement.ctaLinkExternal ? (
                <ExternalLink
                  to={announcement.ctaLink}
                  borderColor={color}
                  {...linkStyle}
                  color="#201547"
                  hoverBorderColor="white"
                  iconColor={color}>
                  <AnnouncementContent
                    theme={theme}
                    announcement={announcement}
                  />
                </ExternalLink>
              ) : (
                <CustomLink
                  to={announcement.ctaLink}
                  borderColor={color}
                  {...linkStyle}
                  color="#201547"
                  hoverBorderColor="white">
                  <AnnouncementContent
                    theme={theme}
                    announcement={announcement}
                  />
                </CustomLink>
              ))}
          </div>
        </NoticeWrapper>
      )}
    </>
  );
};
