import { PageContainer } from 'components';
import { DashboardContent } from './DashboardContent';

const Dashboard: React.FC = () => {
  return (
    <PageContainer>
      <DashboardContent />
    </PageContainer>
  );
};
export default Dashboard;
