import React from 'react';
import { Text } from 'styles';
import { useMediaQuery } from 'react-responsive';
import { deviceSize } from 'components/shared/types';
import { LoaderCard } from 'components/Loaders/LoaderCard';
import {
  StyledTransactionCard,
  DateContainer,
  HiddenTextContainer,
  TotalContainer,
  StyledButtonsContainer,
} from './TransactionCardStyle';

interface ITransactionLoadingCardProps {}

const TransactionLoadingCard: React.FC<ITransactionLoadingCardProps> = () => {
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });

  return (
    <StyledTransactionCard>
      <DateContainer>
        <HiddenTextContainer>
          <Text.SmallBold color="#51504D">DATE</Text.SmallBold>
        </HiddenTextContainer>
        <LoaderCard
          height={isMobile ? 30 : 50}
          width={isMobile ? '80%' : '60%'}
          backgroundColor="#efeee9"
          foregroundColor="#f9f8f2"
        />
      </DateContainer>
      <TotalContainer>
        <HiddenTextContainer>
          <Text.SmallBold color="#51504D">TOTAL</Text.SmallBold>
        </HiddenTextContainer>
        <LoaderCard
          height={isMobile ? 30 : 50}
          width={isMobile ? '90%' : '100%'}
          backgroundColor="#efeee9"
          foregroundColor="#f9f8f2"
        />
      </TotalContainer>
      <StyledButtonsContainer>
        <LoaderCard
          height={isMobile ? 30 : 50}
          width={isMobile ? '100%' : '60%'}
          backgroundColor="#efeee9"
          foregroundColor="#f9f8f2"
        />
      </StyledButtonsContainer>
    </StyledTransactionCard>
  );
};

export default TransactionLoadingCard;
