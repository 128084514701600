import { SubmitButton } from 'components';
import { Wrapper, TitleWrapper, Title, BodyWrapper, Buttons } from '../styles';
import { ViewPaymentSettings } from './ViewPaymentSettings/ViewPaymentSettings';
import { EditPaymentSettings } from './EditPaymentSettings/EditPaymentSettings';
import { useEffect, useState } from 'react';
import { useContactService, usePersistentService } from 'services';

export const PaymentSettings = () => {
  const [isEdit, setIsEdit] = useState(false);
  const getPaymentProfiles = useContactService((s) => s.getPaymentProfiles);

  const paymentProfileReload = usePersistentService(
    (s) => s.paymentProfileReload,
  );
  const setPaymentProfileReload = usePersistentService(
    (s) => s.setPaymentProfileReload,
  );

  useEffect(() => {
    getPaymentProfiles();
    const refetchProfiles = setTimeout(async () => {
      if (!paymentProfileReload) return;
      await getPaymentProfiles({ refetch: true });
      setPaymentProfileReload(false);
    }, 15000);
    return () => {
      clearTimeout(refetchProfiles);
    };
  }, [getPaymentProfiles, paymentProfileReload, setPaymentProfileReload]);

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>Payment Settings</Title>
        {!isEdit && (
          <Buttons>
            <SubmitButton
              value="Edit Details"
              onClick={() => setIsEdit(true)}
              width="auto"
              maxWidth="none"
              paddingHorizontal="1.75rem"
            />
          </Buttons>
        )}
      </TitleWrapper>
      <BodyWrapper>
        {isEdit ? (
          <EditPaymentSettings handleFinishEdit={() => setIsEdit(false)} />
        ) : (
          <ViewPaymentSettings />
        )}
      </BodyWrapper>
    </Wrapper>
  );
};
