import { deviceSize } from 'components/shared/types';
import { useMediaQuery } from 'react-responsive';
import { Divider } from 'components';
import { LoaderCard } from 'components/Loaders/LoaderCard';
import {
  HeaderWrapper,
  HeaderLeftWrapper,
  TransactionNumberWrapper,
  DescriptionColumn,
  CashTotalColumn,
  QuantityColumn,
  ExtraDetailsWrapper,
  PriceName,
  PriceValue,
  DescriptionRow,
  MemberNoColumn,
} from './TransactionDetailsStyle';
import { Fragment } from 'react';

interface ITransactionDetailsLoadingData {}

const TransactionDetailsLoadingData: React.FC<
  ITransactionDetailsLoadingData
> = () => {
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });
  return (
    <>
      <HeaderWrapper>
        <HeaderLeftWrapper>
          <TransactionNumberWrapper>
            <LoaderCard
              height={isMobile ? 30 : 50}
              width={isMobile ? '80%' : '100%'}
              backgroundColor="#efeee9"
              foregroundColor="#f9f8f2"
            />
          </TransactionNumberWrapper>
          <LoaderCard
            height={isMobile ? 30 : 30}
            width={isMobile ? '80%' : '100%'}
            backgroundColor="#efeee9"
            foregroundColor="#f9f8f2"
          />
        </HeaderLeftWrapper>
      </HeaderWrapper>

      <Divider marginVertical={isMobile ? '1.75rem' : '3rem'} />

      {Array(2)
        .fill(null)
        .map((_, index) => (
          <DescriptionRow key={index} variant="header">
            <MemberNoColumn variant="header">
              <LoaderCard
                height={isMobile ? 30 : 40}
                width={isMobile ? '80%' : '60%'}
                backgroundColor="#efeee9"
                foregroundColor="#f9f8f2"
              />
            </MemberNoColumn>
            <DescriptionColumn variant="header">
              <LoaderCard
                height={isMobile ? 30 : 40}
                width={isMobile ? '80%' : '60%'}
                backgroundColor="#efeee9"
                foregroundColor="#f9f8f2"
              />
            </DescriptionColumn>
            <QuantityColumn variant="header">
              <LoaderCard
                height={isMobile ? 30 : 40}
                width={isMobile ? '80%' : '90%'}
                backgroundColor="#efeee9"
                foregroundColor="#f9f8f2"
              />
            </QuantityColumn>
            <CashTotalColumn variant="header">
              <LoaderCard
                height={isMobile ? 30 : 40}
                width={isMobile ? '80%' : '100%'}
                backgroundColor="#efeee9"
                foregroundColor="#f9f8f2"
              />
            </CashTotalColumn>
          </DescriptionRow>
        ))}

      <Divider height="3px" marginVertical={isMobile ? '2rem' : '3rem'} />

      {Array(3)
        .fill(null)
        .map((_, index) => (
          <Fragment key={index}>
            <ExtraDetailsWrapper>
              <PriceName>
                <LoaderCard
                  height={isMobile ? 30 : 40}
                  width={isMobile ? '80%' : '80%'}
                  backgroundColor="#efeee9"
                  foregroundColor="#f9f8f2"
                />
              </PriceName>
              <PriceValue>
                <LoaderCard
                  height={isMobile ? 30 : 40}
                  width={isMobile ? '80%' : '80%'}
                  backgroundColor="#efeee9"
                  foregroundColor="#f9f8f2"
                />
              </PriceValue>
            </ExtraDetailsWrapper>
            {index !== 2 && (
              <Divider
                opacity="0.5"
                marginVertical={isMobile ? '1.2rem' : '1.5rem'}
              />
            )}
          </Fragment>
        ))}
    </>
  );
};

export default TransactionDetailsLoadingData;
