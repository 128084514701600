import { useEffect } from 'react';
import tw from 'twin.macro';
import { ContentWrapper } from 'components/shared/CommonWrappers';
import { MembershipDetailComponent } from 'components/membership/MembershipDetailComponent';
import { Announcement } from 'components/Dashboard/Announcement';
import {
  AnnouncementPageType,
  useContactService,
  useDashboardService,
} from 'services';
import { MyDetails } from './MyDetails/MyDetails';

const MembershipWrapper = tw.div`block`;

export const MembershipDetailContent = () => {
  const { getData } = useDashboardService();
  const myMembershipsData = useContactService((s) => s.myMemberships.data);
  const membership = myMembershipsData?.[0];

  useEffect(() => {
    if (membership) getData(membership);
  }, [membership, getData]);

  return (
    <ContentWrapper>
      <MembershipWrapper>
        <MembershipDetailComponent me />
        <Announcement type={AnnouncementPageType.MEMBERSHIP} />
        <MyDetails />
      </MembershipWrapper>
    </ContentWrapper>
  );
};
