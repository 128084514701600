import { PromptModal, Radio } from 'components';
import { ErrorMessageWrapper } from 'components/Forms/FormShared';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MoonLoader } from 'react-spinners';
import { useContactService } from 'services';
import tw from 'twin.macro';
import { PaymentProfileType, PaymentGatewayTransactionRequestType } from 'type';
import {
  Label,
  PaymentProfileExpiry,
  PersonalContentCell,
  PersonalContentCellVerticalWrapper,
} from '../../../../../styles';
import { ConfirmAddUpdateModal } from './ConfirmAddUpdateModal';
import { PreferredCol } from '../styles';
import styled from 'styled-components';

const CardWrapper = tw.div`
  grid grid-cols-1 md:grid-template-columns[1fr auto]
  gap-x-4 gap-y-1
  max-w-full md:max-w-[640px]
  `;
const LeftWrapper = tw.div`flex gap-1 md:gap-4 items-center`;
const CardNumberWrapper = tw.div`
  flex
  flex-col gap-0.5
  md:flex-row md:gap-7`;
const RightWrapper = tw.div`pl-1 md:pl-0`;
const ButtonContainer = tw.div`pl-[52px] md:pl-0 flex gap-4`;
const ActionButton = styled.button<{ variant: 'update' | 'delete' }>(
  ({ variant }) => [
    tw`
    text-sm underline transition duration-300
    `,
    variant === 'update'
      ? tw`text-primaryPurple hover:text-primaryBlue`
      : tw`text-errorRed hover:text-lightRed`,
  ],
);

interface ICardItemProps {
  index: number;
  paymentProfile: PaymentProfileType;
  handleSetPaymentData: (data: PaymentGatewayTransactionRequestType) => void;
  selectedPreferred: string;
  handleSetSelectedPreferred: (data: string) => void;
  paymentProfilesCount: number;
}

export const CardItem: React.FC<ICardItemProps> = ({
  index,
  paymentProfile: { id, cardNumber, expiryDate, expired },
  handleSetPaymentData,
  selectedPreferred,
  handleSetSelectedPreferred,
  paymentProfilesCount,
}) => {
  const { putPaymentProfile, deletePaymentProfile } = useContactService();

  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [removePaymentOptionError, setRemovePaymentOptionError] =
    useState<string>('');

  const [showDeleteCardModal, setShowDeleteCardModal] =
    useState<boolean>(false);
  const [showUpdateCardModal, setShowUpdateCardModal] =
    useState<boolean>(false);

  const {
    formState: { isDirty },
  } = useFormContext();

  const handleUpdateCard = async (id: string, preferred: boolean) => {
    setUpdateLoading(true);
    const res = await putPaymentProfile(id, preferred);
    res?.data && handleSetPaymentData(res.data);
    res?.errors && setUpdateLoading(false);
  };

  const handleDeleteCard = async (id: string) => {
    setDeleteLoading(true);
    await deletePaymentProfile(id);
    setDeleteLoading(false);
    setShowDeleteCardModal(false);
  };

  return (
    <>
      <CardWrapper>
        <LeftWrapper>
          <PreferredCol>
            <Radio
              selected={id === selectedPreferred}
              handleOnClick={() => handleSetSelectedPreferred(id)}
            />
          </PreferredCol>
          <CardNumberWrapper>
            <Label>Card {index + 1}</Label>
            <PersonalContentCellVerticalWrapper>
              <PersonalContentCell>{cardNumber}</PersonalContentCell>
              <PaymentProfileExpiry expired={expired}>
                {expired ? 'Expired' : 'Expires'} {expiryDate}
              </PaymentProfileExpiry>
            </PersonalContentCellVerticalWrapper>
          </CardNumberWrapper>
        </LeftWrapper>

        <RightWrapper>
          <ButtonContainer>
            {updateLoading ? (
              <MoonLoader size={16} />
            ) : (
              <ActionButton
                type="button"
                variant="update"
                onClick={() => {
                  if (isDirty) {
                    setShowUpdateCardModal(true);
                  } else {
                    handleUpdateCard(id, id === selectedPreferred);
                  }
                }}>
                Update
              </ActionButton>
            )}

            <ActionButton
              type="button"
              variant="delete"
              onClick={() => {
                if (paymentProfilesCount === 1) {
                  setRemovePaymentOptionError(
                    'You must have at least one payment option',
                  );
                } else {
                  setShowDeleteCardModal(true);
                }
              }}>
              Delete
            </ActionButton>
          </ButtonContainer>
        </RightWrapper>
      </CardWrapper>

      {removePaymentOptionError && (
        <ErrorMessageWrapper>{removePaymentOptionError}</ErrorMessageWrapper>
      )}

      <PromptModal
        title="Remove payment option?"
        description={
          removePaymentOptionError
            ? removePaymentOptionError
            : `Are you sure you'd like to remove the card "${cardNumber}"?`
        }
        buttonText="Delete"
        show={showDeleteCardModal}
        loading={deleteLoading}
        handleClose={() => setShowDeleteCardModal(false)}
        handleConfirm={() => handleDeleteCard(id)}
      />

      <ConfirmAddUpdateModal
        show={showUpdateCardModal}
        loading={updateLoading}
        handleClose={() => setShowUpdateCardModal(false)}
        handleConfirm={() => handleUpdateCard(id, id === selectedPreferred)}
      />
    </>
  );
};
