import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Text } from 'styles';
import { useMediaQuery } from 'react-responsive';
import { deviceSize } from 'components/shared/types';
import { Radio } from 'components';

const StyledPaymentOptionCard = tw.div`
  flex
  items-center
  bg-white
  p-4
  md:p-8
  cursor-pointer
`;

const StyledText = styled.p`
  ${tw`ml-3 md:ml-6`}
`;

interface IPaymentOptionCardProps {
  text: string;
  selected: boolean;
}

const PaymentOptionCard: React.FC<IPaymentOptionCardProps> = ({
  text,
  selected,
}) => {
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });
  const responsiveText = isMobile ? Text.MediumBold : Text.LargeBold;

  return (
    <StyledPaymentOptionCard>
      <Radio selected={selected} />
      <StyledText as={responsiveText}>{text}</StyledText>
    </StyledPaymentOptionCard>
  );
};

export default PaymentOptionCard;
