import create from 'zustand';
import { http } from 'configs';
import { formatError } from 'helpers';
import { APIResponseType, CashOnCardType, ErrorType } from 'type';

const initialState = {
  cashOnCard: {
    data: null as null | CashOnCardType,
    loading: true as boolean,
  },
  errors: null as null | ErrorType | undefined | string,
};

type CashOnCardStoreState = typeof initialState;
type CashOnCardStore = CashOnCardStoreState & {
  getCashOnCard: () => Promise<null | CashOnCardType>;
  clearData: () => void;
  clearErrors: () => void;
};

const useCashOnCardService = create<CashOnCardStore>((set, get) => {
  return {
    ...initialState,
    getCashOnCard: async () => {
      try {
        const {
          data: { data, errors },
        }: APIResponseType<CashOnCardType> = await http.get(
          `/member/0/cashoncard`,
        );
        set({
          cashOnCard: {
            data,
            loading: false,
          },
        });
        set({ errors });
        return data || null;
      } catch (error) {
        set({ errors: formatError(error) });
        return null;
      }
    },
    clearData: () => set(initialState),
    clearErrors: () => set({ errors: null }),
  };
});

export default useCashOnCardService;
