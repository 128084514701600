import tw from 'twin.macro';
import styled from 'styled-components';
import { Button } from 'components';

export const StyledTransactionCard = styled.div<{ isCenter?: boolean }>(
  ({ isCenter = true }) => [
    tw`
    grid
    grid-cols-12
    gap-x-4
    bg-white
    p-4
    sm:pl-8
    sm:pr-6
    sm:py-4
    my-1.5
    md:items-center
  `,
    isCenter ? tw`items-center` : ``,
  ],
);

export const DateContainer = tw.div`
  flex-col
  col-span-8
  sm:col-span-5
  xl:col-span-7
`;

export const DateWrapper = tw.div`
  block md:flex flex-row items-center
`;
export const DateInner = tw.div`
  md:w-52
`;

export const Refund = tw.p`
  inline-block mt-2 md:mt-0 ml-0 md:ml-4 rounded py-1 px-3 border-2 border-primaryBlue text-[0.70125rem] font-bold tracking-wider text-primaryBlue
`;

export const DetailRefund = tw.p`
  inline-block ml-2 md:ml-4 rounded py-1 px-3 border-2 border-primaryBlue text-[0.70125rem] font-bold tracking-wider leading-tight text-primaryBlue
`;
export const TotalContainer = tw.div`
  col-span-4
  sm:col-span-3
  xl:col-span-1
`;

export const HiddenTextContainer = tw.div`
  sm:hidden
  uppercase
`;

export const StyledButtonsContainer = tw.div`
  flex
  justify-end
  col-span-12
  sm:col-span-4
  mt-4
  sm:mt-0
`;

export const StyledActionButton = styled(Button)`
  uppercase
`;
