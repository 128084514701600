import { CybersourceForm } from 'pages/membership';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { deviceSize } from 'components/shared/types';
import { PaymentProfileType, PaymentGatewayTransactionRequestType } from 'type';
import {
  FieldList,
  FieldWrapper,
  PaymentPlaceholderWrapper,
  PersonalLabelPreferred,
  Section,
  StyledNoPaymentOptionsText,
} from '../../../../styles';
import { FormValues } from '../../types';
import { AddNewCard } from './components/AddNewCard';
import { CardItem } from './components/CardItem';
import { PreferredCol } from './styles';
import { Divider } from 'components';
import tw from 'twin.macro';

const CardsContainer = tw.div`relative flex flex-col gap-6 flex-grow`;

interface IPaymentFormProps {
  paymentProfilesData: PaymentProfileType[] | null;
}

export const PaymentForm: React.FC<IPaymentFormProps> = ({
  paymentProfilesData,
}) => {
  const { watch, setValue } = useFormContext<FormValues>();
  const selectedPreferred = watch('preferredCardId');
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });
  const isTablet = useMediaQuery({ maxWidth: deviceSize.tablet });

  const [paymentData, setPaymentData] = useState<
    PaymentGatewayTransactionRequestType | null | undefined
  >(null);

  const handleSetSelectedPreferred = (id: string) => {
    setValue('preferredCardId', id, { shouldDirty: true });
  };

  return (
    <Section first>
      {/* cybersource hidden input */}
      {paymentData && <CybersourceForm data={paymentData} />}

      {/* list cards */}
      <FieldList>
        <Divider marginVertical={isTablet ? '0.5rem' : '0'} opacity="0" />
        {!!paymentProfilesData?.length ? (
          <>
            <PreferredCol>
              <PersonalLabelPreferred>Preferred</PersonalLabelPreferred>
              <Divider marginVertical="1rem" opacity="0" />
            </PreferredCol>
            <CardsContainer>
              {paymentProfilesData.map((paymentProfile, index) => {
                return (
                  <CardItem
                    key={paymentProfile.id}
                    index={index}
                    paymentProfile={paymentProfile}
                    handleSetPaymentData={setPaymentData}
                    selectedPreferred={selectedPreferred}
                    handleSetSelectedPreferred={handleSetSelectedPreferred}
                    paymentProfilesCount={paymentProfilesData.length}
                  />
                );
              })}
            </CardsContainer>
          </>
        ) : (
          <PaymentPlaceholderWrapper>
            <StyledNoPaymentOptionsText>
              You don't have any payment options saved.
            </StyledNoPaymentOptionsText>
            {!isMobile && <AddNewCard handleSetPaymentData={setPaymentData} />}
          </PaymentPlaceholderWrapper>
        )}

        {/* add card */}
        {(!!paymentProfilesData?.length || isMobile) && (
          <FieldWrapper>
            <AddNewCard handleSetPaymentData={setPaymentData} />
          </FieldWrapper>
        )}
      </FieldList>
    </Section>
  );
};
