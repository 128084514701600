import styled from 'styled-components';
import tw from 'twin.macro';
import { TopHamburgerMenu } from './TopHamburger/TopHamburger';
import { Link } from 'react-router-dom';
import { Logo } from './Logo';
import { useMediaQuery } from 'react-responsive';
import { deviceSize } from 'components/shared/types';
import { VRCMemberHubPhoneLogo1 } from './svgs/logo/VRCMemberHubPhoneLogo1';
import { useContactService } from 'services';

const HeaderWrapper = styled.div`
  ${tw`
    w-full bg-white z-30 top-0 sticky
  `}
`;
const TopContainer = styled.div`
  background: #e9e9ea;
  color: #201547;
  letter-spacing: 0.1em;
  ${tw`
      h-[42px] hidden sm:flex flex-row justify-between items-center pl-4 text-xs font-bold
  `}
`;

const TopCenter = styled.div`
  ${tw`
        flex-shrink
    `}
`;

const TopCorner = styled.div`
  color: #4c4581;
  letter-spacing: 0.1em;
  ${tw`
      flex items-center font-bold items-center h-full justify-center uppercase
  `}
`;

const VRCWebSiteBtnContainer = tw.a`
  px-7 py-3 font-bold transition duration-300
  hover:text-primaryPurple hover:bg-secondaryMidGrey hover:bg-opacity-50
`;

const SmallTopContainer = styled(Link)`
  ${tw`
    w-full bg-white h-[80px] sm:h-[142px] flex justify-center items-center sm:hidden
  `}
`;

export const NoBackgroundBorderButton = styled(Link)`
  color: #e1dbd2;
  letter-spacing: 0.1em;
  ${tw`
    flex items-center px-7 font-bold h-full items-center justify-center transition duration-300 bg-primaryPurple hover:bg-primaryBlue
  `}
`;
const LogoImgContainer = styled.div`
  ${tw`
    items-center
  `}
`;

export const Header = () => {
  const {
    myContact: { data },
  } = useContactService();
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });
  return (
    <HeaderWrapper>
      <TopContainer>
        <TopCorner>HI {data?.displayName}</TopCorner>
        <TopCenter></TopCenter>
        <TopCorner>
          <VRCWebSiteBtnContainer
            href="https://vrc.com.au"
            target="_blank"
            rel="noreferrer noopener">
            VRC WEBSITE
          </VRCWebSiteBtnContainer>
          <NoBackgroundBorderButton to="/">My VRC</NoBackgroundBorderButton>
        </TopCorner>
      </TopContainer>
      <SmallTopContainer to={`/`}>
        <LogoImgContainer>
          {isMobile && <VRCMemberHubPhoneLogo1 />}
        </LogoImgContainer>
      </SmallTopContainer>
      <TopHamburgerMenu />
      <Logo></Logo>
    </HeaderWrapper>
  );
};
