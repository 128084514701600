import { useContactService } from 'services';
import { Loader } from '../../Loader';
import { BorderedSections } from '../../styles';
import { FormWrapper } from './components/FormWrapper';

interface IEditPaymentSettingsProps {
  handleFinishEdit: () => void;
}

export const EditPaymentSettings: React.FC<IEditPaymentSettingsProps> = ({
  handleFinishEdit,
}) => {
  const [myContactData, paymentProfilesData] = useContactService((state) => [
    state.myContact.data,
    state.paymentProfiles.data,
    state.paymentProfiles.loading,
  ]);

  if (!myContactData || !paymentProfilesData) {
    return <Loader />;
  }

  return (
    <BorderedSections>
      <FormWrapper
        myContactData={myContactData}
        paymentProfilesData={paymentProfilesData}
        handleFinishEdit={handleFinishEdit}
      />
    </BorderedSections>
  );
};
