import React, { useEffect } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Text } from 'styles';
import { useFormContext } from 'react-hook-form';
import { Checkbox } from 'components';
import { FormDataType } from './type';
import { offerHelpers } from 'helpers';
import { useMembershipService } from 'services';

const TNC_URL = 'https://www.vrc.com.au/about-vrc/terms-and-conditions/';

const StyledConfirmTnC = styled.div<{ hide: boolean }>(({ hide }) => [
  tw`
  flex
  items-center
  mb-10
  sm:mb-12
  `,
  hide ? tw`hidden` : tw``,
]);

const Label = tw.label`flex items-center cursor-pointer`;
const Input = tw.input`hidden`;

const TnC = tw(Text.Small)`
  text-primaryBlue
  ml-5
  tracking-normal
`;

const Underline = tw.a`text-sm underline`;

interface IConfirmTnCProps {}

const ConfirmTnC: React.FC<IConfirmTnCProps> = () => {
  const { register, setValue, watch } = useFormContext<FormDataType>();
  const offersResData = useMembershipService((s) => s.offers.data);
  const offersData = offersResData?.offers;
  const hasPurchasedOffer = offerHelpers.getPurchasedOffer(offersData);
  const checked = watch('tnc');

  const handleClick = () => {
    setValue('tnc', !checked);
  };

  useEffect(() => {
    if (hasPurchasedOffer) setValue('tnc', true);
  }, [hasPurchasedOffer, setValue]);

  return (
    <StyledConfirmTnC hide={false}>
      <Label htmlFor="tnc">
        <Input
          {...register('tnc')}
          type="checkbox"
          id="tnc"
          name="tnc"
          checked={checked}
          onClick={handleClick}
        />
        <Checkbox checked={checked} />
      </Label>
      <TnC>
        I confirm that I have read and understood the{' '}
        <Underline href={TNC_URL} target="_blank" rel="noreferrer noopener">
          Terms &#38; Conditions
        </Underline>{' '}
        in relation to VRC membership
      </TnC>
    </StyledConfirmTnC>
  );
};

export default ConfirmTnC;
