import axios from 'axios';
import { SilentRequest } from '@azure/msal-browser';
import { b2cPolicies, publicClientApp } from 'configs';
import { usePersistentService } from 'services';

const UNAUTHORIZED_STATUS = [401];

const http = axios.create({
  baseURL: process.env.REACT_APP_BE_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  validateStatus: (status) => {
    if (UNAUTHORIZED_STATUS.includes(status)) return true;
    return status >= 200 && status < 300;
  },
});

const getToken = async () => {
  const authority = usePersistentService.getState().authority;
  const silentRequest: SilentRequest = {
    account: publicClientApp.getAllAccounts()[0],
    authority: b2cPolicies.authorities[authority],
    scopes: [],
  };
  try {
    const token = usePersistentService.getState().authToken;
    if (token) return token.idToken;
    const result = await publicClientApp.acquireTokenSilent(silentRequest);
    usePersistentService.getState().setAuthToken(result);
    return result.idToken;
  } catch (error) {
    publicClientApp.logoutRedirect();
    Promise.reject(error);
  }
};

http.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    if (token && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  async (response) => {
    if (UNAUTHORIZED_STATUS.includes(response.status)) {
      usePersistentService.getState().setAuthToken(null);
      return http(response.config);
    } else {
      return response;
    }
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default http;
