import { booleanToText } from 'helpers';
import { FormProvider, useForm } from 'react-hook-form';
import { ContactType, PaymentProfileType } from 'type';
import { Form } from './Form';
import { getPreferredIdFromData } from '../helpers';
import { FormValues } from '../types';

interface IFormWrapperProps {
  myContactData: ContactType;
  paymentProfilesData: PaymentProfileType[] | null;
  handleFinishEdit: () => void;
}

export const FormWrapper: React.FC<IFormWrapperProps> = ({
  myContactData,
  paymentProfilesData,
  handleFinishEdit,
}) => {
  const methods = useForm<FormValues>({
    defaultValues: {
      // use putMyContact:
      allowVrcMembershipMarketing: booleanToText(
        myContactData.allowVrcMembershipMarketing,
      ),
      // use patchPaymentProfile:
      preferredCardId: getPreferredIdFromData(paymentProfilesData),
    },
  });

  return (
    <FormProvider {...methods}>
      <Form
        myContactData={myContactData}
        paymentProfilesData={paymentProfilesData}
        handleFinishEdit={handleFinishEdit}
      />
    </FormProvider>
  );
};
