import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from '@emotion/react';
import { CustomButton } from 'components';
import { TransitionOpacityEnterContainer } from 'components/Animations/Animation';
import { TransationEnterType } from 'components/Animations/type';
import { DashboardLinkButton } from 'components/shared/CommonWrappers';
import { useContactService } from 'services';
import { Text } from 'styles';
import { MoonLoader } from 'react-spinners';
import { useState } from 'react';

const GreetingDescription = tw(Text.Normal)`
  text-sm md:text-md text-secondaryWarmDarkGrey mt-7 text-left
`;
const GreetingPrivacyPolicy = tw.a`
  text-sm md:text-md underline text-primaryPurple hover:text-primaryBlue
`;
const GreetingTitle = tw(Text.H2)`
  text-h4 md:text-h2 text-primaryBlue
`;
const GreetingName = tw(Text.H1)`
  text-h2 md:text-h1 mb-2
`;
const GreetingButtonWrapper = tw.div`
  block sm:flex mt-14 text-center sm:text-left
`;
const GreetingButton = styled(DashboardLinkButton)(
  ({
    loading,
    action,
    currentAction,
  }: {
    loading: LoadingType;
    action: ButtonActionType;
    currentAction: ButtonActionType | undefined;
  }) => [
    tw`text-sm w-full text-center`,
    currentAction && currentAction === action
      ? loading && tw`invisible`
      : loading && tw`text-white`,
  ],
);
const GreetingCountMeInLink = styled(CustomButton)(
  ({ loading }: { loading: LoadingType }) => [
    tw`relative px-8 py-3 w-full sm:w-auto`,
    loading
      ? tw`bg-neutralDarkGrey`
      : tw`bg-primaryPurple hover:bg-primaryBlue`,
  ],
);
const GreetingNoThanksLink = styled(CustomButton)(
  ({ loading }: { loading: LoadingType }) => [
    tw`relative px-8 py-3 border-2 mx-0 sm:mx-4 my-2.5 sm:my-0 w-full sm:w-auto`,
    loading
      ? tw`border-neutralDarkGrey hover:border-neutralDarkGrey bg-neutralDarkGrey hover:bg-neutralDarkGrey`
      : tw`border-primaryPurple hover:bg-primaryPurple`,
  ],
);
const override = css`
  display: flex;
  position: absolute;
  left: calc(50% - 12px);
`;

type LoadingType = 'true' | undefined;
type ButtonActionType = 'yes' | 'no';

export const GreetingAnimation: React.FC<TransationEnterType> = ({
  visible = false,
  duration = 500,
}) => {
  const [buttonAction, setButtonAction] = useState<ButtonActionType>();
  const myContactData = useContactService((s) => s.myContact.data);
  const putMyContact = useContactService((s) => s.putMyContact);
  const putMyContactLoading = useContactService((s) => s.loading.putMyContact);
  const loading = putMyContactLoading ? 'true' : undefined;

  const saveMembershipMarketing = async (
    allowVrcMembershipMarketing: boolean,
  ) => {
    if (myContactData && !putMyContactLoading) {
      setButtonAction(allowVrcMembershipMarketing ? 'yes' : 'no');
      await putMyContact({ ...myContactData, allowVrcMembershipMarketing });
      setButtonAction(undefined);
    }
  };

  return (
    <TransitionOpacityEnterContainer
      duration={duration}
      startOpacity={0}
      endOpacity={1}>
      {visible && (
        <>
          <GreetingName>
            Hi {myContactData?.personalDetails?.firstName},
          </GreetingName>
          <GreetingTitle textAlign="left">
            Would you like to opt-in for VRC Marketing?
          </GreetingTitle>
          <GreetingDescription>
            Your current membership settings show that you do not consent to the
            VRC using and disclosing your personal information as set out in
            this statement, our{' '}
            <GreetingPrivacyPolicy
              href="https://www.vrc.com.au/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer">
              Privacy Policy
            </GreetingPrivacyPolicy>{' '}
            and in accordance with your privacy preferences for the delivery of
            marketing member events and offers, member research, publications
            and third party VRC partner offers and benefits. Therefore, your
            personal information will only be used to facilitate and administer
            your VRC membership and its benefits for season renewal, important
            Club information and the notice of Annual General Meeting (AGM) –
            which includes all AGM correspondence and the VRC Annual Report.
            <br />
            <br />
            If you wish to change your status and receive communications from us
            for the marketing of member events and offers, member research,
            publications and third party VRC partner offers and benefits, please
            click "Yes, Count Me In" below.
          </GreetingDescription>
          <GreetingButtonWrapper>
            <GreetingCountMeInLink
              onClick={() => saveMembershipMarketing(true)}
              loading={loading}>
              <GreetingButton
                color="white"
                loading={loading}
                action="yes"
                currentAction={buttonAction}>
                YES, COUNT ME IN.
              </GreetingButton>
              {putMyContactLoading && buttonAction === 'yes' && (
                <MoonLoader
                  css={override}
                  color="white"
                  loading
                  size={18}
                  speedMultiplier={0.8}
                />
              )}
            </GreetingCountMeInLink>
            <GreetingNoThanksLink
              color={
                putMyContactLoading && buttonAction === 'no' ? '' : 'white'
              }
              onClick={() => saveMembershipMarketing(false)}
              loading={loading}>
              <GreetingButton
                color="#201547"
                loading={loading}
                action="no"
                currentAction={buttonAction}>
                NO THANKS
              </GreetingButton>
              {putMyContactLoading && buttonAction === 'no' && (
                <MoonLoader
                  css={override}
                  color="white"
                  loading
                  size={18}
                  speedMultiplier={0.8}
                />
              )}
            </GreetingNoThanksLink>
          </GreetingButtonWrapper>
        </>
      )}
    </TransitionOpacityEnterContainer>
  );
};
