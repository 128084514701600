import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from '@emotion/react';
import { Text } from 'styles';
import { MoonLoader } from 'react-spinners';
import { SearchAddressResponseAddressRecordType } from 'type';

interface IContainerProps {
  show: boolean;
}

const Container = styled.div<IContainerProps>(({ show }) => [
  tw`
  absolute
  w-[310px]
  right-0
  sm:w-auto
  sm:left-0
  bg-white
  mt-1.5
  shadow-md
  z-10
  origin-top
  transition
  duration-200
`,
  show
    ? tw`transform opacity-100 scale-y-100`
    : tw`transform opacity-0 scale-y-0`,
]);

const ListItem = styled.button`
  ${tw`
  flex
  flex-col
  w-full
  border
  px-3
  py-1
  transition
  hover:bg-primaryPurple
  `}
  &:hover > p {
    color: white;
  }
`;

const ListItemLoader = tw(ListItem)`
  justify-center
  cursor-default
  hover:bg-transparent
`;

const override = css`
  display: flex;
  margin: 0.4rem 0;
`;

const NoAddressContainer = tw.button`
  flex
  bg-white
  w-full
  border
  p-3
`;

const NoAdressText = tw(Text.XSmall)`
  mr-2
`;

const EnterAddressText = tw(Text.XSmall)`
  text-primaryPurple
  underline
`;

interface IListLoaderProps {}

const ListLoader: React.FC<IListLoaderProps> = () => {
  return (
    <ListItemLoader type="button" onClick={() => {}}>
      <MoonLoader css={override} size={16} color="#000" speedMultiplier={1} />
    </ListItemLoader>
  );
};

interface IListContentProps {
  address: SearchAddressResponseAddressRecordType;
  onClick: () => void;
}

const ListContent: React.FC<IListContentProps> = ({
  address: { addressLine, locality, state, postcode },
  onClick,
}) => {
  return (
    <ListItem type="button" onClick={() => onClick()}>
      <Text.XSmallBold>{addressLine}</Text.XSmallBold>
      <Text.XSmall>
        {locality} {state} {postcode}
      </Text.XSmall>
    </ListItem>
  );
};

interface IMemberAddressDropdownProps {
  show: boolean;
  addresses: null | SearchAddressResponseAddressRecordType[];
  addressesLoading: boolean;
  handleAddressSelected: (
    address: SearchAddressResponseAddressRecordType,
  ) => void;
  handleManualEditClicked: () => void;
}

export const MemberAddressDropdown: React.FC<IMemberAddressDropdownProps> = ({
  show,
  addresses,
  addressesLoading,
  handleAddressSelected,
  handleManualEditClicked,
}) => {
  addresses = addresses?.filter((_, index) => index < 8) || null;

  return (
    <Container show={show}>
      {addressesLoading && <ListLoader />}
      {addresses?.map((addressFinder) => (
        <ListContent
          key={addressFinder.recordId}
          address={addressFinder}
          onClick={() => handleAddressSelected(addressFinder)}
        />
      ))}
      <NoAddressContainer type="button" onClick={handleManualEditClicked}>
        <NoAdressText>Can't find your address on the list?</NoAdressText>
        <EnterAddressText>Enter Address Manually</EnterAddressText>
      </NoAddressContainer>
    </Container>
  );
};
