import styled from 'styled-components';
import tw from 'twin.macro';
import 'styled-components/macro';

const SVGWrapper = styled.span`
  ${tw``}
`;

export const ExternalIcon: React.FC<{ color?: string }> = ({
  color = '#e1dbd2',
}) => {
  return (
    <SVGWrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14.75 14.75"
        aria-hidden="true"
        tw="h-4 w-4 ml-4"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M11.62,8.08v4.25a1.41,1.41,0,0,1-.41,1,1.45,1.45,0,0,1-1,.41H2.42a1.46,1.46,0,0,1-1-.41,1.46,1.46,0,0,1-.41-1V4.54a1.45,1.45,0,0,1,.41-1,1.42,1.42,0,0,1,1-.42H6.67"
            />
            <path
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9.5,1h4.25V5.25"
            />
            <path
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6,8.79,13.75,1"
            />
          </g>
        </g>
      </svg>
    </SVGWrapper>
  );
};
