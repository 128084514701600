import { css } from '@emotion/react';
import { textToBoolean } from 'helpers';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MoonLoader } from 'react-spinners';
import { useContactService } from 'services';
import { Text } from 'styles';
import { ContactType, PaymentProfileType } from 'type';
import {
  BorderedSections,
  FooterWrapper,
  SaveButton,
  Section,
  TertiaryButton,
  ToggleVisibilityWrapper,
} from '../../../styles';
import { getPreferredIdFromData } from '../helpers';
import { FormValues } from '../types';
import { PaymentForm } from './PaymentForm/PaymentForm';
import { PreferencesForm } from './PreferencesForm/PreferencesForm';

interface IFormProps {
  myContactData: ContactType;
  paymentProfilesData: PaymentProfileType[] | null;
  handleFinishEdit: () => void;
}

export const Form: React.FC<IFormProps> = ({
  myContactData,
  paymentProfilesData,
  handleFinishEdit,
}) => {
  const [putMyContact, patchPaymentProfile, getPaymentProfiles] =
    useContactService((state) => [
      state.putMyContact,
      state.patchPaymentProfile,
      state.getPaymentProfiles,
    ]);

  const { handleSubmit, setValue } = useFormContext<FormValues>();

  const [isDisabled, setIsDisabled] = useState(false);

  async function onSubmit(formValues: FormValues) {
    const { allowVrcMembershipMarketing, preferredCardId } = formValues;

    async function submitMyContact() {
      const request: ContactType = {
        ...myContactData,
        allowVrcMembershipMarketing: textToBoolean(allowVrcMembershipMarketing),
      };
      await putMyContact(request);
    }

    async function submitPatchPaymentProfile(preferredCardId: string) {
      if (preferredCardId) {
        const res = await patchPaymentProfile(preferredCardId);
        res?.data && setValue('preferredCardId', res.data);
        await getPaymentProfiles({ refetch: true });
      }
    }

    setIsDisabled(true);

    await submitMyContact();

    if (
      preferredCardId &&
      preferredCardId !== getPreferredIdFromData(paymentProfilesData)
    ) {
      await submitPatchPaymentProfile(preferredCardId);
    }

    handleFinishEdit();
  }

  return (
    <div>
      <MoonLoader
        css={css`
          position: absolute;
          top: 55%;
          left: 45%;
          display: block;
          margin: 0 auto;
          border-color: black;
        `}
        size={30}
        color={'#000'}
        loading={isDisabled}
        speedMultiplier={1}
      />
      <ToggleVisibilityWrapper visible={!isDisabled}>
        <BorderedSections>
          <PaymentForm paymentProfilesData={paymentProfilesData} />
          <PreferencesForm />

          <Section last>
            <FooterWrapper>
              <TertiaryButton type="button" onClick={handleFinishEdit}>
                <Text.Normal color="inherit">Cancel</Text.Normal>
              </TertiaryButton>
              <SaveButton value="Save" onClick={handleSubmit(onSubmit)} />
            </FooterWrapper>
          </Section>
        </BorderedSections>
      </ToggleVisibilityWrapper>
    </div>
  );
};
