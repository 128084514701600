import React from 'react';
import styled from 'styled-components';
import 'styled-components/macro';

interface IStyledDividerProps {
  bgColor?: string;
  height?: string;
  marginVertical?: string;
  opacity?: string;
}

const StyledDivider = styled.div<IStyledDividerProps>(
  ({
    bgColor = '#C4C4C4',
    height = '1px',
    marginVertical = '3rem',
    opacity = '1',
  }) => [
    `
    width: 100%;
    background-color: ${bgColor};
    height: ${height};
    margin: ${marginVertical} 0;
    opacity: ${opacity};
    `,
  ],
);

const Divider: React.FC<IStyledDividerProps> = (props) => {
  return <StyledDivider {...props} />;
};

export default Divider;
