import React, { useMemo } from 'react';
import tw from 'twin.macro';
import { css } from '@emotion/react';
import { Text } from 'styles';
import { MoonLoader } from 'react-spinners';
import { useFormContext } from 'react-hook-form';
import { useDeviceSize } from 'hooks';
import { Divider, Dropdown } from 'components';
import { LoaderCard } from 'components/Loaders/LoaderCard';
import { FormDataType, PaymentInstalmentList } from 'pages/membership';
import { MemberPaymentPlanType } from 'type';
import { useMembershipService } from 'services';
import { paymentPlanHelpers } from 'helpers';

const InstalmentsContainer = tw.div`
  grid
  grid-cols-8
  items-center
  mb-10
  sm:mb-14
`;

const NumberOfInstalments = tw(Text.Large)`
  col-span-8 md:col-span-3
  flex-shrink-0
  pr-4
  sm:mr-10
  mb-4
  md:mb-0
`;

const InstalmentDropdownContainer = tw.div`
  col-span-8 md:col-span-5
`;

const PaymentsContainer = tw.div`
  mb-10
  sm:mb-12
`;

const override = css`
  display: flex;
  margin: 0.4rem 0;
  left: 48%;
`;

interface IPaymentInstalmentProps {
  onChangePaymentPlan: (paymentPlan: MemberPaymentPlanType) => void;
}

const PaymentInstalment: React.FC<IPaymentInstalmentProps> = ({
  onChangePaymentPlan,
}) => {
  const { setValue, watch } = useFormContext<FormDataType>();
  const { isMobile } = useDeviceSize();
  const paymentPlansData = useMembershipService((s) => s.paymentPlans.data);
  const calculatedInstalments = useMembershipService(
    (s) => s.calculatedInstalments,
  );

  const paymentOption = watch('paymentOption');
  const paymentPlan = watch('paymentPlan');

  const activePlan = useMemo(() => {
    return paymentPlanHelpers.getActivePlan(paymentPlansData ?? []);
  }, [paymentPlansData]);

  const noOnePlans = useMemo(() => {
    return paymentPlanHelpers.getNoOnePlans(paymentPlansData ?? []);
  }, [paymentPlansData]);

  const calculatedSelection = useMemo(() => {
    return calculatedInstalments.data?.newOrUpdatedPaymentPlan;
  }, [calculatedInstalments.data]);

  const { hasActive, selection } = useMemo(() => {
    if (paymentPlansData) {
      return { hasActive: !!activePlan, selection: activePlan ?? paymentPlan };
    } else {
      return { hasActive: false, selection: undefined };
    }
  }, [paymentPlansData, activePlan, paymentPlan]);

  const handleSelected = (selected: MemberPaymentPlanType) => {
    setValue('paymentPlan', selected);
    onChangePaymentPlan(selected);
  };

  if (paymentOption !== 'Pay in instalments') {
    return null;
  }

  return (
    <>
      {hasActive ? (
        <Divider
          marginVertical={isMobile ? '0.75rem' : '1.75rem'}
          bgColor="transparent"
        />
      ) : (
        <InstalmentsContainer>
          <NumberOfInstalments>Number of instalments</NumberOfInstalments>
          <InstalmentDropdownContainer>
            {noOnePlans && selection ? (
              <Dropdown
                selections={noOnePlans}
                selection={selection}
                onSelected={handleSelected}
                keyExtractor={(p) => p.id}
                nameExtractor={(p) => p.numberOfInstalments.toString()}
                maxWidth="100%"
              />
            ) : (
              <LoaderCard
                width="100%"
                height={50}
                backgroundColor="#efeee9"
                foregroundColor="#f9f8f2"
              />
            )}
          </InstalmentDropdownContainer>
        </InstalmentsContainer>
      )}

      <PaymentsContainer>
        {calculatedInstalments.loading ? (
          <>
            <MoonLoader
              css={override}
              size={30}
              color={'#000'}
              loading={true}
              speedMultiplier={1}
            />
            <Text.Small textAlign="center">Updating payment plan</Text.Small>
          </>
        ) : (
          <PaymentInstalmentList
            selection={calculatedSelection}
            hasActive={hasActive}
          />
        )}
      </PaymentsContainer>
    </>
  );
};

export default PaymentInstalment;
