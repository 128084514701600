import React, { useMemo } from 'react';
import tw from 'twin.macro';
import 'styled-components/macro';
import { Text } from 'styles';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { deviceSize } from 'components/shared/types';
import { formatAddress } from 'helpers';
import { LoaderCard } from 'components/Loaders/LoaderCard';
import { RenewalAccordion } from 'pages/membership';
import { Divider, SubmitButton } from 'components';
import { useContactService } from 'services';

const DetailsContainer = tw.div`
  bg-white
  grid
  grid-cols-2
  gap-x-4
  p-[2.125rem]
`;

const ColumnContainer = tw.div`
  col-span-2
  sm:col-span-1
`;

const FieldContainer = tw.div`
  grid
  grid-cols-5
  gap-x-4
  col-span-2
  sm:col-span-1
  mb-3
`;

const FieldName = tw(Text.NormalBold)`
  text-secondaryWarmDarkGrey
  -mr-2
  col-span-2
`;

const FieldValue = tw(Text.Normal)`
  text-primaryBlue
  col-span-3
  break-words
`;

const EditLink = tw(Link)`
  flex justify-end
  mt-6
`;

interface IFieldOrLoaderProps {
  field: string | undefined;
}

const FieldOrLoader: React.FC<IFieldOrLoaderProps> = ({ field }) => {
  return field ? (
    <FieldValue>{field}</FieldValue>
  ) : (
    <LoaderCard
      width="7rem"
      height={20}
      backgroundColor="#efeee9"
      foregroundColor="#f9f8f2"
    />
  );
};

interface IMemberDetailsProps {}

const MemberDetails: React.FC<IMemberDetailsProps> = () => {
  const myContactData = useContactService((s) => s.myContact.data);
  const myMembershipsData = useContactService((s) => s.myMemberships.data);
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });
  const myMembership = myMembershipsData?.[0];

  const preferredContact = useMemo(() => {
    if (!myContactData?.phoneNumbers) return;
    const { preferredPhone, ...phones } = myContactData.phoneNumbers;
    return Object.values(phones).find((p) => p.label === preferredPhone)?.value;
  }, [myContactData?.phoneNumbers]);

  return (
    <>
      <RenewalAccordion title="Your Details" defaultExpanded={false}>
        <DetailsContainer>
          <ColumnContainer>
            <FieldContainer>
              <FieldName>Member ID</FieldName>
              <FieldOrLoader field={myMembership?.memberNumber} />
            </FieldContainer>
            <FieldContainer>
              <FieldName>First Name</FieldName>
              <FieldOrLoader
                field={myContactData?.personalDetails?.firstName}
              />
            </FieldContainer>
            <FieldContainer>
              <FieldName>Last Name</FieldName>
              <FieldOrLoader field={myContactData?.personalDetails?.lastName} />
            </FieldContainer>
            <FieldContainer>
              <FieldName>Email</FieldName>
              <FieldOrLoader field={myContactData?.personalDetails?.email} />
            </FieldContainer>
          </ColumnContainer>

          <ColumnContainer>
            <FieldContainer>
              <FieldName>Preferred Phone</FieldName>
              <FieldOrLoader field={preferredContact} />
            </FieldContainer>
            <FieldContainer>
              <FieldName>Postal Address</FieldName>
              <FieldOrLoader
                field={formatAddress(myContactData?.addresses?.postal)}
              />
            </FieldContainer>
            <FieldContainer>
              <FieldName>Billing Address</FieldName>
              <FieldOrLoader
                field={formatAddress(myContactData?.addresses?.billing)}
              />
            </FieldContainer>
          </ColumnContainer>
        </DetailsContainer>

        <EditLink to="/membership">
          <SubmitButton
            value="EDIT DETAILS"
            width={isMobile ? '8rem' : '10rem'}
          />
        </EditLink>
      </RenewalAccordion>
      <Divider height="2px" marginVertical="2.4rem" bgColor="#201547" />
    </>
  );
};

export default MemberDetails;
