import { useFormContext } from 'react-hook-form';
import { useContactService } from 'services';
import { FormDataType } from '../../type';
import { useNavigate } from 'react-router-dom';
import { PaymentGatewayTransactionRequestType } from 'type';

interface IUsePaymentSettingsProps {
  handleSetPaymentData: (data: PaymentGatewayTransactionRequestType) => void;
}

interface ISaveSettingsProps {
  isPaymentProfileChanged: boolean;
  isAutoRenewChanged: boolean;
}

const usePaymentSettings = ({
  handleSetPaymentData,
}: IUsePaymentSettingsProps) => {
  const navigate = useNavigate();
  const { getValues } = useFormContext<FormDataType>();
  const getMyMemberships = useContactService((s) => s.getMyMemberships);
  const getPaymentProfiles = useContactService((s) => s.getPaymentProfiles);
  const postPaymentProfile = useContactService((s) => s.postPaymentProfile);
  const patchPaymentProfile = useContactService((s) => s.patchPaymentProfile);
  const patchMembership = useContactService((s) => s.patchMembership);
  const myMemberships = useContactService((s) => s.myMemberships);
  const membership = myMemberships.data?.[0];

  const handleSaveSettings = async ({
    isPaymentProfileChanged,
    isAutoRenewChanged,
  }: ISaveSettingsProps) => {
    if (isPaymentProfileChanged && !isAutoRenewChanged) {
      await handlePaymentProfileChanged();
    }
    if (!isPaymentProfileChanged && isAutoRenewChanged) {
      await handleAutoRenewChanged();
    }
    if (isPaymentProfileChanged && isAutoRenewChanged) {
      await handleBothChanged();
    }
  };

  const handlePaymentProfileChanged = async () => {
    const preferredId = getValues('paymentProfile.id');
    if (preferredId) {
      const { data } = await patchPaymentProfile(preferredId);
      if (data) getPaymentProfiles({ refetch: true });
      redirectToSaveSettings(data ? 'ACCEPT' : 'DECLINED');
    } else {
      const { data } = await postPaymentProfile();
      redirectToPaymentGateway(data);
    }
  };

  const handleAutoRenewChanged = async () => {
    const { data } = await patchMembership(membership?.id as string, {
      autoRenew: true,
      paymentPlanId: getValues('paymentPlan.id'),
    });
    if (data) getMyMemberships({ refetch: true });
    redirectToSaveSettings(data ? 'ACCEPT' : 'DECLINED');
  };

  const handleBothChanged = async () => {
    const preferredId = getValues('paymentProfile.id');
    if (preferredId) {
      const [{ data: paymentProfileData }, { data: autoRenewData }] =
        await Promise.all([
          patchPaymentProfile(preferredId),
          patchMembership(membership?.id as string, {
            autoRenew: true,
            paymentPlanId: getValues('paymentPlan.id'),
          }),
        ]);
      if (paymentProfileData) getPaymentProfiles({ refetch: true });
      if (autoRenewData) getMyMemberships({ refetch: true });
      const data = !!paymentProfileData && !!autoRenewData;
      redirectToSaveSettings(data ? 'ACCEPT' : 'DECLINED');
    } else {
      const { data } = await postPaymentProfile({
        autoRenew: true,
        paymentPlanId: getValues('paymentPlan.id'),
      });
      redirectToPaymentGateway(data);
    }
  };

  const redirectToSaveSettings = (decision: 'ACCEPT' | 'DECLINED') => {
    const action = 'token';
    const finalPath = `/payment/response?action=${action}&decision=${decision}`;
    navigate(finalPath);
  };

  const redirectToPaymentGateway = (
    data: PaymentGatewayTransactionRequestType | null,
  ) => {
    if (data) handleSetPaymentData(data);
  };

  return { handleSaveSettings };
};

export default usePaymentSettings;
