import { FormDataType, ItemType } from 'pages/membership';
import { UseFormGetValues } from 'react-hook-form';
import { purchaseHelpers } from './purchaseHelpers';
import {
  MemberPaymentPlanType,
  OfferProductType,
  OfferType,
  PurchaseType,
} from 'type';

const offer = {
  defaultValue: [] as (OfferType | ItemType)[],
  setDefault(value: (OfferType | ItemType)[]) {
    this.defaultValue = value;
  },
  isDirty(currentValue: (OfferType | ItemType)[]) {
    const id = (() => {
      if (!this.defaultValue[0]) return null;
      if ('subscriptionProductId' in this.defaultValue[0]) {
        return this.defaultValue[0].subscriptionProductId;
      }
      return this.defaultValue[0].id;
    })();
    const currentId = (() => {
      if (!currentValue[0]) return null;
      if ('subscriptionProductId' in currentValue[0]) {
        return currentValue[0].subscriptionProductId;
      }
      return currentValue[0].id;
    })();
    return id !== null && currentId !== null ? id !== currentId : true;
  },
};

const optionalProducts = {
  defaultValue: [] as OfferProductType[],
  setDefault(value: OfferProductType[]) {
    this.defaultValue = value;
  },
  isDirty(currentValue: PurchaseType['products']) {
    for (const currentProduct of currentValue) {
      const hasProduct = this.defaultValue.find(
        (p) => p.id === currentProduct.id,
      );
      if (
        hasProduct &&
        hasProduct.quantityDefault !== currentProduct.quantity
      ) {
        return true;
      }
    }
    return false;
  },
};

const paymentPlan = {
  defaultValue: null as null | MemberPaymentPlanType,
  setDefault(value: null | MemberPaymentPlanType) {
    this.defaultValue = value;
  },
  isDirty(currentValue: null | MemberPaymentPlanType) {
    return this.defaultValue?.id !== currentValue?.id;
  },
};

const isDirty = (getValues: UseFormGetValues<FormDataType>) => {
  const { offer, optionalProducts, paymentPlan } = preferenceHelpers;
  const fields = [
    getValues('OneMandatory'),
    getValues('AllOptional'),
    getValues('OneOptional'),
  ];
  const selectedProducts = fields
    .flatMap((f) => f.map((u) => purchaseHelpers.formatUpgrade(u)))
    .filter(Boolean) as PurchaseType['products'];

  const offerDirty = offer.isDirty(getValues('offer'));
  const planDirty = paymentPlan.isDirty(getValues('paymentPlan'));
  const optionalProductsDirty = optionalProducts.isDirty(selectedProducts);

  return {
    isPreferenceChanged: offerDirty || planDirty || optionalProductsDirty,
    offerDirty,
    planDirty,
    optionalProductsDirty,
  };
};

export const preferenceHelpers = {
  offer,
  optionalProducts,
  paymentPlan,
  isDirty,
};
