import tw from 'twin.macro';
import { Text } from 'styles';
import { dateWithMonthName } from 'helpers';
import { Checkbox, Divider } from 'components';
import { PaymentPlanInstalmentType } from 'type';

const PaymentsRow = tw.div`
  flex
  justify-between
  items-center
`;

const PaymentContainer = tw.div`
  flex flex-col gap-x-2 gap-y-1.5 sm:flex-row w-full
  justify-between items-start sm:items-center
  mr-4
`;

const PaymentDateAndStatus = tw.div`
  flex flex-col ms:flex-row gap-x-4 gap-y-1.5
  items-start ms:items-center
`;

const PaymentLabel = tw.div`
  whitespace-nowrap w-[6rem]
`;

const PaymentDate = tw.div`
  col-span-4 md:col-span-2 whitespace-nowrap md:ml-[0.4rem] w-[6rem]
`;

const StatusCard = tw.p`
  px-3 py-1 mt-1 md:mt-0
  text-primaryBlue font-bold tracking-wider whitespace-nowrap
  border-primaryBlue border-2 rounded
`;

const AdjustedCard = tw.p`
  px-1 py-1 md:px-3
  text-primaryBlue font-bold tracking-wider whitespace-nowrap
  bg-specialPistachio border-2 border-specialPistachio rounded
`;

const PaymentAmount = tw.div`
  flex flex-col items-end
  md:min-w-max md:whitespace-nowrap
`;

const Price = tw(Text.Normal)`
  w-[4rem]
`;

const VerticalLine = tw.div`
border-r border-[#D9D9D9] h-[50px] w-1 ml-8 `;

const CheckboxContainer = tw.button`
  ml-[2rem] mr-[0.9rem]
`;

const Subtext = tw.div`
  text-right text-primaryBlue
`;

const STATUS_CARD_TEXT: Record<
  PaymentPlanInstalmentType['status'],
  string | null
> = {
  Paid: 'PAID',
  Overdue: 'MISSED',
  New: null,
  Pending: null,
  Unknown: null,
  Adjusted: null,
  PayingNow: null,
};

interface IPaymentInstalmentCardProps {
  label: string;
  index: number;
  instalment: PaymentPlanInstalmentType;
  checkedPayNow: boolean;
  onCheckedPayNow: (isChecked: boolean, index: number) => void;
  disabled?: boolean;
  showOutline?: boolean;
  showTick?: boolean;
  subtext?: string;
  isLast?: boolean;
}

const PaymentInstalmentCard = ({
  label,
  index,
  checkedPayNow,
  onCheckedPayNow,
  disabled = false,
  showOutline = false,
  showTick = false,
  subtext = '',
  instalment: { status, amountIncGst, date },
  isLast = false,
}: IPaymentInstalmentCardProps) => {
  const statusCardText = STATUS_CARD_TEXT[status];
  return (
    <>
      <PaymentsRow>
        <PaymentContainer>
          <PaymentDateAndStatus>
            <PaymentLabel>
              <Text.NormalBold>{label}</Text.NormalBold>
            </PaymentLabel>
            <PaymentDate>
              <Text.Normal color="#4C4581">
                {dateWithMonthName(date)}
              </Text.Normal>
            </PaymentDate>
            {statusCardText && <StatusCard>{statusCardText}</StatusCard>}
          </PaymentDateAndStatus>
          {status === 'Adjusted' && <AdjustedCard>ADJUSTED</AdjustedCard>}
        </PaymentContainer>

        <PaymentAmount>
          <Price textAlign="right">{`$${amountIncGst.toFixed(2)}`}</Price>
          {!!subtext && <Subtext>{subtext}</Subtext>}
        </PaymentAmount>

        <VerticalLine />

        <CheckboxContainer
          type="button"
          onClick={() => onCheckedPayNow(!checkedPayNow, index)}
          disabled={showTick || disabled}>
          <Checkbox
            checked={checkedPayNow}
            showTick={showTick}
            showOutline={showOutline}
            disabled={disabled}
          />
        </CheckboxContainer>
      </PaymentsRow>
      {isLast ? (
        <Divider marginVertical="-0.75rem" bgColor="transparent" />
      ) : (
        <Divider marginVertical="1.2rem" />
      )}
    </>
  );
};

export default PaymentInstalmentCard;
