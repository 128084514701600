export const booleanToText = (selection?: boolean) => {
  switch (selection) {
    case true:
      return 'Yes';
    case false:
      return 'No';
    default:
      return 'No';
  }
};

export const textToBoolean = (selection?: 'Yes' | 'No') => {
  switch (selection) {
    case 'Yes':
      return true;
    case 'No':
      return false;
    default:
      return false;
  }
};
