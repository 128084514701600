import tw from 'twin.macro';
import { Text } from 'styles';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMembershipService } from 'services';
import { MemberPaymentPlanType } from 'type';
import { Divider } from 'components';
import {
  dateWithMonthName,
  instalmentHelpers,
  paymentHelpers,
  preferenceHelpers,
} from 'helpers';
import {
  FormDataType,
  GapPaymentNotice,
  PaymentAmount,
  PaymentInstalmentCard,
} from 'pages/membership';

const Title = tw(Text.LargeBold)`mb-2`;
const Description = tw.div`mb-2`;
const CheckboxTitle = tw(Text.Normal)`-mt-2 lg:-mt-4 mb-2`;

interface IPaymentInstalmentListProps {
  selection: MemberPaymentPlanType | undefined;
  hasActive?: boolean;
}

const PaymentInstalmentList = ({
  selection,
  hasActive,
}: IPaymentInstalmentListProps) => {
  const { watch, setValue, getValues } = useFormContext<FormDataType>();

  const overduePlan = watch('overduePlan');
  const selectedInstalments = watch('selectedInstalments');
  const instalments = useMemo(() => selection?.instalments ?? [], [selection]);
  const joiningFee = useMembershipService((s) => s.offers.data?.joiningFee);
  const newInstalments = useMembershipService(
    (s) => s.calculatedInstalments.data?.newInstalments,
  );
  const newInstalmentIds = useMemo(() => {
    return newInstalments?.map((i) => i.id);
  }, [newInstalments]);

  const { isPreferenceChanged } = preferenceHelpers.isDirty(getValues);
  const { useInstalments, hasInstalmentIds } = instalmentHelpers;
  const paidInstalments = useInstalments(instalments, ['Paid']);
  const missedInstalments = useInstalments(instalments, ['Overdue']);
  const pendingInstalments = useInstalments(instalments, ['Paid'], true);

  const instalmentsList = instalments.filter((i) => {
    if (i.status !== 'Overdue') return true;
    if (!newInstalmentIds?.includes(i.id)) return true;
    return false;
  });

  const { gapPaymentNotice, gapPaymentAmount, overdueInstalmentsAmount } =
    paymentHelpers.useGapPayment(newInstalments, missedInstalments);

  const nextPayment = paymentHelpers.useNextPayment(
    selectedInstalments,
    pendingInstalments,
  );

  const shouldAllBoxesUnchecked = useMemo(() => {
    if (!selectedInstalments.length) return true;
    if (selectedInstalments.length === missedInstalments.length) return true;
    if (selectedInstalments.length <= 1 && isPreferenceChanged) return true;
    return false;
  }, [selectedInstalments, missedInstalments, isPreferenceChanged]);

  const handleCheckedPayNow = useCallback(
    (isChecked: boolean, index: number) => {
      const selected = instalmentsList.filter((i, idx) => {
        if (hasInstalmentIds(paidInstalments, i.id)) return false;
        if (isChecked) return idx <= index;
        return idx < index;
      });
      setValue('selectedInstalments', [...missedInstalments, ...selected]);
    },
    [
      instalmentsList,
      paidInstalments,
      missedInstalments,
      hasInstalmentIds,
      setValue,
    ],
  );

  if (!instalments.length) return null;

  return (
    <div>
      <Title>Payment Plan</Title>
      <Description>
        <Text.Small color="#51504D">
          Your membership and/or optional products will be paid in{' '}
          {instalmentsList.length} instalments.
        </Text.Small>
      </Description>

      <CheckboxTitle textAlign="right">Pay Now</CheckboxTitle>

      {overduePlan?.instalments?.map((instalment, index) => {
        return (
          <PaymentInstalmentCard
            key={instalment.id}
            label="Prior Season"
            index={index}
            instalment={instalment}
            onCheckedPayNow={handleCheckedPayNow}
            checkedPayNow
            showTick
            showOutline
            disabled
          />
        );
      })}

      {instalmentsList.map((instalment, index) => {
        const checkedPayNow = hasInstalmentIds(
          selectedInstalments,
          instalment.id,
        );

        const subtext =
          joiningFee && index === 0 ? '*Incl. Joining Fee Instalments' : '';

        const showTick = (() => {
          if (instalment.status === 'Paid' || instalment.status === 'Overdue') {
            return true;
          }
          return (
            !missedInstalments.length &&
            isPreferenceChanged &&
            instalment.id === pendingInstalments[0]?.id
          );
        })();

        const showOutline = instalment.status === 'Paid' ? false : showTick;

        const disabled = (() => {
          if (showTick) return true;
          if (shouldAllBoxesUnchecked) return false;
          const extraLength = missedInstalments.length
            ? 0
            : paidInstalments.length;
          return index >= extraLength + selectedInstalments.length + 1;
        })();

        return (
          <PaymentInstalmentCard
            key={instalment.id}
            label={`Payment ${index + 1}`}
            index={index}
            instalment={instalment}
            checkedPayNow={checkedPayNow}
            onCheckedPayNow={handleCheckedPayNow}
            subtext={subtext}
            showTick={showTick}
            showOutline={showOutline}
            disabled={disabled}
            isLast={index === instalmentsList.length - 1}
          />
        );
      })}
      {!!gapPaymentAmount && (
        <GapPaymentNotice type={gapPaymentNotice} amount={gapPaymentAmount} />
      )}
      {!!nextPayment && (
        <>
          <Divider marginVertical="1.5rem" bgColor="transparent" />
          <Divider marginVertical="1.5rem" />
          <PaymentAmount
            amount={
              selectedInstalments.length
                ? nextPayment + overdueInstalmentsAmount
                : nextPayment
            }
            label={
              !hasActive || selectedInstalments.length
                ? 'Amount'
                : 'Next Payment'
            }
            text={
              !selectedInstalments.length
                ? `(${dateWithMonthName(pendingInstalments[0]?.date)})`
                : ''
            }
          />
        </>
      )}
    </div>
  );
};

export default PaymentInstalmentList;
