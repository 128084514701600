import { css } from '@emotion/react';
import { Dropdown, Input } from 'components';
import { PREFERRED_PHONES_LIST } from 'configs';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MoonLoader } from 'react-spinners';
import { useContactService } from 'services';
import { Text } from 'styles';
import { ContactType } from 'type';
import {
  EditFieldWrapper,
  EditFieldList,
  Label,
  LabelWrapper,
  SaveButton,
} from '../../../styles';
import {
  BorderedSections,
  FooterWrapper,
  Section,
  TertiaryButton,
  ToggleVisibilityWrapper,
} from '../../../styles';
import { BasicInfo } from '../../components/BasicInfo';
import { FormValues } from '../types';
import Address from './Address/Address';

interface IFormProps {
  data: ContactType;
  handleFinishEdit: () => void;
}

export const Form: React.FC<IFormProps> = ({ data, handleFinishEdit }) => {
  const putMyContact = useContactService((state) => state.putMyContact);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useFormContext<FormValues>();

  const [isDisabled, setIsDisabled] = useState(false);

  const onSubmit = async (formValues: FormValues) => {
    const request: ContactType = {
      ...data,
      addresses: formValues.addresses,
      phoneNumbers: formValues.phoneNumbers,
      industry: formValues.industry,
      occupation: formValues.occupation,
    };
    setIsDisabled(true);
    await putMyContact(request);
    handleFinishEdit();
  };

  const [industries, getIndustries] = useContactService((state) => [
    state.industries,
    state.getIndustries,
  ]);

  useEffect(() => {
    getIndustries();
  }, [getIndustries]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MoonLoader
        css={css`
          position: absolute;
          top: 55%;
          left: 45%;
          display: block;
          margin: 0 auto;
          border-color: black;
        `}
        size={30}
        color={'#000'}
        loading={isDisabled}
        speedMultiplier={1}
      />
      <ToggleVisibilityWrapper visible={!isDisabled}>
        <BorderedSections>
          <Section first>
            <BasicInfo isEdit />
          </Section>

          {/* Phone Numbers */}
          <Section>
            <EditFieldList>
              <EditFieldWrapper>
                <LabelWrapper>
                  <Label>Preferred Number</Label>
                </LabelWrapper>
                <Controller
                  control={control}
                  name="phoneNumbers.preferredPhone"
                  render={({ field: { onChange, value } }) => (
                    <Dropdown
                      selections={PREFERRED_PHONES_LIST}
                      selection={value ?? ''}
                      onSelected={onChange}
                      maxWidth="1000px"
                    />
                  )}
                />
              </EditFieldWrapper>
              <EditFieldWrapper>
                <LabelWrapper>
                  <Label>{data.phoneNumbers.personalMobile.label}</Label>
                </LabelWrapper>
                <Input
                  name="phoneNumbers.personalMobile.value"
                  errorMessage={
                    errors?.phoneNumbers?.personalMobile?.value?.message
                  }
                  placeholder={data.phoneNumbers.personalMobile.label}
                />
              </EditFieldWrapper>
              <EditFieldWrapper>
                <LabelWrapper>
                  <Label>{data.phoneNumbers.businessPhone.label}</Label>
                </LabelWrapper>
                <Input
                  name="phoneNumbers.businessPhone.value"
                  errorMessage={
                    errors?.phoneNumbers?.businessPhone?.value?.message
                  }
                  placeholder={data.phoneNumbers.businessPhone.label}
                />
              </EditFieldWrapper>
              <EditFieldWrapper>
                <LabelWrapper>
                  <Label>{data.phoneNumbers.businessMobile.label}</Label>
                </LabelWrapper>
                <Input
                  name="phoneNumbers.businessMobile.value"
                  errorMessage={
                    errors?.phoneNumbers?.businessMobile?.value?.message
                  }
                  placeholder={data.phoneNumbers.businessMobile.label}
                />
              </EditFieldWrapper>
            </EditFieldList>
          </Section>

          {/* Address */}
          <Section>
            <Address data={data} />
          </Section>

          {/* Other */}
          <Section>
            <EditFieldList>
              <EditFieldWrapper>
                <LabelWrapper>
                  <Label>Industry</Label>
                </LabelWrapper>
                {industries.data && (
                  <Controller
                    control={control}
                    name="industry"
                    render={({ field: { onChange, value } }) => (
                      <Dropdown
                        selections={industries.data ?? []}
                        selection={value ?? { id: '', name: '' }}
                        onSelected={onChange}
                        maxWidth="1000px"
                        keyExtractor={({ id }) => id}
                        nameExtractor={({ name }) => name}
                      />
                    )}
                  />
                )}
              </EditFieldWrapper>
              <EditFieldWrapper>
                <LabelWrapper>
                  <Label>Occupation</Label>
                </LabelWrapper>

                <Input
                  name="occupation"
                  errorMessage={errors?.occupation?.message}
                  placeholder="Occupation"
                />
              </EditFieldWrapper>
            </EditFieldList>
          </Section>

          <Section last>
            <FooterWrapper>
              <TertiaryButton type="button" onClick={handleFinishEdit}>
                <Text.Normal color="inherit">Cancel</Text.Normal>
              </TertiaryButton>
              <SaveButton value="Save" type="submit" />
            </FooterWrapper>
          </Section>
        </BorderedSections>
      </ToggleVisibilityWrapper>
    </form>
  );
};
