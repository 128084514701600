import { PageContainer } from 'components';
import { TransactionHistoryContent } from './TransactionHistoryContent';

interface ITransactionHistoryProps {}

const TransactionHistory: React.FC<ITransactionHistoryProps> = () => {
  return (
    <PageContainer>
      <TransactionHistoryContent />
    </PageContainer>
  );
};
export default TransactionHistory;
