import { format } from 'date-fns';

export const dateWithMonthName = (date: string | null | undefined) =>
  date ? format(new Date(date), 'dd MMM yyyy') : null;

export const dateWithFullMonthName = (date: string | null | undefined) =>
  date ? format(new Date(date), 'dd MMMM yyyy') : null;

export const dateWithDayAndMonthName = (date: string | null | undefined) =>
  date ? format(new Date(date), 'EEEE dd MMMM yyyy') : null;
