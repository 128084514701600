import CustomLink from 'components/Links/CustomLink';
import { IMenu } from 'configs';
import { trackGTMEvent } from 'helpers/trackGTMEvent';
import styled from 'styled-components';
import tw from 'twin.macro';

const MenuItemStyle = styled.li`
  ${tw`
    relative
    text-primaryBlue hover:text-gray-200 transition-colors transition-duration[300ms]
    flex items-center
    h-12
    text-xs font-semibold tracking-widest leading-[3rem]
    pl-4 my-2.5
    cursor-pointer
  `}
`;

const LabelStyle = styled.div<{ isActive?: boolean }>(({ isActive }) => [
  tw`
      w-full h-full transition
      after:absolute after:w-full after:h-full after:bg-primaryBlue after:top-0 after:left-0 after:rounded
      after:opacity-0 hover:after:opacity-[0.15] hover:text-primaryPurple
    `,
  isActive
    ? tw`after:opacity-[0.15] text-primaryPurple`
    : tw`bg-transparent text-primaryBlue`,
]);

export interface IDropdownMenuItem extends IMenu {
  isActive?: boolean;
}

export const DropdownMenuItem: React.FC<IDropdownMenuItem> = ({
  id,
  label,
  to,
  isActive,
  ...props
}) => {
  return (
    <MenuItemStyle
      key={id}
      onClick={() => trackGTMEvent('main_menu_click', { menu_item: label })}
    >
      <CustomLink to={to} {...props}>
        <LabelStyle isActive={isActive}>{label}</LabelStyle>
      </CustomLink>
    </MenuItemStyle>
  );
};
