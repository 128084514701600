import { useMsal } from '@azure/msal-react';
import { SubmitButton } from 'components';
import { passwordResetRequest } from 'configs';
import { Buttons, OutlinedButton } from '../../styles';

interface IEditButtonsProps {
  handleEdit: () => void;
}

export const EditButtons: React.FC<IEditButtonsProps> = ({ handleEdit }) => {
  const { instance } = useMsal();

  return (
    <Buttons>
      <OutlinedButton
        onClick={() => instance.loginRedirect(passwordResetRequest)}
      >
        Change Password
      </OutlinedButton>
      <SubmitButton
        value="Edit Details"
        onClick={handleEdit}
        width="auto"
        maxWidth="none"
        paddingHorizontal="1.75rem"
      />
    </Buttons>
  );
};
