import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Text } from 'styles';
import { useMediaQuery } from 'react-responsive';
import { deviceSize } from './types';

const StyledContentHeaderContainer = tw.div`
  hidden
  sm:block
  mb-8
`;

const StyledContentHeaderText = styled.p``;

interface IContentHeaderProps {
  text: string;
}

export const ContentHeader: React.FC<IContentHeaderProps> = ({ text }) => {
  const isTablet = useMediaQuery({ maxWidth: deviceSize.tablet });
  return (
    <StyledContentHeaderContainer>
      <StyledContentHeaderText as={isTablet ? Text.H3 : Text.H2}>
        {text}
      </StyledContentHeaderText>
    </StyledContentHeaderContainer>
  );
};

export default ContentHeader;
