import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';

interface IToggleContainerProps {
  variant?: 'primary' | 'secondary';
  scale?: number;
  toggle: boolean;
}

interface IToggleButtonProps extends IToggleContainerProps {}

const chooseBgColor = (
  variant: IToggleContainerProps['variant'],
  toggle: IToggleContainerProps['toggle'],
) => {
  if (variant === 'primary' && toggle) return tw`bg-neutralMidGrey`;
  if (variant === 'primary' && !toggle) return tw`bg-primaryBlue`;
  if (variant === 'secondary' && toggle) return tw`bg-primaryPurple`;
  if (variant === 'secondary' && !toggle) return tw`bg-secondaryKeyline`;
};

const ToggleContainer = styled.div<IToggleContainerProps>(
  ({ variant = 'secondary', toggle, scale = 1 }) => [
    tw`
    flex
    flex-shrink-0
    items-center
    w-[54.47px]
    h-[32px]
    rounded-full
    p-2
    cursor-pointer
    transition
    duration-300
    `,
    `
    transform: scale(${scale});
    `,
    chooseBgColor(variant, toggle),
  ],
);

const ToggleButton = styled.div<IToggleButtonProps>(
  ({ variant = 'secondary', toggle, scale = 1 }) => [
    tw`
    w-[19.74px]
    h-[19.74px]
    rounded-full
    bg-white
    transform
    transition
    duration-300
    `,
    `
    transform: scale(${scale});
    `,
    variant === 'primary' ? tw`bg-primaryPurple` : tw`bg-white`,
    toggle ? tw`translate-x-full` : tw`translate-x-0`,
  ],
);

interface IToggleProps extends IToggleContainerProps {
  onToggle: () => void;
}

const Toggle: React.FC<IToggleProps> = ({ onToggle, ...props }) => {
  return (
    <ToggleContainer onClick={onToggle} {...props}>
      <ToggleButton {...props} />
    </ToggleContainer>
  );
};

export default Toggle;
