import { useMediaQuery } from 'react-responsive';
import { deviceSize } from 'components/shared/types';

const useDeviceSize = () => {
  const isDesktop = useMediaQuery({ minWidth: deviceSize.desktop + 1 });
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });
  const isLaptop = useMediaQuery({
    minWidth: deviceSize.mobile + 1,
    maxWidth: deviceSize.desktop,
  });
  return { isDesktop, isMobile, isLaptop };
};

export default useDeviceSize;
