import create from 'zustand';
import { produce } from 'immer';
import { http } from 'configs';
import { formatError } from 'helpers';
import {
  APIResponseType,
  AddressType,
  ContactType,
  ErrorType,
  IndustryType,
  MembershipType,
  PaymentGatewayResponseType,
  PaymentProfileType,
  SearchAddressResponseAddressRecordType,
  PaymentGatewayTransactionRequestType,
} from 'type';

const initialState = {
  myContact: {
    data: null as null | ContactType,
    loading: true as boolean,
  },
  myMemberships: {
    data: null as null | MembershipType[],
    loading: true as boolean,
  },
  addressesFound: {
    data: null as null | SearchAddressResponseAddressRecordType[],
    loading: false as boolean,
  },
  paymentProfiles: {
    data: null as null | PaymentProfileType[],
    loading: true as boolean,
  },
  industries: {
    data: null as null | IndustryType[],
    loading: true as boolean,
  },
  loading: {
    putMyContact: false as boolean,
    patchMembership: false as boolean,
    postPaymentProfile: false as boolean,
    putPaymentProfile: false as boolean,
    patchPaymentProfile: false as boolean,
    deletePaymentProfile: false as boolean,
  },
  errors: null as ErrorsType,
};

type ErrorsType = null | ErrorType | undefined | string;
type ContactStoreState = typeof initialState;
type PatchMembershipResponseType = APIResponseType<'success' | null>;
type PaymentResponseType =
  APIResponseType<PaymentGatewayTransactionRequestType | null>['data'];
type PatchPaymentProfileResponseType = APIResponseType<string | null>;

type ContactStore = ContactStoreState & {
  getMyContact: () => Promise<void>;
  putMyContact: (updateData: ContactType) => Promise<boolean>;
  getMyMemberships: (params?: { refetch: boolean }) => Promise<void>;
  patchMembership: (
    membershipId: string,
    body: {
      autoRenew: boolean;
      paymentPlanId: string;
    },
  ) => Promise<PatchMembershipResponseType['data']>;
  getPaymentProfiles: (params?: { refetch: boolean }) => Promise<void>;
  postPaymentProfile: (body?: {
    autoRenew: boolean;
    paymentPlanId: string;
  }) => Promise<PaymentResponseType>;
  putPaymentProfile: (
    id: string,
    preferred: boolean,
  ) => Promise<PaymentResponseType>;
  patchPaymentProfile: (
    preferredId: string,
  ) => Promise<PatchPaymentProfileResponseType['data']>;
  deletePaymentProfile: (id: string) => Promise<void>;
  getAddresses: (searchString: string) => Promise<void>;
  getAddressDetail: (recordId: string) => Promise<AddressType | null>;
  clearAddressesFound: () => void;
  getIndustries: () => Promise<void>;
  setLoading: (loaders: Partial<typeof initialState.loading>) => void;
  clearData: () => void;
  clearErrors: () => void;
};

const useContactService = create<ContactStore>((set, get) => {
  return {
    ...initialState,
    getMyContact: async () => {
      if (get().myContact.data) return;
      try {
        set({ myContact: { ...get().myContact, loading: true } });
        const {
          data: { data, errors },
        }: APIResponseType<ContactType> = await http.get(`/contact/0`);
        set({ myContact: { data, loading: false }, errors });
      } catch (error) {
        set({
          myContact: { ...get().myContact, loading: false },
          myMemberships: { ...get().myMemberships, loading: false },
          errors: formatError(error),
        });
      }
    },
    putMyContact: async (updateData) => {
      const contactId = get().myContact.data?.id;
      if (!contactId || !updateData) return false;
      try {
        get().setLoading({ putMyContact: true });
        const {
          data: { data, errors },
        }: APIResponseType<boolean> = await http.put(
          `/contacts/${contactId}`,
          updateData,
        );
        get().setLoading({ putMyContact: false });
        if (data) {
          set({
            myContact: { data: updateData, loading: false },
            errors: { state: 'success', message: 'Successfully saved' },
          });
        } else if (errors) {
          set({ errors });
        }
        return true;
      } catch (error) {
        get().setLoading({ putMyContact: false });
        set({ errors: formatError(error) });
        return false;
      }
    },
    getMyMemberships: async (params) => {
      const contactId = get().myContact.data?.id;
      if (!contactId) return;
      if (!params?.refetch && get().myMemberships.data) return;
      try {
        set({ myMemberships: { ...get().myMemberships, loading: true } });
        const {
          data: { data, errors },
        }: APIResponseType<MembershipType[]> = await http.get(
          `/contacts/${contactId}/memberships`,
        );
        set({ myMemberships: { data, loading: false }, errors });
      } catch (error) {
        set({
          myMemberships: { ...get().myMemberships, loading: false },
          errors: formatError(error),
        });
      }
    },
    patchMembership: async (membershipId, body) => {
      const contactId = get().myContact.data?.id;
      if (!contactId || !get().myMemberships.data) return { data: null };
      try {
        get().setLoading({ patchMembership: true });
        const { data }: PatchMembershipResponseType = await http.patch(
          `/contacts/${contactId}/memberships/${membershipId}`,
          body,
        );
        get().setLoading({ patchMembership: false });
        if (data.errors) {
          set({ errors: data.errors });
          return { data: null };
        } else {
          set({ errors: { state: 'success', message: 'Successfully saved' } });
          return { data: 'success' };
        }
      } catch (error) {
        get().setLoading({ patchMembership: false });
        set({ errors: formatError(error) });
        return { data: null };
      }
    },
    getPaymentProfiles: async (params) => {
      const contactId = get().myContact.data?.id;
      if (!contactId) return;
      if (!params?.refetch && get().paymentProfiles.data) return;
      try {
        set({ paymentProfiles: { ...get().paymentProfiles, loading: true } });
        const {
          data: { data, errors },
        }: APIResponseType<PaymentProfileType[]> = await http.get(
          `/contacts/${contactId}/paymentprofiles`,
        );
        set({ paymentProfiles: { data, loading: false }, errors });
      } catch (error) {
        set({
          paymentProfiles: { ...get().paymentProfiles, loading: false },
          errors: formatError(error),
        });
      }
    },
    postPaymentProfile: async (body) => {
      const myContactData = get().myContact.data;
      if (!myContactData?.id) return { data: null };
      try {
        get().setLoading({ postPaymentProfile: true });
        const membershipId = get().myMemberships.data?.[0]?.id;
        const { firstName, lastName } = myContactData.personalDetails;
        const { data }: APIResponseType<PaymentGatewayTransactionRequestType> =
          await http.post(`/contacts/${myContactData.id}/paymentprofile`, {
            ...body,
            ...(membershipId ? { membershipId } : {}),
            firstName,
            lastName,
          });
        get().setLoading({ postPaymentProfile: false });
        if (data?.errors) set({ errors: data.errors });
        return data;
      } catch (error) {
        get().setLoading({ postPaymentProfile: false });
        const errors = formatError(error);
        set({ errors });
        return { data: null, errors };
      }
    },
    putPaymentProfile: async (id, preferred) => {
      const myContactData = get().myContact.data;
      if (!myContactData?.id) return { data: null };
      try {
        get().setLoading({ putPaymentProfile: true });
        const { firstName, lastName } = myContactData.personalDetails;
        const {
          data,
        }: APIResponseType<PaymentGatewayTransactionRequestType | null> = await http.put(
          `/contacts/${myContactData.id}/paymentprofiles/${id}`,
          {
            ...(preferred ? { preferredId: id } : {}),
            firstName,
            lastName,
          },
        );
        get().setLoading({ putPaymentProfile: false });
        if (data?.errors) set({ errors: data.errors });
        return data;
      } catch (error) {
        get().setLoading({ putPaymentProfile: false });
        const errors = formatError(error);
        set({ errors });
        return { data: null, errors };
      }
    },
    patchPaymentProfile: async (preferredId) => {
      const contactId = get().myContact.data?.id;
      if (!contactId) return { data: null };
      try {
        get().setLoading({ patchPaymentProfile: true });
        const { data }: PatchPaymentProfileResponseType = await http.patch(
          `/contacts/${contactId}/paymentprofiles`,
          { preferredId },
        );
        get().setLoading({ patchPaymentProfile: false });
        if (data?.errors) {
          set({ errors: data.errors });
        } else {
          set({ errors: { state: 'success', message: 'Successfully saved' } });
        }
        return data;
      } catch (error) {
        get().setLoading({ patchPaymentProfile: false });
        const errors = formatError(error);
        set({ errors });
        return { data: null, errors };
      }
    },
    deletePaymentProfile: async (id) => {
      const contactId = get().myContact.data?.id;
      if (!contactId) return;
      try {
        get().setLoading({ deletePaymentProfile: true });
        const {
          data: { data, errors },
        }: APIResponseType<PaymentGatewayResponseType> = await http.delete(
          `/contacts/${contactId}/paymentprofiles/${id}`,
        );
        get().setLoading({ deletePaymentProfile: false });
        if (data) {
          set(
            produce((draft: ContactStoreState) => {
              if (draft.paymentProfiles.data) {
                draft.paymentProfiles.data = draft.paymentProfiles.data?.filter(
                  ({ id: Id }) => Id !== id,
                );
              }
              draft.paymentProfiles.loading = false;
              draft.errors = {
                state: 'success',
                message: 'Successfully deleted',
              };
            }),
          );
        } else if (errors) {
          set({ errors });
        }
      } catch (error) {
        get().setLoading({ deletePaymentProfile: false });
        set({ errors: formatError(error) });
      }
    },
    getAddresses: async (searchString) => {
      try {
        set({ addressesFound: { ...get().addressesFound, loading: true } });
        const {
          data: { data, errors },
        }: APIResponseType<SearchAddressResponseAddressRecordType[]> =
          await http.post(`/address`, {
            searchString,
          });
        set({ addressesFound: { data, loading: false }, errors });
      } catch (error) {
        set({
          addressesFound: { ...get().addressesFound, loading: false },
          errors: formatError(error),
        });
      }
    },
    getAddressDetail: async (recordId) => {
      try {
        const {
          data: { data, errors },
        }: APIResponseType<AddressType> = await http.get(
          `/addresses/${recordId}`,
        );
        set({ errors });
        return data ?? null;
      } catch (error) {
        set({ errors: formatError(error) });
        return null;
      }
    },
    clearAddressesFound: () => {
      set({ addressesFound: { data: null, loading: false } });
    },
    getIndustries: async () => {
      try {
        set({ industries: { ...get().industries, loading: true } });
        const {
          data: { data, errors },
        }: APIResponseType<IndustryType[]> = await http.get(
          `/contacts/industries/list`,
        );
        set({ industries: { data, loading: false }, errors });
      } catch (error) {
        set({ errors: formatError(error) });
      }
    },
    setLoading: (loaders) => set({ loading: { ...get().loading, ...loaders } }),
    clearData: () => set(initialState),
    clearErrors: () => set({ errors: null }),
  };
});

export default useContactService;
