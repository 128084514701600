import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from '@emotion/react';
import { Text } from 'styles';
import { MoonLoader } from 'react-spinners';

interface IStyledLargeButtonProps {
  maxWidth?: string;
  variant?: 'primary' | 'secondary';
}

interface ILargeButtonProps extends IStyledLargeButtonProps {
  type?: 'button' | 'submit';
  loading?: boolean;
  disabled?: boolean;
  label: string;
  description: string;
  onClick?: () => void;
}

const StyledLargeButton = styled.button<IStyledLargeButtonProps>(
  ({ maxWidth, variant, disabled }) => [
    tw`
    flex
    flex-col
    justify-center
    items-center
    py-8
    px-10
    border-2
    transition
    duration-300
    w-full`,
    `max-width: ${maxWidth};`,
    variant === 'primary' &&
      (disabled
        ? tw`bg-secondaryKeyline border-secondaryKeyline`
        : tw`bg-primaryPurple border-primaryPurple hover:bg-primaryBlue hover:border-primaryBlue`),
    variant === 'secondary' &&
      (disabled
        ? tw`border-neutralDarkGrey`
        : tw`border-primaryPurple hover:bg-primaryPurple`),
  ],
);

const Content = styled.div(({ loading }: { loading: 'true' | undefined }) => [
  loading ? tw`invisible` : tw``,
]);

const ButtonTextReturn =
  (type: 'title' | 'description') =>
  ({
    variant,
    disabled,
  }: {
    variant: ILargeButtonProps['variant'];
    disabled: boolean;
  }) =>
    [
      tw`text-center transition duration-300`,
      type === 'title' && tw`sm:mb-1 tracking-widest`,
      variant === 'primary' &&
        (disabled ? tw`text-neutralDarkGrey` : tw`text-white`),
      variant === 'secondary' &&
        (disabled
          ? tw`text-neutralDarkGrey`
          : tw`text-primaryPurple group-hover:text-white`),
    ];
const ButtonTitle = styled(Text.NormalBold)(ButtonTextReturn('title'));
const ButtonDescription = styled(Text.Small)(ButtonTextReturn('description'));

const Loader = styled.div(({ loading }: { loading: 'true' | undefined }) => [
  tw`absolute`,
  loading ? tw`` : tw`invisible`,
]);

const override = css`
  display: flex;
`;

const LargeButton: React.FC<ILargeButtonProps> = ({
  type = 'button',
  label,
  description,
  maxWidth = '22.875rem',
  variant = 'primary',
  disabled = false,
  loading = false,
  onClick = () => {},
}) => {
  const isDisabled = disabled || loading;
  const isLoading = loading ? 'true' : undefined;
  return (
    <StyledLargeButton
      className={isDisabled ? '' : 'group'}
      type={type}
      maxWidth={maxWidth}
      variant={variant}
      disabled={isDisabled}
      onClick={onClick}>
      <Content loading={isLoading}>
        <ButtonTitle variant={variant} disabled={isDisabled}>
          {label}
        </ButtonTitle>
        <ButtonDescription variant={variant} disabled={isDisabled}>
          {description}
        </ButtonDescription>
      </Content>
      <Loader loading={isLoading}>
        <MoonLoader
          css={override}
          color="#A1A1A1"
          loading
          size={28}
          speedMultiplier={0.8}
        />
      </Loader>
    </StyledLargeButton>
  );
};

export default LargeButton;
