import { useEffect } from 'react';
import { Toast } from 'components';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import {
  useCashOnCardService,
  useContactService,
  useDashboardService,
  useErrorService,
  useMembershipService,
  useTransactionsService,
} from 'services';

const ErrorWrapper = styled.div`
  ${tw`
    fixed w-full top-0 left-0 z-50
  `}
`;
const ErrorComponent = () => {
  const { errors: dashboardErrors, clearErrors: clearDashBoardError } =
    useDashboardService();
  const { errors: contactErrors, clearErrors: clearContactErrors } =
    useContactService();
  const { errors: membershipErrors, clearErrors: clearMembershipErrors } =
    useMembershipService();
  const { errors: transactionErrors, clearErrors: clearTransactionErrors } =
    useTransactionsService();
  const { errors: cachOnCardErrors, clearErrors: clearCachOnCardErrors } =
    useCashOnCardService();
  const { errors, setError } = useErrorService();

  const navigate = useNavigate();

  useEffect(() => {
    if (dashboardErrors) {
      setError(dashboardErrors);
      clearDashBoardError();
    }
    if (contactErrors) {
      setError(contactErrors);
      clearContactErrors();
    }
    if (membershipErrors) {
      if (
        membershipErrors &&
        typeof membershipErrors !== 'string' &&
        membershipErrors.statusCode &&
        membershipErrors.statusCode.includes('401')
      ) {
        navigate('/login');
      } else {
        setError(membershipErrors);
        clearMembershipErrors();
      }
    }
    if (transactionErrors) {
      setError(transactionErrors);
      clearTransactionErrors();
    }
    if (cachOnCardErrors) {
      setError(cachOnCardErrors);
      clearCachOnCardErrors();
    }
  }, [
    dashboardErrors,
    clearDashBoardError,
    contactErrors,
    clearContactErrors,
    membershipErrors,
    clearMembershipErrors,
    transactionErrors,
    clearTransactionErrors,
    cachOnCardErrors,
    clearCachOnCardErrors,
    setError,
    errors,
    navigate,
  ]);

  return (
    <ErrorWrapper>
      {errors && typeof errors === 'string' && errors !== '' && (
        <Toast state="error" error={{ message: errors }}></Toast>
      )}
      {errors &&
        typeof errors !== 'string' &&
        errors.message &&
        errors.message !== '' && (
          <Toast
            state={errors.state ? errors.state : 'error'}
            error={{ message: errors.message }}
          ></Toast>
        )}
    </ErrorWrapper>
  );
};
export default ErrorComponent;
