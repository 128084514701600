import tw from 'twin.macro';
import styled from 'styled-components';
import {
  BorderedSections,
  PaymentProfileExpiry,
  PersonalContentCellVerticalWrapper,
  Section,
  Sections,
  Title,
} from '../../styles';
import { useContactService } from 'services';
import {
  FieldList,
  FieldWrapper,
  LabelWrapper,
  Label,
  PersonalContentCell,
  PaymentPlaceholderWrapper,
  StyledNoPaymentOptionsText,
} from '../../styles';
import { Loader } from '../../Loader';
import { booleanToText } from 'helpers';
import { Text } from 'styles';

const PreferencesTitle = styled(Title)`
  ${tw`mb-10`}
`;

const PaymentLabelPreferred = styled(Text.Small)`
  ${tw`
    text-xs md:text-sm ml-1
  `}
  line-height: 1.6rem;
`;

export const ViewPaymentSettings = () => {
  const [myContactData, paymentProfilesData, paymentProfilesLoading] =
    useContactService((state) => [
      state.myContact.data,
      state.paymentProfiles.data,
      state.paymentProfiles.loading,
    ]);

  return (
    <Sections>
      <BorderedSections>
        {/* Payment Settings */}
        <Section first>
          {myContactData && !paymentProfilesLoading && (
            <FieldList>
              {!!paymentProfilesData?.length ? (
                <>
                  {paymentProfilesData.map((paymentProfile, index) => {
                    const { id, cardNumber, preferred, expiryDate, expired } =
                      paymentProfile;
                    return (
                      <FieldWrapper key={id}>
                        <LabelWrapper
                          style={{ display: 'flex', gap: '0.25rem' }}>
                          <Label>Card {index + 1}</Label>
                          {preferred && (
                            <PaymentLabelPreferred>
                              (Preferred)
                            </PaymentLabelPreferred>
                          )}
                        </LabelWrapper>
                        <PersonalContentCellVerticalWrapper>
                          <PersonalContentCell>
                            {cardNumber}
                          </PersonalContentCell>
                          <PaymentProfileExpiry expired={expired}>
                            {expired ? 'Expired' : 'Expires'} {expiryDate}
                          </PaymentProfileExpiry>
                        </PersonalContentCellVerticalWrapper>
                      </FieldWrapper>
                    );
                  })}
                </>
              ) : (
                <PaymentPlaceholderWrapper>
                  <StyledNoPaymentOptionsText>
                    You don't have any payment options saved.
                  </StyledNoPaymentOptionsText>
                </PaymentPlaceholderWrapper>
              )}
            </FieldList>
          )}
          {(!myContactData || paymentProfilesLoading) && <Loader />}
        </Section>

        {/* Preferences */}
        <Section last>
          <PreferencesTitle>Preferences</PreferencesTitle>
          {myContactData && (
            <FieldList>
              <FieldWrapper>
                <LabelWrapper>
                  <Label>VRC Marketing</Label>
                </LabelWrapper>
                <PersonalContentCell>
                  {booleanToText(myContactData?.allowVrcMembershipMarketing)}
                </PersonalContentCell>
              </FieldWrapper>
            </FieldList>
          )}
          {!myContactData && <Loader />}
        </Section>
      </BorderedSections>
    </Sections>
  );
};
