import React from 'react';
import styled from 'styled-components';
import { LinkType, StyledLinkType } from './type';
import linkBaseStyle from './style';
import { ExternalIcon } from 'components/shared/svgs/externalIcon';
import 'styled-components/macro';

const StyledLink = styled.a<StyledLinkType>(linkBaseStyle);

const ExternalLink: React.FC<LinkType> = ({
  to,
  children,
  iconColor = '#e1dbd2',
  visibleIcon = true,
  ...props
}) => {
  return (
    <StyledLink href={to} target={`_blank`} {...props}>
      {children}
      {visibleIcon && <ExternalIcon color={iconColor} />}
    </StyledLink>
  );
};

export default ExternalLink;
