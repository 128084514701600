import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Text } from 'styles';
import { Link } from 'react-router-dom';
import { Button } from 'components';
import { useDeviceSize } from 'hooks';
import { PaymentResponseIconVariantType, ResponseLogo } from 'pages/membership';

const Container = tw.div`
  bg-secondaryCream
  absolute
  inset-0
  flex
  flex-col
  justify-center
  items-center
  min-h-screen
`;

const InnerContainer = tw.div`
  flex
  flex-col
  justify-center
  items-center
  w-full
  max-w-[26.375rem]
  p-3
`;

const Header = styled.h1`
  ${tw`mt-10 mb-2`}
`;

const ErrorLabel = styled.span`
  ${tw`
  mr-1
  text-md
  leading-md
  sm:text-lg
  sm:leading-lg
  font-bold
  `}
`;

const Error = styled.p`
  ${tw`
  mx-2
  my-4
  sm:my-6
  text-center
  text-lightRed`}
`;

const ButtonText = styled.p``;

interface IRenewalResponseProps {
  iconVariant: PaymentResponseIconVariantType;
  headerText: string;
  errorText: string;
}

const RenewalResponse: React.FC<IRenewalResponseProps> = ({
  iconVariant,
  headerText,
  errorText,
}) => {
  const { isMobile } = useDeviceSize();
  const responsiveHeader = isMobile ? Text.H5 : Text.H2;
  const responsiveError = isMobile ? Text.Normal : Text.Large;
  const responsiveButtonText = isMobile ? Text.XSmallBold : Text.NormalBold;

  return (
    <Container>
      <InnerContainer>
        <ResponseLogo iconVariant={iconVariant} />
        <Header as={responsiveHeader}>{headerText}</Header>
        {!!errorText && (
          <Error as={responsiveError}>
            <ErrorLabel>Error:</ErrorLabel>
            {errorText}
          </Error>
        )}

        <Link
          to={{
            pathname: '/membership',
            search: '?tabId=payment-settings-and-preferences',
          }}
          style={{
            width: '100%',
            marginTop: '0.8rem',
            marginBottom: '0.8rem',
          }}
        >
          <Button
            maxWidth="26.375rem"
            paddingVertical="1rem"
            letterSpacing="0.125rem"
          >
            <ButtonText as={responsiveButtonText}>
              RETURN TO MEMBER DASHBOARD
            </ButtonText>
          </Button>
        </Link>

        <a href="https://www.vrc.com.au/" style={{ width: '100%' }}>
          <Button
            variant="secondary"
            borderWidth="0.16rem"
            maxWidth="26.375rem"
            paddingVertical="1rem"
            letterSpacing="0.125rem"
          >
            <ButtonText as={responsiveButtonText}>
              RETURN TO VRC WEBSITE
            </ButtonText>
          </Button>
        </a>
      </InnerContainer>
    </Container>
  );
};

export default RenewalResponse;
