import { BASE_ABSOLUTE_URL } from 'configs/constants';
import {
  Configuration,
  PublicClientApplication,
  RedirectRequest,
} from '@azure/msal-browser';

const CLIENT_ID = process.env.REACT_APP_AZURE_B2C_CLIENT_ID;
const TENANT = process.env.REACT_APP_AZURE_B2C_TENANT;
const SIGNIN_POLICY = process.env.REACT_APP_AZURE_B2C_SIGNIN_POLICY;
const REDIRECT_URI = `${BASE_ABSOLUTE_URL}/login`;
const B2C_PATH = `https://${TENANT}.b2clogin.com/${TENANT}.onmicrosoft.com`;
const PASSWORD_RESET_POLICY =
  process.env.REACT_APP_AZURE_B2C_PASSWORD_RESET_POLICY;

export const b2cPolicies = {
  authorityDomain: `${TENANT}.b2clogin.com`,
  authorities: {
    signIn: `${B2C_PATH}/${SIGNIN_POLICY}`,
    passwordReset: `${B2C_PATH}/${PASSWORD_RESET_POLICY}`,
  },
};

const msalConfig: Configuration = {
  auth: {
    clientId: CLIENT_ID as string,
    authority: b2cPolicies.authorities.signIn,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: REDIRECT_URI,
    postLogoutRedirectUri: REDIRECT_URI,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const passwordResetRequest: RedirectRequest | undefined = {
  authority: b2cPolicies.authorities.passwordReset,
  scopes: [],
};

const publicClientApp = new PublicClientApplication(msalConfig);

export default publicClientApp;
