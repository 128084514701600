import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Text } from 'styles';
import { useDeviceSize } from 'hooks';
import { MoonLoader } from 'react-spinners';
import { FlexRowContainer } from '../membership/styles';

const PaymentDueContainer = tw.div`flex my-1 md:my-3`;
const PaymentDueLabel = styled.p`
  ${tw`mr-2`}
`;
const PaymentDueText = styled.p``;

interface IPaymentAmountProps {
  label: string;
  text: string;
  amount: number;
  loading?: boolean;
}

const PaymentAmount: React.FC<IPaymentAmountProps> = ({
  label,
  text,
  amount,
  loading = false,
}) => {
  const { isMobile } = useDeviceSize();

  const responsiveDueLabel = isMobile ? Text.LargeBold : Text.XLargeBold;
  const responsiveDueText = isMobile ? Text.Large : Text.XLarge;

  return (
    <FlexRowContainer>
      <PaymentDueContainer>
        <PaymentDueLabel as={responsiveDueLabel}>{label}</PaymentDueLabel>
        <PaymentDueText as={responsiveDueText}>{text}</PaymentDueText>
      </PaymentDueContainer>
      {loading ? (
        <MoonLoader size={20} speedMultiplier={0.8} />
      ) : (
        <PaymentDueContainer>
          <PaymentDueText as={responsiveDueText}>{`$${amount.toFixed(
            2,
          )}`}</PaymentDueText>
        </PaymentDueContainer>
      )}
    </FlexRowContainer>
  );
};

export default PaymentAmount;
