import React from 'react';
import tw from 'twin.macro';

const StyledRadio = tw.div`
  flex
  flex-shrink-0
  justify-center
  items-center
  w-[24px]
  h-[24px]
  sm:w-[36px]
  sm:h-[36px]
  rounded-full
  bg-neutralGrey
  cursor-pointer
`;

const SelectedRadio = tw.div`
  w-[12px]
  h-[12px]
  sm:w-[18px]
  sm:h-[18px]
  rounded-full
  bg-primaryPurple
  cursor-pointer
`;

interface IRadioProps {
  selected: boolean;
  handleOnClick?: () => void;
}

const Radio: React.FC<IRadioProps> = ({ selected, handleOnClick }) => {
  return (
    <StyledRadio onClick={handleOnClick}>
      {selected && <SelectedRadio />}
    </StyledRadio>
  );
};

export default Radio;
