import React from 'react';
import styled from 'styled-components';
import { LinkType, StyledLinkType } from './type';
import linkBaseStyle from './style';
import { Link } from 'react-router-dom';
import 'styled-components/macro';

const StyledLink = styled.span<StyledLinkType>(linkBaseStyle);

const CustomLink: React.FC<
  LinkType & {
    dataProps?: Record<string, string>;
  }
> = ({ to, children, dataProps, ...props }) => {
  return (
    <Link to={to} onClick={() => window.scrollTo(0, 0)} {...dataProps}>
      <StyledLink {...props}>{children}</StyledLink>
    </Link>
  );
};

export default CustomLink;
