import tw from 'twin.macro';
import { StyledLinkType } from './type';

const linkBaseStyle = ({
  color = '',
  hoverBorderColor = '',
  justifyContent = 'start',
  borderWidth = '0',
  borderColor = 'black',
  backgroundColor = 'none',
  paddingHorizontal = '0',
  paddingVertical = '0',
  marginHorizontal = '0',
  marginVertical = '0',
  fontSize = '0.875rem',
  underline = 'false',
}: StyledLinkType) => [
  tw`
    inline-flex
    items-center
    text-primaryPurple
    cursor-pointer
    w-full md:w-auto
    justify-center
    whitespace-nowrap
  `,
  `
    justify-content: ${justifyContent};
    border-width: ${borderWidth};
    border-color: ${borderColor};
    padding: ${paddingVertical} ${paddingHorizontal};
    margin: ${marginVertical} ${marginHorizontal};
    background:${backgroundColor};
    font-size:${fontSize};
    transition-duration: 0.3s;
  `,
  underline === 'true' ? tw`underline` : '',
  hoverBorderColor
    ? `
    &:hover {
      border-color: ${hoverBorderColor};
      background: ${hoverBorderColor}
    }
    `
    : '',
  color
    ? `
    &:hover, &:hover > p {
      color: ${color};
    }
    &:hover > span {
      filter: brightness(0) saturate(100%) invert(10%) sepia(50%) saturate(1730%) hue-rotate(229deg) brightness(107%) contrast(105%);
    }
  `
    : '',
];

export default linkBaseStyle;
