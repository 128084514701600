import { RefObject, useEffect } from 'react';

interface IUseOutsideClickProps {
  refs: RefObject<HTMLElement>[];
  callback: () => void;
}

const useOutsideClick = ({ refs, callback }: IUseOutsideClickProps) => {
  const handleClick = (e: any) => {
    const isIncluded = refs.some((ref) => ref.current?.contains(e.target));
    if (!isIncluded) callback();
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useOutsideClick;
