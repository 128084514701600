import { SubmitButton } from 'components';
import { BorderButton } from 'components/shared/Button';
import { Text } from 'styles';
import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrapper = tw.div`flex flex-col gap-10`;

export const TitleWrapper = tw.div`flex flex-wrap items-center justify-between gap-4 md:gap-7`;

export const Title = styled(Text.H2)`
  ${tw`text-h4 md:text-h2`}
`;

export const Desktop = tw.div`hidden md:block`;
export const Mobile = tw.div`block md:hidden`;

export const Buttons = tw.div`
  flex
  flex-col items-stretch gap-3 w-full
  sm:flex-row sm:items-center sm:gap-6 sm:w-auto
`;

export const BodyWrapper = tw.div``;

export const FooterWrapper = tw.div`flex justify-between items-center gap-7 flex-col md:flex-row`;

export const Sections = tw.div`flex flex-col`;

export const BorderedSections = styled(Sections)`
  ${tw`divide-y`}
`;

export const Section = styled.div<{ first?: boolean; last?: boolean }>(
  ({ first = false, last = false }) => [
    first ? tw`pt-0` : tw`pt-10`,
    last ? tw`pb-0` : tw`pb-10`,
  ],
);

export const OutlinedButton = styled(BorderButton)`
  letter-spacing: 0.1em;
  ${tw`
    h-auto text-primaryPurple hover:text-white hover:bg-primaryPurple transition duration-300 uppercase py-3 px-3 sm:px-7
  `}
`;

export const SaveButton = styled(SubmitButton)`
  @media only screen and (max-width: 767px) {
    width: 100%;
    max-width: none;
    order: -1;
  }
`;

export const TertiaryButton = styled.button`
  ${tw`underline transition duration-300 hover:text-primaryBlue`}
`;

export const ToggleVisibilityWrapper = styled.div<{ visible?: boolean }>(
  ({ visible = true }) => [visible ? tw`` : tw`opacity-50`],
);

export const FieldList = tw.div`relative flex flex-col gap-4 flex-grow`;

export const FieldWrapper = tw.div`
flex items-start
flex-col sm:flex-row
gap-1 sm:gap-4 md:gap-8
w-full max-w-full sm:max-w-[640px]
`;

export const EditFieldList = tw.div`relative flex flex-col gap-4 flex-grow`;

export const EditFieldWrapper = tw.div`flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-8 w-full max-w-full sm:max-w-[640px]`;

export const LabelWrapper = styled.div`
  ${tw`
    flex-shrink-0 text-left min-w-[8rem] sm:min-w-[9rem] md:min-w-[10rem]
  `}
`;

export const Label = styled(Text.MediumBold)`
  ${tw`
    text-sm md:text-md flex-shrink-0
  `}
`;

export const PersonalContentCellVerticalWrapper = tw.div`
  flex flex-col gap-y-0.5 md:gap-y-1
`;

export const PersonalContentCell = styled(Text.Medium)`
  ${tw`
    text-sm md:text-md
  `}
  overflow-wrap: anywhere;
`;

export const PersonalLabelPreferred = styled(Text.Small)`
  ${tw`text-xs md:text-sm`}
`;

export const PaymentProfileExpiry = styled(PersonalLabelPreferred)(
  ({ expired = false }: { expired?: boolean }) => [
    expired ? tw`text-failedRed` : tw`text-primaryBlue`,
  ],
);

export const PaymentPlaceholderWrapper = tw.div`
  flex justify-between items-start items-center bg-white w-full
  px-3 py-5 md:px-0
  mt-2 md:mt-0
`;

export const StyledNoPaymentOptionsText = tw(Text.Medium)`
  text-sm md:text-md
`;
