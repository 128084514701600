import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import 'styled-components/macro';
import { Text } from 'styles';
import { Button } from 'components';
import { deviceSize } from 'components/shared/types';
import { LoaderCard } from 'components/Loaders/LoaderCard';
import { useTransactionsService } from 'services';
import { useMediaQuery } from 'react-responsive';
import { ContactType } from 'type';

const DownloadPDFLoader = tw.div`flex justify-end max-w-[250px]`;
const DownloadPDFText = styled.p`
  ${tw`font-semibold tracking-widest px-8`}
`;

interface IDownloadPDFButtonProps {
  hide?: boolean;
  marginVertical?: string;
  myMembership: ContactType | null;
}

const DownloadPDFWrapper = styled.div<IDownloadPDFButtonProps>(
  ({ hide = false, marginVertical = '1rem' }) => [
    hide ? tw`hidden` : tw`block`,
    `margin: ${marginVertical} 0`,
  ],
);

const DownloadPDFButton: React.FC<IDownloadPDFButtonProps> = (props) => {
  const { myMembership, hide } = props;
  const isTablet = useMediaQuery({ maxWidth: deviceSize.tablet });
  const loadingId = useTransactionsService((s) => s.loading.transactionId);

  if (hide) return null;

  return !myMembership || !!loadingId ? (
    <DownloadPDFLoader>
      <LoaderCard
        height={isTablet ? 30 : 50}
        width="100%"
        backgroundColor="#efeee9"
        foregroundColor="#f9f8f2"
      />
    </DownloadPDFLoader>
  ) : (
    <DownloadPDFWrapper {...props}>
      <Button
        maxWidth="100%"
        paddingHorizontal="1.25rem"
        paddingVertical="0.8rem">
        <DownloadPDFText as={isTablet ? Text.XSmall : Text.Small} color="white">
          DOWNLOAD PDF
        </DownloadPDFText>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          tw="h-5 w-5 ml-2">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
          />
        </svg>
      </Button>
    </DownloadPDFWrapper>
  );
};

export default DownloadPDFButton;
