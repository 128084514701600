import tw from 'twin.macro';
import styled from 'styled-components';
import {
  SVGWrapper,
  LoaderCard,
  LoaderCardWrapper,
} from '../Loaders/LoaderCard';
import {
  DetailedLast,
  DetailedWrapper,
  LoadDetailed,
  MembershipWrapper,
  PaymentAlertWrapper,
} from 'components/membership/styles';
import { deviceSize } from 'components/shared/types';
import { useMediaQuery } from 'react-responsive';

const LoaderFullCard1 = () => {
  return (
    <LoaderCardWrapper width="20%">
      <LoaderCard
        height={42}
        backgroundColor="#201547"
        foregroundColor="#302653"
      />
    </LoaderCardWrapper>
  );
};

const PartialLoader = [1, 2, 3, 4, 5];
const LoadDetailedTitle = styled.div`
  width: 8.125rem;
  ${tw`
    mr-2 md:mr-0
  `}
`;
const LoadDetailedDescription = styled.div`
  ${tw`
    mt-0 md:mt-2
  `}
`;
const LoaderFullCard2 = () => {
  const isTablet = useMediaQuery({ maxWidth: deviceSize.tablet });
  return (
    <DetailedWrapper>
      {PartialLoader.map((p) => (
        <LoadDetailed key={`partial_${p}`}>
          <LoadDetailedTitle>
            <LoaderCardWrapper width="50%">
              <LoaderCard
                height={14}
                backgroundColor="#201547"
                foregroundColor="#312754"
              />
            </LoaderCardWrapper>
          </LoadDetailedTitle>
          <LoadDetailedDescription>
            <LoaderCardWrapper width="100%">
              <LoaderCard
                height={isTablet ? 14 : 42}
                backgroundColor="#221748"
                foregroundColor="#302653"
              />
            </LoaderCardWrapper>
          </LoadDetailedDescription>
        </LoadDetailed>
      ))}
      <DetailedLast></DetailedLast>
    </DetailedWrapper>
  );
};

const LoaderFullCard3 = () => {
  return (
    <PaymentAlertWrapper>
      <LoaderCardWrapper width="60%">
        <LoaderCard
          height={24}
          backgroundColor="#302653"
          foregroundColor="#231849"
        />
      </LoaderCardWrapper>
    </PaymentAlertWrapper>
  );
};

export const MembershipLoader: React.FC<{ me: boolean }> = ({ me = false }) => {
  return (
    <SVGWrapper>
      <MembershipWrapper me={me}>
        <LoaderFullCard1 />
        <LoaderFullCard2 />
        <LoaderFullCard3 />
      </MembershipWrapper>
    </SVGWrapper>
  );
};
