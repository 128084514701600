import { ExternalLink } from 'components';
import { format } from 'date-fns';
import { useContactService } from 'services';
import styled from 'styled-components';
import { Text } from 'styles';
import tw from 'twin.macro';
import { Loader } from '../../Loader';
import {
  FieldWrapper,
  PersonalContentCell,
  FieldList,
  Label,
  LabelWrapper,
} from '../../styles';

const ContactLink = styled(ExternalLink)`
  ${tw`
    inline underline
  `}
`;

export const LongDescription = tw.div`
  inline-block
  md:text-right
`;

interface IBasicInfoProps {
  isEdit: boolean;
}

const SUPPORT_EMAIL = 'membership@vrc.net.au';

export const BasicInfo = ({ isEdit }: IBasicInfoProps) => {
  const myContactData = useContactService((state) => state.myContact.data);

  if (!myContactData) {
    return <Loader />;
  }

  return (
    <FieldList>
      <FieldWrapper>
        <LabelWrapper>
          <Label>Last Name</Label>
        </LabelWrapper>
        <PersonalContentCell>
          {myContactData.personalDetails.lastName}
        </PersonalContentCell>
      </FieldWrapper>
      <FieldWrapper>
        <LabelWrapper>
          <Label>First Name</Label>
        </LabelWrapper>
        <PersonalContentCell>
          {myContactData.personalDetails.firstName}
        </PersonalContentCell>
      </FieldWrapper>
      <FieldWrapper>
        <LabelWrapper>
          <Label>Date Of Birth</Label>
        </LabelWrapper>
        <PersonalContentCell>
          {myContactData.personalDetails.dateOfBirth
            ? format(
                new Date(myContactData.personalDetails.dateOfBirth),
                'dd MMMM yyyy',
              )
            : ''}
        </PersonalContentCell>
      </FieldWrapper>
      <FieldWrapper>
        <LabelWrapper>
          <Label>Email</Label>
        </LabelWrapper>
        <PersonalContentCell>
          {myContactData.personalDetails.email}
        </PersonalContentCell>
      </FieldWrapper>
      <FieldWrapper>
        <LabelWrapper>
          <Label>Alternate Email</Label>
        </LabelWrapper>
        <PersonalContentCell>
          {myContactData.personalDetails?.alternateEmail}
        </PersonalContentCell>
      </FieldWrapper>

      {isEdit && (
        <LongDescription>
          <Text.Small>
            To update, please call {` `}
            <ContactLink
              to={'tel:1300 727 575'}
              color="#4C4581"
              visibleIcon={false}>
              1300 727 575
            </ContactLink>
            {` `} or email{` `}
            <ContactLink
              to={`mailto:${SUPPORT_EMAIL}`}
              color="#4C4581"
              visibleIcon={false}>
              {SUPPORT_EMAIL}
            </ContactLink>
          </Text.Small>
        </LongDescription>
      )}
    </FieldList>
  );
};
