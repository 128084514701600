import React, { useEffect, useState } from 'react';
import tw, { TwStyle } from 'twin.macro';
import styled from 'styled-components';
import 'styled-components/macro';
import { Text } from 'styles';
import { ToastState } from 'type/api/types';
import useErrorService from 'services/error/error.service';

const DEFAULT_ERROR_MESSAGE =
  'Something went wrong. Refresh the page to try again.';

interface IIconProps {
  color?: string;
}

const CircleExclamationIcon: React.FC<IIconProps> = ({ color = '#201547' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    color={color}
    tw="w-[1.375rem] h-[1.375rem] sm:mr-3"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

const TriangleExclamationIcon: React.FC<IIconProps> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    tw="w-[1.375rem] h-[1.375rem] text-primaryPurple sm:mr-3"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
    />
  </svg>
);

const CheckIcon: React.FC<IIconProps> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    tw="w-[1.375rem] h-[1.375rem] text-primaryPurple sm:mr-3"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 13l4 4L19 7"
    />
  </svg>
);

type ToastType = {
  IconType: { [state in IState['state']]: JSX.Element };
  BgColorType: { [state in IState['state']]: TwStyle };
};

const toastIcon: ToastType['IconType'] = {
  info: <CircleExclamationIcon />,
  success: <CheckIcon />,
  warning: <TriangleExclamationIcon />,
  error: <CircleExclamationIcon color="#FFF" />,
};

const bgColor: ToastType['BgColorType'] = {
  info: tw`bg-neutralGrey`,
  success: tw`bg-specialPistachio`,
  warning: tw`bg-primaryPink`,
  error: tw`bg-lightRed`,
};

interface IState {
  state: ToastState;
  hide?: boolean;
}

interface IStyledToastProps extends IState {}

const StyledToast = styled.div<IStyledToastProps>(({ state, hide = false }) => [
  tw`
  flex
  flex-col
  sm:flex-row
  items-center
  border
  px-6
  transform
  origin-top
  transition-all
  duration-1000
  overflow-hidden
  text-white
`,
  bgColor[state],
  hide
    ? tw`opacity-0 max-h-0 py-0`
    : tw`opacity-100 max-h-[100px] py-3 sm:py-4`,
]);

interface IToastProps extends IStyledToastProps {
  durationToFade?: number;
  error: {
    field?: string | null;
    message: string;
  };
  hidden?: boolean;
}

const Toast: React.FC<IToastProps> = ({
  state,
  durationToFade = 6000,
  error: { field, message },
  hidden = false,
}) => {
  const [hide, setHide] = useState<boolean>(hidden);
  const { clearError } = useErrorService();

  useEffect(() => {
    const fade = setTimeout(() => {
      setHide(true);
      clearError();
    }, durationToFade);
    return () => {
      clearTimeout(fade);
    };
  }, [durationToFade, clearError]);

  if (field) return null;
  return (
    <StyledToast state={state} hide={hide}>
      {toastIcon[state]}
      <Text.Normal
        color={state === 'error' ? '#FFF' : '#201547'}
        textAlign="center"
      >
        {message || DEFAULT_ERROR_MESSAGE}
      </Text.Normal>
    </StyledToast>
  );
};

export default Toast;
