import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface IUseForceRedirectProps {
  triggerCondition: () => boolean;
  redirectPath?: string;
}

const useForceRedirect = ({
  triggerCondition,
  redirectPath = '/',
}: IUseForceRedirectProps) => {
  const navigate = useNavigate();
  const triggered = triggerCondition();

  useEffect(() => {
    if (triggered) {
      navigate(redirectPath, { replace: true });
    }
  }, [triggered, navigate, redirectPath]);
};

export default useForceRedirect;
