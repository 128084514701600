import React from 'react';
import { Text } from 'styles';
import { Input } from 'components';
import {
  LabelWrapper,
  EditFieldWrapper,
  EditFieldList,
  Label,
} from '../../../../styles';
import { AddressKind } from 'helpers';
import { MemberSearchAddressButton } from './MemberSearchAddressButton';
import { useFormContext } from 'react-hook-form';
import { ContactType } from 'type';
import { useContactService } from 'services';
import tw from 'twin.macro';

const LabelWithOptional = tw.div`
  flex flex-col
`;

interface IMemberAddressManualProps {
  inputName: AddressKind;
  handleSearchAddress: () => void;
}

export const MemberAddressManual: React.FC<IMemberAddressManualProps> = ({
  inputName,
  handleSearchAddress,
}) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext<ContactType>();

  const clearFoundAddresses = useContactService(
    (state) => state.clearAddressesFound,
  );

  const handleSearchAddressClicked = () => {
    clearFoundAddresses();
    handleSearchAddress();
  };

  return (
    <EditFieldList>
      <EditFieldWrapper>
        <LabelWrapper>
          <Label>Address Line 1</Label>
        </LabelWrapper>
        <Input
          name={`addresses.${inputName}.street1`}
          errorMessage={errors?.addresses?.[`${inputName}`]?.street1?.message}
          handleChangeText={(text) =>
            setValue(`addresses.${inputName}.street1`, text)
          }
        />
      </EditFieldWrapper>
      <EditFieldWrapper>
        <LabelWrapper>
          <LabelWithOptional>
            <Label>Address Line 2</Label>
            <Text.Small color="#51504D">(Optional)</Text.Small>
          </LabelWithOptional>
        </LabelWrapper>
        <Input
          name={`addresses.${inputName}.street2`}
          handleChangeText={(text) =>
            setValue(`addresses.${inputName}.street2`, text)
          }
        />
      </EditFieldWrapper>
      <EditFieldWrapper>
        <LabelWrapper>
          <Label>Suburb</Label>
        </LabelWrapper>
        <Input
          name={`addresses.${inputName}.suburb`}
          errorMessage={errors?.addresses?.[`${inputName}`]?.suburb?.message}
          handleChangeText={(text) =>
            setValue(`addresses.${inputName}.suburb`, text)
          }
        />
      </EditFieldWrapper>
      <EditFieldWrapper>
        <LabelWrapper>
          <Label>State</Label>
        </LabelWrapper>
        <Input
          name={`addresses.${inputName}.state`}
          errorMessage={errors?.addresses?.[`${inputName}`]?.state?.message}
          handleChangeText={(text) =>
            setValue(`addresses.${inputName}.state`, text)
          }
        />
      </EditFieldWrapper>
      <EditFieldWrapper>
        <LabelWrapper>
          <Label>Postcode</Label>
        </LabelWrapper>
        <Input
          name={`addresses.${inputName}.postCode`}
          errorMessage={errors?.addresses?.[`${inputName}`]?.postCode?.message}
          handleChangeText={(text) =>
            setValue(`addresses.${inputName}.postCode`, text)
          }
        />
      </EditFieldWrapper>
      <EditFieldWrapper>
        <LabelWrapper>
          <Label>Country</Label>
        </LabelWrapper>
        <Input
          name={`addresses.${inputName}.country`}
          errorMessage={errors?.addresses?.[`${inputName}`]?.country?.message}
          handleChangeText={(text) =>
            setValue(`addresses.${inputName}.country`, text)
          }
        />
      </EditFieldWrapper>
      <EditFieldWrapper>
        <MemberSearchAddressButton handleClick={handleSearchAddressClicked} />
      </EditFieldWrapper>
    </EditFieldList>
  );
};
