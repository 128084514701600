export const AlertTriangleIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="alert-triangle">
      <path
        id="Vector"
        d="M10.2908 3.86118L1.82075 18.0012C1.64612 18.3036 1.55372 18.6465 1.55274 18.9957C1.55176 19.3449 1.64224 19.6883 1.81518 19.9917C1.98812 20.2951 2.23748 20.5479 2.53846 20.725C2.83944 20.9021 3.18155 20.9973 3.53075 21.0012H20.4708C20.82 20.9973 21.1621 20.9021 21.463 20.725C21.764 20.5479 22.0134 20.2951 22.1863 19.9917C22.3593 19.6883 22.4497 19.3449 22.4488 18.9957C22.4478 18.6465 22.3554 18.3036 22.1808 18.0012L13.7108 3.86118C13.5325 3.56729 13.2815 3.3243 12.9819 3.15567C12.6824 2.98703 12.3445 2.89844 12.0008 2.89844C11.657 2.89844 11.3191 2.98703 11.0196 3.15567C10.72 3.3243 10.469 3.56729 10.2908 3.86118V3.86118Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M12 9V13"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M12 17H12.01"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
