import {
  AdditionalProductGroup,
  Address,
  AddressInfo,
  Announcement,
  Banner,
  CalculatedInstalments,
  Contact,
  ContactOthers,
  ContactPaymentInfo,
  ContactPersonalDetails,
  ContactPhone,
  Dashboard,
  Industry,
  JoiningFeeProduct,
  KeyValue,
  MemberPaymentPlan,
  Membership,
  NavTile,
  Offer,
  OfferProduct,
  OffersPackage,
  PaginatedData,
  PaginatedDataRecordsItem,
  PaymentGatewayTransactionRequest,
  PaymentPlanInfo,
  PaymentPlanInstalment,
  PaymentProfile,
  Purchase,
  PurchaseProduct,
  PurchasedProduct,
  RenewalMessage,
  SearchAddressResponseAddressRecord,
} from './generatedTypes';

type DeepRequired<T> = NonNullable<{
  [K in keyof T]-?: T[K] extends object | undefined ? DeepRequired<T[K]> : T[K];
}>;

// Generated.
export type PaymentGatewayTransactionRequestPayloadType = {
  access_key: string;
  profile_id: string;
  transaction_uuid: string;
  unsigned_field_names: string;
  signed_date_time: string;
  bill_to_email: string | null;
  bill_to_forename: string | null;
  bill_to_surname: string | null;
  consumer_id: string;
  transaction_type: 'sale';
  reference_number: string;
  amount: string;
  currency: string;
  locale: string;
  ignore_avs: 'true' | 'false';
  signed_field_names: string;
  signature: string;
};

export type TransactionListType = PaginatedDataType<TransactionListItemType>;

export type TransactionListItemType = {
  id: string;
  reference: string;
  date: string;
  amountIncGst: number;
  type: string;
};

// TODO: Update types when Transaction Detail is ready for integration
export type TransactionDetailType = DeepRequired<{
  date?: string;
  id?: string;
  items?: {
    cashTotal?: number;
    id?: string;
    name?: string;
    quantity?: number;
  }[];
  number?: number;
  price?: {
    gst?: number;
    shipping?: string;
    subTotal?: number;
    total?: number;
  };
  type?: string;
}>;

export type PaymentGatewayTransactionRequestType = Omit<
  DeepRequired<PaymentGatewayTransactionRequest>,
  'payload'
> & {
  payload: PaymentGatewayTransactionRequestPayloadType;
};

export type SearchAddressResponseAddressRecordType =
  DeepRequired<SearchAddressResponseAddressRecord>;

export type RenewalMessageType = DeepRequired<RenewalMessage>;

export type PurchaseProductType = DeepRequired<PurchaseProduct>;

export type PurchasedProductType = DeepRequired<PurchasedProduct>;

export type PaymentProfileType = DeepRequired<PaymentProfile>;

export type PaymentPlanInstalmentStatusType =
  | 'Unknown'
  | 'Pending'
  | 'Overdue'
  | 'Paid'
  | 'New'
  | 'Adjusted'
  | 'PayingNow';

export type PaymentPlanInstalmentType = Omit<
  DeepRequired<PaymentPlanInstalment>,
  'status'
> & {
  status: PaymentPlanInstalmentStatusType;
};

export type PaymentPlanInfoType = DeepRequired<PaymentPlanInfo>;

export type PaginatedDataRecordsItemType =
  DeepRequired<PaginatedDataRecordsItem>;

export type PaginatedDataType<T> = Omit<
  DeepRequired<PaginatedData>,
  'records'
> & {
  records: T[];
};

export type OfferProductStatusType = 'Available' | 'Disabled';

export type OfferProductType = Omit<DeepRequired<OfferProduct>, 'status'> & {
  status: OfferProductStatusType;
};

export type OfferStatusType = 'Purchased' | 'Available' | 'Disabled';

export type OfferPaymentType = 'Full' | 'Plan';

export type OfferType = Omit<
  DeepRequired<Offer>,
  'status' | 'paymentType' | 'additionalProductGroups'
> & {
  status: OfferStatusType;
  paymentType: OfferPaymentType;
  additionalProductGroups: AdditionalProductGroupType[];
};

export type NavTileType = DeepRequired<NavTile>;

export type MembershipStatusType =
  | 'Unknown' // = 0
  | 'New'
  | 'Offer' // no update/create operations are permitted on the portal (should not even come through)
  | 'Renewal' // ready for renewal
  | 'Active' // renewed for the season
  | 'Closing' // portal should not get a record with this status. When in closing, API will return the new season record status.
  | 'Suspended'
  | 'Cancelled' // should not be returned to portal
  | 'Resigned' // should not be returned to portal
  | 'Deceased' // should not be returned to portal
  | 'Expired' // should not be returned to portal
  // additional values for financial status if membership status is Active
  | 'Pending' // = 100 Membership.Status should be Renewal
  | 'Financial' // Membership.Status should be Active or Suspended
  | 'Overdue' // Membership.Status should be Active, Offer or Renewal
  | 'Lapsed'; // Membership.Status should be Active, Offer or Renewal

export type MembershipSeasonStatusType = 'Preseason' | 'Current';

export type MembershipFinancialStatusType =
  | 'Unknown' // = 0
  | 'New'
  | 'Offer' // no update/create operations are permitted on the portal (should not even come through)
  | 'Renewal' // ready for renewal
  | 'Active' // renewed for the season
  | 'Closing' // portal should not get a record with this status. When in closing, API will return the new season record status.
  | 'Suspended'
  | 'Cancelled' // should not be returned to portal
  | 'Resigned' // should not be returned to portal
  | 'Deceased' // should not be returned to portal
  | 'Expired' // should not be returned to portal
  // additional values for financial status if membership status is Active
  | 'Pending' // = 100 Membership.Status should be Renewal
  | 'Financial' // Membership.Status should be Active or Suspended
  | 'Overdue' // Membership.Status should be Active, Offer or Renewal
  | 'Lapsed'; // Membership.Status should be Active, Offer or Renewal

export type MembershipType = Omit<
  DeepRequired<Membership>,
  'financialStatus' | 'seasonStatus' | 'status'
> & {
  financialStatus: MembershipFinancialStatusType;
  seasonStatus: MembershipSeasonStatusType;
  status: MembershipStatusType;
};

export type MemberPaymentPlanStatusType =
  | 'Active'
  | 'Available'
  | 'Proposed'
  | 'Overdue';

export type MemberPaymentPlanType = Omit<
  DeepRequired<MemberPaymentPlan>,
  'instalments' | 'status'
> & {
  instalments?: PaymentPlanInstalmentType[];
  status: MemberPaymentPlanStatusType;
};

export type KeyValueType = DeepRequired<KeyValue>;

export type JoiningFeeProductStatusType = 'Available' | 'Disabled';

export type JoiningFeeProductType = Omit<
  DeepRequired<JoiningFeeProduct>,
  'status'
> & {
  status: JoiningFeeProductStatusType;
};

export type OffersPackageType = Omit<
  DeepRequired<OffersPackage>,
  'joiningFee' | 'offers' | 'purchasedProducts'
> & {
  joiningFee: JoiningFeeProductType | null;
  offers: OfferType[];
  purchasedProducts: PurchasedProductType[];
};

export type IndustryType = DeepRequired<Industry>;

export type DashboardType = DeepRequired<Dashboard>;

export type ContactPhoneType = DeepRequired<ContactPhone>;

export type ContactPersonalDetailsType = DeepRequired<ContactPersonalDetails>;

export type ContactPaymentInfoType = DeepRequired<ContactPaymentInfo>;

export type ContactOthersType = DeepRequired<ContactOthers>;

export type ContactType = DeepRequired<Contact>;

export type CalculatedInstalmentsType = Omit<
  DeepRequired<CalculatedInstalments>,
  'existingInstalments' | 'newInstalments' | 'newOrUpdatedPaymentPlan'
> & {
  existingInstalments: PaymentPlanInstalmentType[];
  newInstalments: PaymentPlanInstalmentType[];
  newOrUpdatedPaymentPlan: MemberPaymentPlanType;
};

export type BannerType = DeepRequired<Banner>;

export type AnnouncementType = DeepRequired<Announcement>;

export type AddressType = DeepRequired<Address>;

export type AddressInfoType = DeepRequired<AddressInfo>;

export type AdditionalProductGroupType = Omit<
  DeepRequired<AdditionalProductGroup>,
  'groupingType' | 'products'
> & {
  groupingType: 'AllMandatory' | 'OneMandatory' | 'AllOptional' | 'OneOptional';
  products: OfferProductType[];
};

// Request.
export type CalculateInstalmentsRequestBodyType = {
  currentPlan: DeepRequired<MemberPaymentPlanType>;
  itemsToAdd: OfferProductType[];
};

export const PurchasePurchaseType = {
  Offer: 'Offer',
  Token: 'Token',
} as const;

export type PurchaseType = Omit<
  DeepRequired<Purchase>,
  | 'newInstalments'
  | 'overdueInstalments'
  | 'paymentPlan'
  | 'paymentProfile'
  | 'purchaseType'
> & {
  newInstalments: PaymentPlanInstalmentType[];
  overdueInstalments: PaymentPlanInstalmentType[];
  paymentPlan: MemberPaymentPlanType | null;
  paymentProfile: PaymentProfileType | null;
  purchaseType: keyof typeof PurchasePurchaseType;
};

// Response.
export type PaymentGatewayResponseType =
  | PaymentGatewayTransactionRequestType
  | SaveSettingsResponseType;

export type SaveSettingsResponseType = {
  action: SaveSettingsFieldType['action'];
  decision: SaveSettingsFieldType['decision'];
  message: string;
};

export type SaveSettingsFieldType = {
  action: 'payment' | 'token';
  decision: 'ACCEPT' | 'CANCEL' | 'DECLINED';
  decision_url: 'response' | 'cancel' | 'declined';
};

// Others.
export type CashOnCardType = {
  cashOnCardBalance: number;
  finalPointsBalance: number;
  lastUpdated: string;
  memberId: string;
  racingRewardConversion: number;
  tenureRewardsBonus: number;
  totalCashOnCardBalance: number;
  userTenure: number;
};

export type OrderItemType = {
  cashTotal: number;
  id: string;
  name: string;
  quantity: number;
};

export type PriceType = {
  gst: number;
  shipping: string;
  subTotal: number;
  total: number;
};

export type OrderDetailType = {
  date: string;
  id: string;
  items: OrderItemType[];
  number: number;
  price: PriceType;
  type: string;
};
