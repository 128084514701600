import React, { useCallback, useMemo, useRef, useState } from 'react';
import tw from 'twin.macro';
import { Input } from 'components';
import { AddressKind, formatAddress } from 'helpers';
import { EditFieldWrapper, Label, LabelWrapper } from '../../../../styles';
import { MoonLoader } from 'react-spinners';
import { useDebounce, useOutsideClick } from 'hooks';
import { useFormContext } from 'react-hook-form';
import { ContactType, SearchAddressResponseAddressRecordType } from 'type';
import { useContactService } from 'services';
import { MemberAddressDropdown } from './MemberAddressDropdown';
import { MemberAddressManual } from './MemberAddressManual';

const RowContainer = tw.div`flex w-full items-center my-3`;
const AddressInputContainer = tw.div`relative w-full`;

interface IMemberAddressEditProps {
  inputName: AddressKind;
  onManualEdit: (isManualEdit: boolean) => void;
  showLabel?: boolean;
}

export const MemberAddressEdit: React.FC<IMemberAddressEditProps> = ({
  inputName,
  onManualEdit,
  showLabel = true,
}) => {
  const {
    getValues,
    setValue: setFormValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<ContactType>();

  const { debounce: debounceChangeText } = useDebounce(500);

  const defaultAddress = useMemo(
    () => formatAddress(getValues(`addresses.${inputName}`)),
    [getValues, inputName],
  );

  const myContactData = useContactService((state) => state.myContact.data);
  const addressesFound = useContactService((state) => state.addressesFound);
  const getAddresses = useContactService((state) => state.getAddresses);
  const getAddressDetail = useContactService((state) => state.getAddressDetail);
  const clearFoundAddresses = useContactService(
    (state) => state.clearAddressesFound,
  );

  const inputRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [manualEdit, setManualEdit] = useState<boolean>(false);
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [value, setValue] = useState<string>(defaultAddress);
  const [loadingAddressDetail, setLoadingAddressDetail] =
    useState<boolean>(false);

  const handleFocused = () => {
    setInputFocused(true);
    setValue('');
  };

  const handleBlurred = () => {
    if (!inputFocused) return;
    clearFoundAddresses();
    setInputFocused(false);
    setValue(defaultAddress);
  };

  const handleChangeText = useCallback(
    async (text: string) => {
      setValue(text);
      await debounceChangeText();
      getAddresses(text);
    },
    [setValue, debounceChangeText, getAddresses],
  );

  const handleAddressSelected = async (
    addressFinder: SearchAddressResponseAddressRecordType,
  ) => {
    setLoadingAddressDetail(true);
    const address = await getAddressDetail(addressFinder.recordId);
    clearFoundAddresses();
    setLoadingAddressDetail(false);
    if (address) {
      address.label = myContactData?.addresses?.[inputName]?.label as string;
      setFormValue(`addresses.${inputName}`, address);
      setValue(formatAddress(address));
      clearErrors(`addresses.${inputName}`);
    }
  };

  const handleManualEditClicked = () => {
    setManualEdit(true);
    onManualEdit(true);
  };

  const handleSearchAddress = () => {
    setManualEdit(false);
    onManualEdit(false);
    setValue(formatAddress(getValues(`addresses.${inputName}`)));
  };

  useOutsideClick({ refs: [inputRef, dropdownRef], callback: handleBlurred });

  const error = errors?.addresses?.[inputName] ?? {};
  const mainError = Object.entries(error)[0];
  const mainErrorMessage = mainError?.[1]?.message ?? '';

  return !manualEdit ? (
    <EditFieldWrapper>
      <LabelWrapper>
        {showLabel && (
          <Label>{myContactData?.addresses?.[inputName]?.label}</Label>
        )}
      </LabelWrapper>
      <AddressInputContainer>
        {loadingAddressDetail ? (
          <RowContainer>
            <MoonLoader size={18} color="#000" speedMultiplier={1} />
          </RowContainer>
        ) : (
          <>
            <div ref={inputRef}>
              <Input
                placeholder={myContactData?.addresses?.[inputName]?.label}
                value={value}
                handleChangeText={handleChangeText}
                onFocus={handleFocused}
                errorMessage={mainErrorMessage}
              />
            </div>
            <div ref={dropdownRef}>
              <MemberAddressDropdown
                show={inputFocused}
                addresses={addressesFound.data}
                addressesLoading={addressesFound.loading}
                handleAddressSelected={handleAddressSelected}
                handleManualEditClicked={handleManualEditClicked}
              />
            </div>
          </>
        )}
      </AddressInputContainer>
    </EditFieldWrapper>
  ) : (
    <MemberAddressManual
      inputName={inputName}
      handleSearchAddress={handleSearchAddress}
    />
  );
};
