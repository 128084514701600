import styled from 'styled-components';
import tw from 'twin.macro';

export type ButtonProps = {
  className?: String;
  onClick: () => void;
};

export const Button = styled.button`
  ${tw`
    flex items-center px-7 font-bold items-center h-11 justify-center
    `}
`;

export const BorderButton = styled(Button)`
  border: 2px solid #201547;
`;
