import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import 'styled-components/macro';
import { IMembershipProps } from './MembershipComponent';
import { Text } from 'styles';
import { CustomLink, StyledLinkType } from 'components';
import { useContactService } from 'services';
import { PaymentAlertWrapper } from './styles';
import { AlertCircleIcon } from '../shared/svgs/AlertCircleIcon';
import { AlertTriangleIcon } from '../shared/svgs/AlertTriangleIcon';
import { trackGTMEvent } from 'helpers/trackGTMEvent';
import LoadingIndicator from 'components/Loaders/LoadingIndicator';

const UpperLeftContainer = tw.div`
  block
  md:flex
  flex-col
  w-full
  md:max-w-md
  xl:max-w-2xl
  2xl:max-w-4xl
  ml-4

`;

const linkStyle: StyledLinkType = {
  borderWidth: '1px',
  borderColor: 'white',
  backgroundColor: '#FFFFFF',
  paddingVertical: '0.9375rem ',
  paddingHorizontal: '1.75rem',
};

const RightContainer = styled.div`
  ${tw`
    w-full md:w-auto mt-4 md:mt-0 md:flex md:justify-center
  `}
`;
const LeftContainer = styled.div`
  ${tw`
    flex justify-start flex-row text-left
  `}
`;
const SVGWrapper = styled.span`
  ${tw`
    h-5 w-5 mt-1 mb-3 md:mb-0 flex justify-center md:justify-start
  `}
`;

const RenewalDateContainer = tw.div`flex flex-col sm:flex-row`;
const RenewalDate = styled(Text.Small)<{ marginRight?: string }>(
  ({ marginRight = '0' }) => [
    tw`md:font-semibold text-left `,
    marginRight && `margin-right: ${marginRight}`,
  ],
);
const RenewDescription = styled(Text.Small)`
  ${tw`
    hidden sm:block
  `}
`;

const RenewalCustomLink = styled(CustomLink)`
  &:hover {
    border-color: #201547;
    background-color: #201547;
    transition-duration: 0.3s;
  }
  &:hover > p {
    color: white;
  }
  ${tw`
    justify-center p-3 sm:px-7
  `}
`;

const RenewalCustomLinkText = styled(Text.XSmall)`
  letter-spacing: 0.1em;
  ${tw`
    font-semibold
  `}
`;
const RenewalCustomTextLink = tw(CustomLink)`
  text-sm
  text-white
  hover:opacity-60
  transition
  duration-300
  underline
  justify-center
`;

const MembershipRenewalAlertWrapper = styled(PaymentAlertWrapper)<{
  showPayNow?: boolean;
}>(({ showPayNow = false }) => [
  tw`block px-5 md:px-8 py-6 md:flex justify-between`,
  showPayNow
    ? tw`bg-primaryPink text-primaryPurple`
    : tw`bg-primaryBlue text-white`,
]);

export const MembershipRenewalAlert: React.FC<IMembershipProps> = () => {
  const myMemberships = useContactService((state) => state.myMemberships);
  const membership = myMemberships.data?.[0];
  const isMembershipOffer = membership?.status === 'Offer';
  const renewalMessage = membership?.renewalMessage;

  if (myMemberships.loading) {
    return <LoadingIndicator visible />;
  }

  if (!renewalMessage) {
    return null;
  }

  const showRenewButton =
    renewalMessage.showPayNow ||
    membership.financialStatus === 'Pending' ||
    membership.financialStatus === 'Overdue';

  return (
    <MembershipRenewalAlertWrapper showPayNow={renewalMessage.showPayNow}>
      <LeftContainer>
        <SVGWrapper>
          {renewalMessage.showPayNow ? (
            <AlertTriangleIcon />
          ) : (
            <AlertCircleIcon />
          )}
        </SVGWrapper>
        <UpperLeftContainer>
          <RenewalDateContainer>
            <RenewalDate color="inherit">{renewalMessage.message}</RenewalDate>
          </RenewalDateContainer>
          <RenewDescription color="inherit">
            {renewalMessage.submessage}
          </RenewDescription>
        </UpperLeftContainer>
      </LeftContainer>
      {!isMembershipOffer && (
        <RightContainer>
          <div
            onClick={() => trackGTMEvent('membership_renewal_reminder_click')}>
            {showRenewButton ? (
              <RenewalCustomLink
                to={`/membership/${membership?.id}/renewal`}
                {...linkStyle}>
                <RenewalCustomLinkText>RENEW NOW</RenewalCustomLinkText>
              </RenewalCustomLink>
            ) : (
              <RenewalCustomTextLink
                to={`/membership/${membership?.id}/renewal`}>
                Renewal settings and upgrades
              </RenewalCustomTextLink>
            )}
          </div>
        </RightContainer>
      )}
    </MembershipRenewalAlertWrapper>
  );
};
