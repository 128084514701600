import { useCallback } from 'react';
import {
  useCashOnCardService,
  useContactService,
  useDashboardService,
  useMembershipService,
  useTransactionsService,
} from 'services';

const useResetStores = () => {
  const clearDashboard = useDashboardService((s) => s.clearData);
  const clearContact = useContactService((s) => s.clearData);
  const clearMembership = useMembershipService((s) => s.clearData);
  const clearCashOnCard = useCashOnCardService((s) => s.clearData);
  const clearTransactions = useTransactionsService((s) => s.clearTransactions);

  const resetStores = useCallback(() => {
    clearDashboard();
    clearContact();
    clearMembership();
    clearCashOnCard();
    clearTransactions();
  }, [
    clearDashboard,
    clearContact,
    clearMembership,
    clearCashOnCard,
    clearTransactions,
  ]);

  return { resetStores };
};

export default useResetStores;
