import { PageContainer } from 'components';
import PaymentRedirectContent from './PaymentRedirectContent';

const PaymentRedirect = () => {
  return (
    <PageContainer noSidebar>
      <PaymentRedirectContent />
    </PageContainer>
  );
};

export default PaymentRedirect;
