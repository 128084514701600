import { Fragment } from 'react';
import { OffersPackageType } from 'type';
import { PAYMENT_TYPE_MAP } from '../../utils';
import { offerHelpers, preferenceHelpers } from 'helpers';
import { Divider } from 'components';
import {
  RadioCheckbox,
  RadioCheckboxType,
  RenewalAccordion,
} from 'pages/membership';

interface IRenewalMembershipFinancialProps {
  offersPackage: OffersPackageType;
  handleOfferSelected: (selected: RadioCheckboxType) => void;
}

const RenewalMembershipFinancial = ({
  offersPackage,
  handleOfferSelected,
}: IRenewalMembershipFinancialProps) => {
  const { available, offersComparator, purchasedProductsSorted } = offerHelpers;
  const offers = offersPackage.offers.filter(available).sort(offersComparator);
  const joiningFee = offersPackage.joiningFee;
  const purchasedProducts = purchasedProductsSorted(
    offersPackage.purchasedProducts,
  );
  return (
    <RenewalAccordion title="Your Membership">
      {purchasedProducts.map((product) => {
        const selectedOffer = offers.find(
          (offer) => offer.subscriptionProductId === product.id,
        );
        if (selectedOffer) preferenceHelpers.offer.setDefault([selectedOffer]);
        const field = selectedOffer
          ? 'offer'
          : product.group === 'JoiningFee'
          ? 'JoiningFee'
          : 'AllMandatory';
        const upgradeInfo =
          product.group === 'JoiningFee' ? joiningFee?.message : '';
        const statusCardText = product.purchasedOnPaymentPlan
          ? PAYMENT_TYPE_MAP.Plan
          : PAYMENT_TYPE_MAP.Full;
        return (
          <Fragment key={product.id}>
            {product.group === 'JoiningFee' && (
              <Divider marginVertical="1.5rem" height="2px" bgColor="#E1E1DF" />
            )}
            <RadioCheckbox
              field={field}
              upgrade={product}
              upgradeInfo={upgradeInfo}
              defaultSelected={!!selectedOffer}
              onClick={() =>
                selectedOffer && handleOfferSelected(selectedOffer)
              }
              showTick
              showSelect={false}
              showCount={product.group !== 'JoiningFee'}
              statusCardText={statusCardText}
            />
          </Fragment>
        );
      })}
    </RenewalAccordion>
  );
};

export default RenewalMembershipFinancial;
