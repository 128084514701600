import React from 'react';
import tw from 'twin.macro';
import { Text } from 'styles';

const StyledSearchAddressButton = tw.div`
  flex
  flex-1
  justify-end
  items-end
  mb-2
`;

const StyledText = tw(Text.Normal)`
  underline
`;

interface IMemberSearchAddressButtonProps {
  handleClick: () => void;
}

export const MemberSearchAddressButton: React.FC<
  IMemberSearchAddressButtonProps
> = ({ handleClick }) => {
  return (
    <StyledSearchAddressButton>
      <button type="button" onClick={handleClick}>
        <StyledText>Search Address</StyledText>
      </button>
    </StyledSearchAddressButton>
  );
};
