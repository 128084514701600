import styled from 'styled-components';
import tw from 'twin.macro';
import { useIsAuthenticated } from '@azure/msal-react';
import { BorderButton } from './Button';
import { logout } from 'helpers';
import CustomLink from 'components/Links/CustomLink';

const LogoWrapper = styled.div`
  padding: 1.5rem 1.875rem;
  color: #4c4581;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  ${tw`
    hidden sm:flex justify-between h-[110px]
    `}
`;
const LogoutWrapper = styled.div`
  ${tw`
    my-2
  `}
`;
const LogoImgContainer = styled.div`
  cursor: pointer;
  width: 9.5rem;
  height: 3.8125rem;
`;

const LogoutButton = styled(BorderButton)`
  letter-spacing: 0.1em;
  ${tw`
    h-11 text-primaryPurple hover:text-white hover:bg-primaryPurple transition duration-300
  `}
`;

export const Logo = () => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <LogoWrapper>
      <LogoImgContainer>
        <CustomLink to={`/`}>
          <img src="/images/memberhub-myvrc-newlogo.svg" alt="vrc_logo" />
        </CustomLink>
      </LogoImgContainer>
      {isAuthenticated && (
        <LogoutWrapper>
          <LogoutButton onClick={logout}>LOGOUT</LogoutButton>
        </LogoutWrapper>
      )}
    </LogoWrapper>
  );
};
