import { BrowserRouter as Router } from 'react-router-dom';
import { Routers } from './routes/Routes';
import './styles/scss/index.scss';

import { MsalProvider } from '@azure/msal-react';
import { publicClientApp } from 'configs';

import TagManager from '@sooro-io/react-gtm-module';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID ?? '',
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW,
};
TagManager.initialize(tagManagerArgs);

const App = () => {
  return (
    <MsalProvider instance={publicClientApp}>
      <Router>
        <Routers />
      </Router>
    </MsalProvider>
  );
};

export default App;
