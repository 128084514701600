import tw from 'twin.macro';
import styled from 'styled-components';
import { Text } from 'styles';

export const ErrorMessageWrapper = styled(Text.Small)`
  ${tw`pt-0.5 text-errorRed`}
  &:first-letter {
    text-transform: uppercase;
  }
`;
