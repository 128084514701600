import { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { Text } from 'styles';
import { TransactionCard, TransactionLoadingCard } from '.';
import {
  ContentContainer,
  DashboardLinkButton,
} from 'components/shared/CommonWrappers';
import { ContentHeader, CustomButton } from 'components';
import { DEFAULTPAGECOUNT } from 'configs/constants';
import { useContactService, useTransactionsService } from 'services';

const TitlesContainer = tw.div`
  hidden
  sm:grid
  grid-cols-12
  gap-x-4
  py-3
`;

const DateContainer = tw.div`
  pl-8
  col-span-6
  sm:col-span-5
  xl:col-span-7
`;

const TotalContainer = tw.div`
  col-span-6
  sm:col-span-3
  xl:col-span-1
`;

const LoadMoreContainer = tw.div`flex justify-center items-center`;
const LoadMoreButton = tw(CustomButton)`
  w-auto px-[2.25rem] py-[1rem] bg-primaryPurple hover:bg-primaryBlue justify-center
`;

export const TransactionHistoryContent = () => {
  const myMembershipsData = useContactService((s) => s.myMemberships.data);
  const myMembership = myMembershipsData?.[0];

  const [isLoadMore, setIsLoadMore] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const {
    transactions: { total, data, loading },
    getTransactions,
  } = useTransactionsService();

  useEffect(() => {
    if (isNew || (isLoadMore && data && total > data?.length)) {
      getTransactions({ isNew });
    }
    setIsLoadMore(false);
    setIsNew(false);
    return () => {
      setIsLoadMore(false);
    };
  }, [getTransactions, isNew, isLoadMore, data, total]);

  const loadMore = () => {
    setIsLoadMore(true);
  };

  const loadCount = !data
    ? DEFAULTPAGECOUNT
    : total - data?.length > DEFAULTPAGECOUNT
    ? DEFAULTPAGECOUNT
    : total - data?.length;

  return (
    <>
      <ContentContainer>
        <ContentHeader text="Transaction History" />
        <TitlesContainer>
          <DateContainer>
            <Text.SmallBold color="#51504D">DATE</Text.SmallBold>
          </DateContainer>
          <TotalContainer>
            <Text.SmallBold color="#51504D">TOTAL</Text.SmallBold>
          </TotalContainer>
        </TitlesContainer>
        {data?.map((transaction) => (
          <TransactionCard
            key={transaction.id}
            transaction={transaction}
            myMembership={myMembership}
          />
        ))}

        {loading &&
          Array(loadCount)
            .fill(null)
            .map((_, index) => <TransactionLoadingCard key={index} />)}
      </ContentContainer>

      {!loading && data && total > data?.length && (
        <LoadMoreContainer>
          <LoadMoreButton onClick={loadMore}>
            <DashboardLinkButton color="white">
              LOAD MORE TRANSACTIONS
            </DashboardLinkButton>
          </LoadMoreButton>
        </LoadMoreContainer>
      )}
    </>
  );
};
