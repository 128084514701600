import { useEffect } from 'react';
import { ContentWrapper } from 'components/shared/CommonWrappers';
import { Banner } from 'components/Banner/Banner';
// import { EventList } from 'components/Event/EventList';
import { MemberList } from 'components/CTA/CTAList';
import { Announcement } from '../../components/Dashboard/Announcement';
import {
  AnnouncementPageType,
  useContactService,
  useDashboardService,
} from 'services';
import { MembershipRenewalAlert } from 'components/membership/MembershipRenewalAlert';

export const DashboardContent = () => {
  const { getData } = useDashboardService();
  const myMembershipsData = useContactService((s) => s.myMemberships.data);
  const membership = myMembershipsData?.[0];

  useEffect(() => {
    if (membership) getData(membership);
  }, [membership, getData]);

  return (
    <ContentWrapper>
      <Banner />
      <MembershipRenewalAlert />
      <Announcement type={AnnouncementPageType.DASHBOARD} />
      <MemberList />
      {/* <EventList /> */}
    </ContentWrapper>
  );
};
