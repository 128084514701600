import TagManager from '@sooro-io/react-gtm-module';

export function trackGTMEvent(eventName: string, data?: Record<string, any>) {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ...data,
    },
  });
}
