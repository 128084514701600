import tw from 'twin.macro';
import styled from 'styled-components';
import 'styled-components/macro';
import { Member } from './CTA';
import { CTALoader } from './CTALoader';
import { useDashboardService } from 'services';

const MemberWrapper = styled.div`
  ${tw`
       mx-4 md:mx-8 block md:grid gap-x-4 grid-cols-2 mb-8 md:mb-16
    `}
`;

export const MemberList = () => {
  const { data } = useDashboardService();
  return (
    <MemberWrapper>
      {data && data.tiles ? (
        data.tiles.map((member, index) => (
          <Member key={`member_${index}`} member={member} />
        ))
      ) : (
        <>
          <CTALoader />
          <CTALoader />
        </>
      )}
    </MemberWrapper>
  );
};
