export const AlertCircleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14.75 14.75"
    aria-hidden="true"
    tw={'h-full w-full'}
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="currentColor"
          d="M7,0A6.75,6.75,0,0,0,4.28.55,7,7,0,0,0,.55,4.28,6.75,6.75,0,0,0,0,7,6.75,6.75,0,0,0,.55,9.72a7,7,0,0,0,3.73,3.73A6.75,6.75,0,0,0,7,14a6.75,6.75,0,0,0,2.72-.55,7,7,0,0,0,3.73-3.73A6.75,6.75,0,0,0,14,7a6.75,6.75,0,0,0-.55-2.72A7,7,0,0,0,9.72.55,6.75,6.75,0,0,0,7,0ZM7,12.44A5.35,5.35,0,0,1,4.88,12,5.51,5.51,0,0,1,2,9.12,5.35,5.35,0,0,1,1.56,7,5.35,5.35,0,0,1,2,4.88,5.51,5.51,0,0,1,4.88,2,5.35,5.35,0,0,1,7,1.56,5.35,5.35,0,0,1,9.12,2,5.51,5.51,0,0,1,12,4.88,5.35,5.35,0,0,1,12.44,7,5.35,5.35,0,0,1,12,9.12,5.51,5.51,0,0,1,9.12,12,5.35,5.35,0,0,1,7,12.44ZM7,3.63a.79.79,0,0,0-.78.78V7A.78.78,0,0,0,7.78,7V4.41A.79.79,0,0,0,7,3.63ZM7,8.81A.75.75,0,0,0,6.45,9a.76.76,0,0,0-.23.55.78.78,0,0,0,1.56,0A.76.76,0,0,0,7.55,9,.75.75,0,0,0,7,8.81Z"
        />
      </g>
    </g>
  </svg>
);
