import tw from 'twin.macro';
import styled from 'styled-components';
import { Text } from 'styles';

export const Container = tw.section`
  mb-10
  sm:mb-20
`;

export const FlexContainer = tw.div`
  flex
  flex-col md:flex-row
  md:justify-between
  md:items-center
`;

export const FlexRowContainer = tw(FlexContainer)`
  flex-row
  justify-between
  mt-1 -mb-5
`;

export const ProductGroupTitle = tw(Text.LargeBold)`mb-6`;

export const LabelContainer = tw.div`
  flex flex-col
`;

export const Label = styled.p`
  ${tw`mr-2`}
`;

export const LabelInfo = tw(Text.Small)`mt-1 text-primaryBlue`;

export const NoUpgradesText = tw(Text.Small)`mb-2`;

export const Price = tw.div`
  flex
  justify-between
  items-center
`;
export const PriceText = styled.p``;

export const RadioContainer = tw.div`
  mr-4 sm:mr-6 mt-0.5
`;

export const RadioButton = tw.button`
  flex
  justify-between items-center
  w-full
  mb-6
  sm:mb-8
  last:mb-2
  last:sm:mb-4
`;
