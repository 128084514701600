import { MemberPaymentPlanType } from 'type';

const getActivePlan = (data: MemberPaymentPlanType[]) => {
  return data.find((p) => p.status === 'Active');
};

const getOnePlan = (data: MemberPaymentPlanType[]) => {
  return data.find((p) => p.numberOfInstalments === 1);
};

const getFourPlan = (data: MemberPaymentPlanType[]) => {
  return data.find((p) => p.numberOfInstalments === 4);
};

const getNoOnePlans = (data: MemberPaymentPlanType[]) => {
  return data.filter((p) => p.numberOfInstalments !== 1);
};

export const paymentPlanHelpers = {
  getActivePlan,
  getOnePlan,
  getFourPlan,
  getNoOnePlans,
};
