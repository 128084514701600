import tw from 'twin.macro';
import styled from 'styled-components';
import { Text } from 'styles';
export const AppContainer = styled.div`
  ${tw`
    flex
    flex-col
    w-full
    text-xs
    relative
  `}
`;

export const CenterWrapper = styled.div`
  ${tw`
    w-full flex items-center justify-center
  `}
`;

export const ContainerWrapper = styled.div`
  ${tw`
      flex-none lg:flex w-full
  `}
`;

export const ContentWrapper = styled.div`
  padding-bottom: 4rem;
  ${tw`
    min-h-[80vh] w-full bg-secondaryCream
  `}
`;

export const ContentContainer = tw.section`
  flex
  flex-col
  px-4
  py-6
  sm:px-8
  sm:py-10
`;

export const DashboardLinkButton = styled(Text.XSmall)`
  ${tw`
    font-semibold tracking-widest cursor-pointer
  `}
`;
