import { setLocale } from 'yup';

const camelCaseToSpacedText = (word: string) => {
  const result = word.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const formatPath = (path: string) => {
  const words = path.split('.');
  const last = words[words.length - 1] ?? '';
  return camelCaseToSpacedText(last);
};

setLocale({
  mixed: {
    required: (params) => `${formatPath(params.path)} is required.`,
  },
  string: {
    matches: (params) => `${formatPath(params.path)} is not valid.`,
  },
});
