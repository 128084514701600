import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useContactService, useMembershipService } from 'services';
import { instalmentHelpers, paymentHelpers } from 'helpers';
import { Divider } from 'components';
import {
  FormDataType,
  GapPaymentNotice,
  PayBalanceRemainingCard,
  PaymentAmount,
} from 'pages/membership';

interface IPaymentFullProps {
  hasInstalmentPlan: boolean;
}

const PaymentFull: React.FC<IPaymentFullProps> = ({ hasInstalmentPlan }) => {
  const { watch } = useFormContext<FormDataType>();
  const myMembershipsData = useContactService((s) => s.myMemberships.data);
  const myMembership = myMembershipsData?.[0];
  const calculatedInstalments = useMembershipService(
    (s) => s.calculatedInstalments,
  );
  const newInstalments = useMembershipService(
    (s) => s.calculatedInstalments.data?.newInstalments,
  );

  const isOfferPurchased = watch('offer.0.status') === 'Purchased';
  const selectedInstalments = watch('selectedInstalments');
  const payBalanceRemaining = watch('payBalanceRemaining');
  const paymentOption = watch('paymentOption');
  const paymentPlan = watch('paymentPlan');
  const totalAmount = watch('totalAmount');
  const isPreseason = myMembership?.seasonStatus === 'Preseason';
  const dueTransitionDate = `1 Aug ${new Date().getFullYear()}`;

  const instalments = paymentPlan?.instalments ?? [];
  const missedInstalments = instalmentHelpers.useInstalments(instalments, [
    'Overdue',
  ]);
  const { gapPaymentNotice, overdueInstalmentsAmount } =
    paymentHelpers.useGapPayment(newInstalments, missedInstalments);

  if (!myMembership) return null;

  if (hasInstalmentPlan) {
    const { payableInstalmentsAmount } =
      instalmentHelpers.getInstalmentPurchaseData(
        paymentPlan,
        calculatedInstalments.data,
        selectedInstalments,
        payBalanceRemaining,
        paymentOption,
      );
    const amount = payBalanceRemaining ? payableInstalmentsAmount : totalAmount;
    return (
      <>
        {!!totalAmount && isOfferPurchased && <PayBalanceComponent />}
        {!!overdueInstalmentsAmount && (
          <GapPaymentComponent
            gapPaymentNotice={gapPaymentNotice}
            overdueInstalmentsAmount={overdueInstalmentsAmount}
          />
        )}
        <Divider marginVertical="1rem" bgColor="transparent" />
        <PaymentAmount
          label={!!totalAmount ? 'Amount' : 'Balance Remaining'}
          text={!!totalAmount ? '(due now)' : ''}
          amount={amount + overdueInstalmentsAmount}
          loading={calculatedInstalments.loading}
        />
      </>
    );
  }

  if (totalAmount) {
    return (
      <>
        {!!overdueInstalmentsAmount && (
          <GapPaymentComponent
            gapPaymentNotice={gapPaymentNotice}
            overdueInstalmentsAmount={overdueInstalmentsAmount}
          />
        )}
        <Divider marginVertical="1rem" bgColor="transparent" />
        <PaymentAmount
          label="Amount"
          text={
            isPreseason && !overdueInstalmentsAmount
              ? `(due ${dueTransitionDate})`
              : ''
          }
          amount={totalAmount + overdueInstalmentsAmount}
        />
      </>
    );
  }

  return null;
};

interface IPayBalanceComponentProps {}

const PayBalanceComponent = React.memo((_: IPayBalanceComponentProps) => {
  return (
    <>
      <Divider marginVertical="1.75rem" bgColor="transparent" />
      <PayBalanceRemainingCard />
      <Divider marginVertical="1.25rem" bgColor="transparent" />
    </>
  );
});

interface IGapPaymentComponentProps {
  gapPaymentNotice: ReturnType<
    typeof paymentHelpers.useGapPayment
  >['gapPaymentNotice'];
  overdueInstalmentsAmount: ReturnType<
    typeof paymentHelpers.useGapPayment
  >['overdueInstalmentsAmount'];
}

const GapPaymentComponent = React.memo(
  ({
    gapPaymentNotice,
    overdueInstalmentsAmount,
  }: IGapPaymentComponentProps) => {
    return (
      <>
        <GapPaymentNotice
          type={gapPaymentNotice}
          amount={overdueInstalmentsAmount}
        />
        <Divider marginVertical="3rem" />
        <Divider marginVertical="-2rem" bgColor="transparent" />
      </>
    );
  },
);

export default PaymentFull;
