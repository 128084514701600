import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Text } from 'styles';
import { useDeviceSize } from 'hooks';
import { Divider } from 'components';
import { AsteriskContainer } from './PaymentOptions';

const MainContainer = tw.div`mb-10 -mt-5`;
const Container = tw.div`flex justify-between items-center my-1 md:my-3`;

const BoldText = styled.p`
  ${tw`mr-2 text-primaryBlue`}
`;

const NormalText = styled.p`
  ${tw`text-primaryBlue`}
`;

const Description = tw(Text.Small)`text-primaryBlue`;

const TITLE = {
  catchUp: 'Catch-up Payment',
  overdue: 'Overdue Amount',
} as const;

const DESCRIPTION = {
  catchUp:
    'As you have added an optional product to your payment plan, a catch-up payment is required. Your remaining monthly instalment amounts have also been adjusted and reflected above.',
  overdue:
    'There is currently an overdue amount from last season or an instalment(s) after the season has started. The overdue amount is included below as a required payment.',
} as const;

interface IGapPaymentNoticeProps {
  type: keyof typeof DESCRIPTION;
  amount: number;
}

const GapPaymentNotice: React.FC<IGapPaymentNoticeProps> = ({
  type,
  amount,
}) => {
  const { isMobile } = useDeviceSize();
  const responsiveBoldText = isMobile ? Text.LargeBold : Text.XLargeBold;
  const responsiveText = isMobile ? Text.Large : Text.XLarge;
  return (
    <>
      <Divider marginVertical="3rem" />
      <MainContainer>
        <Container>
          <Container>
            <BoldText as={responsiveBoldText}>{TITLE[type]}</BoldText>
            <NormalText as={responsiveText}>(due now)</NormalText>
          </Container>
          <NormalText as={responsiveText}>{`$${amount.toFixed(2)}`}</NormalText>
        </Container>
        <Description style={{ position: 'relative' }}>
          <AsteriskContainer>*</AsteriskContainer>
          {DESCRIPTION[type]}
        </Description>
      </MainContainer>
      <Divider marginVertical="-1.75rem" bgColor="transparent" />
    </>
  );
};

export default GapPaymentNotice;
