export const BASE_ABSOLUTE_URL: string = `${process.env.REACT_APP_FE_BASE_URL}`;

export interface IMenu {
  id: string;
  value: number;
  label: string;
  to: string;
}
export type Option = Omit<IMenu, 'to'>;

export const getMobileMenus = (membershipId: string) => {
  const mobileMenus = MENUS.map((menu) => {
    return {
      ...menu,
      to: menu.to.replace(':membershipId', membershipId),
    };
  });

  return mobileMenus;
};

export const MENUS: Array<IMenu> = [
  { id: 'dashboard', value: 1, label: 'MY VRC', to: '/' },
  {
    id: 'renewal',
    value: 2,
    label: 'MY MEMBERSHIP',
    to: '/membership/:membershipId/renewal',
  },
  { id: 'membership', value: 3, label: 'MY DETAILS', to: '/membership' },
  {
    id: 'transactionhistory',
    value: 4,
    label: 'TRANSACTION HISTORY',
    to: '/transactions',
  },
];

export const MEMBERSHIP_BADGES = [5, 10, 15, 25];

export enum EVENT_LAYOUT {
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
}

export enum ANNOUNCEMENT_THEME_TYPE {
  GREEN = 'Green',
  GREY = 'Grey',
  PINK = 'Red',
  RED = 'Rogue Red',
}

export const NO_PREFEREED_PHONE = 'No Set';
export const NO_PREFEREED_PHONE_LABEL = '(preferred)';
export const PREFERRED_PHONES_LIST = [
  'Personal Mobile',
  'Business Phone',
  'Business Mobile',
];
export enum InvoiceType {
  INVOICE = 'Invoice',
  RETURN = 'Return',
}

export const DEFAULTPAGECOUNT: number = 8;
export const DEFAULTPAGESTART: number = 1;

export const MOBILE_NUMBER_REGEX =
  /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;

export const BUSINESS_PHONE_REGEX =
  /^((((?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)?)) ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3}))|((1800|1300) ?\d{3} ?\d{3})|(13 ?\d{2} ?\d{2})$/;

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
