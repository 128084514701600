import React from 'react';
import tw from 'twin.macro';
import { Text } from 'styles';
import { useFormContext } from 'react-hook-form';
import { useContactService } from 'services';
import { Toggle } from 'components';
import { useDeviceSize } from 'hooks';
import { FormDataType } from './type';
import {
  RenewalBoxContainer,
  RenewalBoxTextContainer,
  RenewalBoxTitle,
  RenewalBoxSpan,
  RenewalBoxBoldSpan,
  RenewalBoxHyperLink,
  RenewalBoxHyperLinkUnderline,
} from './styles';

const StyledAutoRenewCard = tw.div`
  bg-neutralMidGrey
  flex
  justify-between
  items-center
  p-4
  sm:px-8 sm:py-7
  mt-3 mb-4 -mx-3
`;

interface IAutoRenewCardProps {}

const AutoRenewCard: React.FC<IAutoRenewCardProps> = () => {
  const myMembershipsData = useContactService((s) => s.myMemberships.data);
  const myMembership = myMembershipsData?.[0];

  const { setValue, watch } = useFormContext<FormDataType>();
  const { isMobile } = useDeviceSize();

  const autoRenew = watch('autoRenew');
  const payInInstalment = watch('paymentOption') === 'Pay in instalments';
  const isAutoRenew = myMembership?.autoRenew || payInInstalment;

  return (
    <RenewalBoxContainer>
      <RenewalBoxTextContainer>
        {isAutoRenew ? (
          <RenewalBoxTitle>Please note: </RenewalBoxTitle>
        ) : (
          <RenewalBoxTitle>Automatic payment</RenewalBoxTitle>
        )}
        {!isAutoRenew && (
          <StyledAutoRenewCard>
            <Text.Normal>
              Yes, I'd like to save these settings as my default renewal
              preference
            </Text.Normal>
            <Toggle
              toggle={autoRenew}
              onToggle={() => setValue('autoRenew', !autoRenew)}
              scale={isMobile ? 0.9 : 1.2}
            />
          </StyledAutoRenewCard>
        )}
        <p>
          {isAutoRenew ? (
            <RenewalBoxSpan>
              Your payment plan will automatically renew with these preferences.{' '}
            </RenewalBoxSpan>
          ) : (
            <>
              <RenewalBoxBoldSpan>Please note: </RenewalBoxBoldSpan>
              <RenewalBoxSpan>
                By selecting this option, all future payments will be processed
                using this preference.{' '}
              </RenewalBoxSpan>
            </>
          )}
          <RenewalBoxSpan>To make changes, please contact </RenewalBoxSpan>
          <RenewalBoxHyperLink href="tel:1300727575">
            1300 727 575
          </RenewalBoxHyperLink>
          <RenewalBoxSpan> or email </RenewalBoxSpan>
          <RenewalBoxHyperLinkUnderline href="mailto:membership@vrc.net.au">
            membership@vrc.net.au
          </RenewalBoxHyperLinkUnderline>
        </p>
      </RenewalBoxTextContainer>
    </RenewalBoxContainer>
  );
};

export default AutoRenewCard;
