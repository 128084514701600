import { Fragment, useEffect } from 'react';
import { Divider } from 'components';
import { OfferType } from 'type';
import { offerHelpers, preferenceHelpers } from 'helpers';
import {
  RadioCheckbox,
  UpgradeCheckbox,
  RenewalAccordion,
} from 'pages/membership';

interface IRenewalOptionalProductsProps {
  selected: OfferType;
  recalculatePrices: () => void;
}

const RenewalOptionalProducts = ({
  selected,
  recalculatePrices,
}: IRenewalOptionalProductsProps) => {
  const { available, productGroupsComparator } = offerHelpers;
  const groups = selected.additionalProductGroups;
  const groupsValid = groups.filter((g) => g.groupingType !== 'AllMandatory');
  const products = groupsValid.flatMap((g) => g.products.filter(available));

  useEffect(() => {
    if (products.length) {
      preferenceHelpers.optionalProducts.setDefault(products);
    }
  }, [products, groupsValid]);

  if (!products.length) return null;

  return (
    <>
      <Divider height="2px" marginVertical="2.2rem" bgColor="#201547" />
      <RenewalAccordion title="Optional Products">
        {groupsValid
          .sort(productGroupsComparator)
          .flatMap(({ name, groupingType, products }) => {
            const availableProducts = products.filter(available);
            return (
              <Fragment key={name}>
                {availableProducts.map((product, index) => {
                  const disabled = product.status === 'Disabled';
                  const isLast = index === availableProducts.length - 1;
                  return (
                    <Fragment key={product.id}>
                      {(() => {
                        switch (groupingType) {
                          case 'OneMandatory':
                            return disabled ? (
                              <UpgradeCheckbox
                                field={groupingType}
                                upgrade={product}
                                onClick={recalculatePrices}
                                interactable={false}
                              />
                            ) : (
                              <RadioCheckbox
                                field={groupingType}
                                upgrade={product}
                                onClick={recalculatePrices}
                                defaultSelected={product === products[0]}
                                showSelect
                              />
                            );
                          case 'AllOptional':
                            return (
                              <UpgradeCheckbox
                                field={groupingType}
                                upgrade={product}
                                onClick={recalculatePrices}
                                interactable={!disabled}
                              />
                            );
                          case 'OneOptional':
                            return (
                              <UpgradeCheckbox
                                field={groupingType}
                                upgrade={product}
                                onClick={recalculatePrices}
                                interactable={!disabled}
                                isOneOptional
                              />
                            );
                        }
                      })()}
                      {!isLast ? (
                        <Divider
                          height="2px"
                          marginVertical="2rem"
                          bgColor="#E1E1DF"
                        />
                      ) : (
                        <Divider
                          marginVertical="-0.4rem"
                          bgColor="transparent"
                        />
                      )}
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}
      </RenewalAccordion>
    </>
  );
};

export default RenewalOptionalProducts;
