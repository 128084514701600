import tw from 'twin.macro';
import { GreetingAnimation } from './GreetingAnimation';

const GreetingWrapper = tw.div`
  min-h-screen bg-secondaryCream
  flex items-center py-10 px-0 xs:px-3 sm:px-10 md:px-20 lg:px-32 xl:px-40
`;
const GreetingMiddleWrapper = tw.div`
  flex flex-col items-center justify-center
  w-full md:max-w-[57.5rem]
  px-6
`;

export const Greeting = () => {
  return (
    <GreetingWrapper>
      <GreetingMiddleWrapper>
        <GreetingAnimation visible />
      </GreetingMiddleWrapper>
    </GreetingWrapper>
  );
};
