import { Text } from 'styles';
import { useDeviceSize } from 'hooks';
import { FormDataType } from './type';

const satisfy = <T extends (keyof FormDataType)[]>(o: T) => o;

export const PAYMENT_TYPE_MAP = {
  Full: 'PAID IN FULL',
  Plan: 'INSTALMENT PLAN',
};

export const RADIO_UPGRADES = satisfy([
  'offer',
  'JoiningFee',
  'AllMandatory',
  'OneMandatory',
]);

export const CHECKBOX_UPGRADES = satisfy([
  'OneMandatory',
  'AllOptional',
  'OneOptional',
]);

export const PRICES: {
  label: string;
  field: keyof FormDataType;
  isShown: boolean;
}[] = [
  { label: 'Sub Total', field: 'subtotal', isShown: false },
  { label: 'GST', field: 'gst', isShown: false },
  { label: 'Total (inc. GST)', field: 'totalAmount', isShown: true },
];

export const useResponsivePrice = () => {
  const { isMobile } = useDeviceSize();
  const responsivePriceLabel = isMobile ? Text.LargeBold : Text.XLargeBold;
  const responsivePriceValue = isMobile ? Text.Large : Text.XLarge;

  return { responsivePriceLabel, responsivePriceValue };
};
