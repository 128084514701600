import React from 'react';
import styled from 'styled-components';
import { CustomButtonType, StyledLinkType } from './type';
import linkBaseStyle from './style';

const StyledLink = styled.div<StyledLinkType>(linkBaseStyle);

const CustomButton: React.FC<CustomButtonType> = ({ children, ...props }) => {
  return (
    <StyledLink {...props} onClick={() => props.onClick()}>
      {children}
    </StyledLink>
  );
};

export default CustomButton;
