import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import 'styled-components/macro';
import { Text } from 'styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { BackLink, Divider } from 'components';
import { useCheckSalesOrder, useDeviceSize } from 'hooks';
import { instalmentHelpers, purchaseHelpers } from 'helpers';
import { useContactService, useMembershipService } from 'services';
import { PaymentSalesOrderPending } from 'pages/payment';
import {
  PurchaseType,
  PurchasePurchaseType,
  PaymentGatewayTransactionRequestType,
} from 'type';
import {
  FormDataType,
  CybersourceForm,
  MemberDetails,
  RenewalMembership,
  PaymentOptions,
  AutoRenewCard,
  ConfirmTnC,
  PaymentSettings,
  MembershipAlert,
} from '..';

const Container = tw.div`
  relative
  flex
  flex-col
  pt-6
  sm:pt-12
`;

const Header = styled.p`
  ${tw`my-12 sm:my-16`}
`;

const InnerContainer = tw.div`
  px-5
  w-full
  max-w-3xl
  mx-auto
`;

const PaymentContainer = tw.div`
  bg-[#f0eeed]
  pt-14 pb-32
  sm:pt-24 sm:pb-64
`;

interface IMemberRenewalContentProps {}

const MemberRenewalContent: React.FC<IMemberRenewalContentProps> = () => {
  const navigate = useNavigate();
  const { membershipId } = useParams();

  const myContactData = useContactService((s) => s.myContact.data);
  const offersData = useMembershipService((s) => s.offers.data?.offers);
  const paymentPlansData = useMembershipService((s) => s.paymentPlans.data);
  const getPaymentPlans = useMembershipService((s) => s.getPaymentPlans);
  const getPreferredPlan = useMembershipService((s) => s.getPreferredPlan);

  const { salesOrderPending } = useCheckSalesOrder({
    membershipId,
    skip: !!offersData,
  });

  const calculatedInstalmentsData = useMembershipService(
    (s) => s.calculatedInstalments.data,
  );
  const postPurchase = useMembershipService((s) => s.postPurchase);

  const [paymentData, setPaymentData] =
    useState<PaymentGatewayTransactionRequestType | null>(null);

  const formMethods = useForm<FormDataType>({
    defaultValues: {
      offer: [],
      JoiningFee: [],
      AllMandatory: [],
      OneMandatory: [],
      AllOptional: [],
      OneOptional: [],
      subtotal: 0,
      gst: 0,
      totalAmount: 0,
      paymentOption: 'Pay in full',
      paymentProfile: null,
      paymentPlan: null,
      overduePlan: null,
      autoRenew: true,
      payBalanceRemaining: true,
      selectedInstalments: [],
      tnc: false,
    },
  });

  const { isMobile } = useDeviceSize();
  const resposiveHeader = isMobile ? Text.H4 : Text.H1;

  const onSubmit = async (data: FormDataType) => {
    const {
      offer,
      JoiningFee,
      AllMandatory,
      OneMandatory,
      AllOptional,
      OneOptional,
      totalAmount,
      paymentOption,
      autoRenew,
      payBalanceRemaining,
      paymentPlan,
      overduePlan,
      paymentProfile,
      selectedInstalments,
    } = data;

    const fields = [
      offer,
      JoiningFee,
      AllMandatory,
      OneMandatory,
      AllOptional,
      OneOptional,
    ];

    const selectedProducts = fields
      .flatMap((f) => f.map((u) => purchaseHelpers.formatUpgrade(u)))
      .filter(Boolean) as PurchaseType['products'];

    const products = (() => {
      if (paymentOption === 'Pay in full') return selectedProducts;
      if (paymentOption === 'Pay in instalments') {
        return selectedInstalments.length ? selectedProducts : [];
      }
      return [];
    })();

    const { payablePaymentPlan, payableInstalments, payableInstalmentsAmount } =
      instalmentHelpers.getInstalmentPurchaseData(
        paymentPlan,
        calculatedInstalmentsData,
        selectedInstalments,
        payBalanceRemaining,
        formMethods.getValues('paymentOption'),
      );

    const { overdueInstalments, overdueInstalmentsAmount } =
      instalmentHelpers.getOverdueInstalmentsData(overduePlan?.instalments);

    const offerId =
      offersData?.find((o) => o.subscriptionProductId === offer[0]?.id)?.id ??
      '';

    const purchasePaymentPlan = payablePaymentPlan?.id
      ? payablePaymentPlan
      : null;

    const purchasePaymentProfile = paymentProfile?.id ? paymentProfile : null;

    const amount = (() => {
      let total = overdueInstalmentsAmount;
      if (paymentOption === 'Pay in full') {
        total += payableInstalmentsAmount || totalAmount;
      }
      if (paymentOption === 'Pay in instalments') {
        total += payableInstalmentsAmount;
      }
      return +total.toFixed(2);
    })();

    const purchase: PurchaseType = {
      autoRenew,
      contactId: myContactData?.id ?? '',
      date: '',
      email: myContactData?.personalDetails.email ?? '',
      firstName: myContactData?.personalDetails.firstName ?? '',
      lastName: myContactData?.personalDetails.lastName ?? '',
      membershipId: membershipId ?? '',
      newInstalments: payableInstalments,
      offerId,
      overdueInstalments,
      paymentPlan: purchasePaymentPlan,
      paymentProfile: purchasePaymentProfile,
      payNow: true,
      products,
      purchaseType: PurchasePurchaseType.Offer,
      reference: '',
      totalAmount: amount,
    };

    console.log(JSON.stringify(purchase, null, 2));

    const res = await postPurchase(purchase);
    if (!res) {
      return;
    } else if ('targetUrl' in res) {
      setPaymentData(res);
    } else {
      const { action, decision, message } = res;
      const finalPath = `/payment/response?action=${action}&decision=${decision}&message=${message}`;
      navigate(finalPath);
    }
  };

  useEffect(() => {
    if (offersData) getPaymentPlans(membershipId);
  }, [offersData, membershipId, getPaymentPlans]);

  useEffect(() => {
    if (paymentPlansData) getPreferredPlan(membershipId);
  }, [paymentPlansData, membershipId, getPreferredPlan]);

  return (
    <Container>
      <PaymentSalesOrderPending visible={salesOrderPending} />
      {paymentData && <CybersourceForm data={paymentData} />}

      <InnerContainer>
        <BackLink to="/" text="Back" />
        <MembershipAlert />
        <Header as={resposiveHeader}>Membership Summary</Header>
      </InnerContainer>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <InnerContainer>
            <MemberDetails />
            <RenewalMembership />
          </InnerContainer>

          <PaymentContainer>
            <InnerContainer>
              <PaymentOptions />
              <Divider marginVertical="2.5rem" />
              <AutoRenewCard />
              <Divider marginVertical="2.5rem" />
              <ConfirmTnC />
              <PaymentSettings handleSetPaymentData={setPaymentData} />
            </InnerContainer>
          </PaymentContainer>
        </form>
      </FormProvider>
    </Container>
  );
};

export default MemberRenewalContent;
