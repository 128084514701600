import tw from 'twin.macro';
import 'styled-components/macro';
import { PaymentResponseIconVariantType } from 'pages/membership';
import { PAYMENT_RESPONSE_ICON_VARIANT } from '../constants';

const OuterRing = tw.div`
  bg-primaryBlue
  w-20
  h-20
  rounded-full
  flex
  justify-center
  items-center
`;

const InnerRing = tw.div`
  bg-secondaryCream
  w-[88%]
  h-[88%]
  rounded-full
  flex
  justify-center
  items-center
`;

interface IResponseLogoProps {
  iconVariant: PaymentResponseIconVariantType;
}

const ResponseLogo: React.FC<IResponseLogoProps> = ({ iconVariant }) => {
  return (
    <OuterRing>
      <InnerRing>
        {iconVariant === 'success' ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            tw="text-primaryBlue w-[84%] h-[84%] -rotate-12 ml-0.5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d={PAYMENT_RESPONSE_ICON_VARIANT[iconVariant]}
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            tw="text-primaryBlue w-[84%] h-[84%] ml-0.5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d={PAYMENT_RESPONSE_ICON_VARIANT[iconVariant]}
            />
          </svg>
        )}
      </InnerRing>
    </OuterRing>
  );
};

export default ResponseLogo;
