import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { IMembershipProps } from './MembershipComponent';
import { Text } from 'styles';
import { useContactService } from 'services';

const MemberYearsWrapper = styled.div`
  ${tw`
    absolute top-8 right-5 flex items-center
  `}
`;

const MemberYearsDescription = styled(Text.Medium)`
  ${tw`
    hidden md:flex text-white text-center
  `}
`;

export const MembershipBadge: React.FC<IMembershipProps> = () => {
  const {
    myMemberships: { data },
  } = useContactService();
  const membership = data?.[0];

  if (!membership) return null;
  if (membership.years <= 0) return null;

  return (
    <MemberYearsWrapper>
      <MemberYearsDescription>
        {membership?.years} Year Member
      </MemberYearsDescription>
    </MemberYearsWrapper>
  );
};
