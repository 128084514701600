import React from 'react';
import { MembershipDetailRealWrapper } from './styles';
import { MembershipDetailCell } from './MembershipDetailCell';
import { MembershipBadge } from './MemebershipBadge';
import { MembershipHeader } from './MembershipHeader';
import { IMembershipProps } from './MembershipComponent';
import { MembershipRenewalAlert } from './MembershipRenewalAlert';
import { MembershipLoader } from 'components/membership/MembershipLoader';
import { useContactService } from 'services';

export const MembershipDetailComponent: React.FC<IMembershipProps> = ({
  me = false,
}) => {
  const myContact = useContactService((state) => state.myContact);

  if (!myContact.data) {
    return <MembershipLoader me />;
  }

  return (
    <>
      <MembershipDetailRealWrapper me={me}>
        <MembershipHeader me={me} />
        <MembershipDetailCell isDetail />
        <MembershipBadge />
      </MembershipDetailRealWrapper>
      <MembershipRenewalAlert />
    </>
  );
};
