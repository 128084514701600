import React, { useEffect, useMemo } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Text } from 'styles';
import { useFormContext } from 'react-hook-form';
import { useDeviceSize } from 'hooks';
import { FormDataType, ItemType } from 'pages/membership';
import { AdditionalProductGroupType, OfferProductType } from 'type';
import QuantitySelector from './QuantitySelector';

interface IInteractableProps {
  interactable?: boolean;
}

const QuantitySelectorContainer = styled.div(() => [
  tw`
    flex
    flex-col-reverse xs:flex-row
    gap-2 sm:gap-4
    justify-center items-center
  `,
]);

const PriceTextContainer = styled.div(() => [
  tw`
  w-20
  flex
  justify-end`,
]);

const Container = styled.div<IInteractableProps>(({ interactable }) => [
  tw`
  flex
  justify-between items-center
  mb-7
  `,
  interactable ? tw`opacity-100` : tw`opacity-50`,
]);

const Label = styled.label<IInteractableProps>(({ interactable }) => [
  tw`flex items-center`,
  interactable ? tw`cursor-pointer` : tw`cursor-text`,
]);

const Name = styled.p<IInteractableProps>(({ interactable }) => [
  tw`mr-4 sm:mr-6`,
  !interactable && tw`mb-2`,
]);

interface IUpgradeCheckboxProps extends IInteractableProps {
  field: AdditionalProductGroupType['groupingType'];
  isOneOptional?: boolean;
  upgrade: OfferProductType;
  onClick: () => void;
}

const UpgradeCheckbox: React.FC<IUpgradeCheckboxProps> = ({
  field,
  isOneOptional = false,
  interactable = true,
  upgrade,
  onClick,
}) => {
  const {
    id,
    code,
    name,
    priceExcGst,
    gst,
    priceIncGst,
    group,
    status,
    quantityDefault,
    quantityMax,
    quantityMin,
    availableOnPlan,
  } = upgrade;

  const { watch, setValue } = useFormContext<FormDataType>();
  const { isMobile } = useDeviceSize();

  const item: ItemType = {
    id,
    code,
    quantity: 1,
    gst: +gst,
    group,
    status,
    priceExcGst: +priceExcGst,
    priceIncGst: +priceIncGst,
    availableOnPlan,
    name,
  };

  const handleChangeQuantity = (nextQuantity: number) => {
    const updateValue = (values: ItemType[]) => {
      setValue(field, values);
      onClick();
    };
    let updatedItems = watchedValues.filter(({ id: Id }) => Id !== id);
    if (nextQuantity <= 0) {
      return updateValue(updatedItems);
    }
    updatedItems = isOneOptional ? [item] : [...updatedItems, item];
    const product = updatedItems.find(({ id: Id }) => Id === id);
    if (product) {
      product.quantity = nextQuantity;
      updateValue(updatedItems);
    }
  };

  const responsiveLabel = isMobile ? Text.Normal : Text.Large;
  const watchedValues = watch(field) as ItemType[];

  const isQuantityChangeable = quantityMin !== quantityMax;

  const quantity = useMemo(() => {
    const product = watchedValues.find((v) => v.id === id);
    return product ? (product.quantity as number) : quantityDefault;
  }, [id, watchedValues, quantityDefault]);

  useEffect(() => {
    handleChangeQuantity(isQuantityChangeable ? quantityDefault : quantityMax);
    // eslint-disable-next-line
  }, [isQuantityChangeable, quantityMax, quantityDefault]);

  return (
    <Container interactable={interactable}>
      <Label htmlFor={id} interactable={interactable}>
        <Name as={responsiveLabel} interactable={interactable}>
          {name}
        </Name>
      </Label>
      <QuantitySelectorContainer>
        {isQuantityChangeable && (
          <QuantitySelector
            quantity={quantity}
            quantityMin={quantityMin}
            quantityMax={quantityMax}
            onChangeQuantity={handleChangeQuantity}
            disabled={!interactable}
          />
        )}
        <PriceTextContainer>
          <Text.Large>{`$${(
            +priceIncGst * (quantity === 0 ? 1 : quantity)
          ).toFixed(2)}`}</Text.Large>
        </PriceTextContainer>
      </QuantitySelectorContainer>
    </Container>
  );
};

export default UpgradeCheckbox;
