import { PaymentProfileType } from 'type';

export const getPreferredIdFromData = (
  paymentProfilesData: PaymentProfileType[] | null,
) => {
  const preferredId = paymentProfilesData?.find(
    (payment) => payment.preferred,
  )?.id;

  if (preferredId) {
    return preferredId;
  } else {
    return paymentProfilesData?.[0]?.id;
  }
};
