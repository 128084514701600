interface ISplashLogoProps {
  size?: number;
}

export const SplashLogo: React.FC<ISplashLogoProps> = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 266 266"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.9262 169.366C25.8752 121.48 48.4401 66.406 96.3264 46.3549C144.213 26.3039 199.287 48.8688 219.338 96.7551C239.389 144.641 216.824 199.716 168.938 219.767C121.051 239.818 65.9773 217.253 45.9262 169.366ZM214.872 98.6252C195.853 53.2055 143.616 31.8029 98.1965 50.8211C52.7768 69.8393 31.3742 122.077 50.3924 167.496C69.4107 212.916 121.648 234.319 167.068 215.3C212.487 196.282 233.89 144.045 214.872 98.6252Z"
        fill="url(#paint0_linear_3772_11366)"
      />
      <path
        d="M47.1741 92.9961C52.7953 80.8301 60.9535 70.0112 71.102 61.2644C81.2504 52.5177 93.1546 46.0454 106.017 42.2812C118.879 38.5171 132.401 37.548 145.678 39.4391C158.955 41.3302 171.679 46.0377 182.998 53.2461C194.317 60.4545 203.969 69.9972 211.307 81.2346C218.645 92.472 223.5 105.144 225.545 118.402C227.591 131.661 226.78 145.198 223.168 158.107C219.556 171.016 213.225 182.999 204.601 193.252L200.891 190.128C209.071 180.403 215.076 169.037 218.502 156.793C221.928 144.549 222.697 131.709 220.756 119.134C218.816 106.558 214.212 94.5388 207.252 83.8802C200.292 73.2217 191.137 64.1705 180.401 57.3334C169.665 50.4964 157.596 46.0313 145.003 44.2377C132.41 42.444 119.584 43.3631 107.385 46.9333C95.1853 50.5036 83.8943 56.6426 74.2686 64.9388C64.6428 73.2349 56.9048 83.4966 51.5732 95.0359L47.1741 92.9961Z"
        fill="url(#paint1_linear_3772_11366)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3772_11366"
          x1="-231.997"
          y1="-35.5703"
          x2="312.503"
          y2="246.93"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4C4581" />
          <stop offset="0.830537" stopColor="#4C4581" stopOpacity="0.01" />
          <stop offset="0.891373" stopColor="#4C4581" stopOpacity="0" />
          <stop offset="1" stopColor="#4C4581" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3772_11366"
          x1="220.93"
          y1="140"
          x2="-63.7577"
          y2="3.90815"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4C4581" />
          <stop offset="0.551313" stopColor="#4C4581" stopOpacity="0" />
          <stop offset="1" stopColor="#4C4581" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
