import tw from 'twin.macro';
import 'styled-components/macro';
import { useCallback, useEffect, useMemo } from 'react';
import { Text } from 'styles';
import { Radio } from 'components';
import {
  FlexContainer,
  Label,
  LabelContainer,
  LabelInfo,
  RadioButton,
  RadioContainer,
} from './styles';
import { useFormContext } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { deviceSize } from 'components/shared/types';
import { FormDataType, ItemType, RadioCheckboxType } from 'pages/membership';
import { RADIO_UPGRADES } from '../utils';

const Input = tw.input`hidden`;
const Tick = tw.div`mr-2 sm:mr-3`;

const FlexRowContainer = tw(FlexContainer)`
  flex-row
  items-center
  col-span-2 sm:col-span-4
  justify-self-start
`;

const StatusContainer = tw.div`
  flex
  flex-shrink-0
  flex-col-reverse xs:flex-row
  gap-2 sm:gap-4
  justify-center items-center
`;

const StatusCard = tw.p`
  col-span-1 sm:col-span-2
  w-[6.2rem] sm:w-auto
  text-center
  justify-self-end
  inline-block uppercase
  rounded py-1 px-3
  border-2 border-primaryBlue
  text-[0.70125rem] font-Soehne font-bold text-primaryBlue tracking-wider
`;

const PriceTextContainer = tw.div`
  w-20
  flex
  justify-end
`;

interface IRadioCheckboxProps<T> {
  field: typeof RADIO_UPGRADES[number];
  defaultSelected?: boolean;
  showTick?: boolean;
  showCount?: boolean;
  statusCardText?: string;
  disabled?: boolean;
  showSelect: boolean;
  upgrade: T;
  upgradeInfo?: string;
  multiSelectable?: boolean;
  onClick: (upgrade: T) => void;
}

const RadioCheckbox = <T extends RadioCheckboxType>({
  field,
  defaultSelected = false,
  showTick = false,
  showCount = true,
  statusCardText = '',
  disabled = false,
  showSelect,
  upgrade,
  upgradeInfo,
  multiSelectable = false,
  onClick,
}: IRadioCheckboxProps<T>) => {
  const { name, priceExcGst, gst, priceIncGst } = upgrade;
  const id =
    'subscriptionProductId' in upgrade
      ? upgrade.subscriptionProductId
      : upgrade.id;
  const group = 'group' in upgrade ? upgrade.group : upgrade.type;
  const status = 'status' in upgrade ? upgrade.status : 'Purchased';
  const code =
    'code' in upgrade ? upgrade.code : upgrade.subscriptionProductCode;
  const availableOnPlan =
    'availableOnPlan' in upgrade
      ? upgrade.availableOnPlan
      : upgrade.purchasedOnPaymentPlan;
  const quantity =
    'quantityDefault' in upgrade ? upgrade.quantityDefault : upgrade.quantity;
  const visibleToMember =
    'visibleToMember' in upgrade ? upgrade.visibleToMember : true;

  const { register, watch, setValue, getValues } =
    useFormContext<FormDataType>();
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });
  const responsiveLabel = isMobile ? Text.Normal : Text.Large;

  const item: ItemType = useMemo(() => {
    return {
      id,
      code,
      quantity,
      gst,
      group,
      status,
      priceExcGst,
      priceIncGst,
      availableOnPlan,
      name,
    };
  }, [
    id,
    code,
    quantity,
    gst,
    group,
    status,
    priceExcGst,
    priceIncGst,
    availableOnPlan,
    name,
  ]);

  const clickable = !showTick && showSelect && !disabled;
  const label = `${name}${!showCount || quantity <= 1 ? '' : ` x ${quantity}`}`;

  const handleClick = useCallback(() => {
    const value = getValues(field);
    if (multiSelectable) {
      setValue(field, [...value, item]);
    } else {
      setValue(field, [item]);
    }
    onClick(upgrade);
  }, [getValues, setValue, onClick, multiSelectable, field, item, upgrade]);

  useEffect(() => {
    if (defaultSelected) handleClick();
    // eslint-disable-next-line
  }, [defaultSelected]);

  if (!visibleToMember) return null;

  return (
    <>
      <Input {...register(field)} type="radio" id={id} name={id} />
      <RadioButton
        type="button"
        onClick={() => clickable && handleClick()}
        style={{
          cursor: clickable ? 'pointer' : 'default',
          opacity: disabled ? 0.5 : 1,
        }}
      >
        <FlexRowContainer>
          {showTick ? (
            <Tick>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
                tw="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </Tick>
          ) : showSelect ? (
            <RadioContainer>
              <Radio selected={watch(field).some((v) => v.id === item.id)} />
            </RadioContainer>
          ) : null}
          <LabelContainer>
            <Label as={responsiveLabel}>{label}</Label>
            {upgradeInfo && <LabelInfo>{upgradeInfo}</LabelInfo>}
          </LabelContainer>
        </FlexRowContainer>

        <StatusContainer>
          {!!statusCardText && <StatusCard>{statusCardText}</StatusCard>}
          <PriceTextContainer>
            <Text.Large textAlign="right">{`$${(
              +priceIncGst * quantity
            ).toFixed(2)}`}</Text.Large>
          </PriceTextContainer>
        </StatusContainer>
      </RadioButton>
    </>
  );
};

export default RadioCheckbox;
