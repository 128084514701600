import { AddressInfoType, AddressType } from 'type';

export type AddressKind = keyof Pick<
  AddressInfoType,
  'billing' | 'postal' | 'physical'
>;

export const formatAddress = (
  address: AddressType | null | undefined,
): string => {
  if (!address) return '';
  const formattedAddress: string[] = [];

  if (address.street1) formattedAddress.push(address.street1);
  if (address.street2) formattedAddress.push(address.street2);
  if (address.suburb) formattedAddress.push(address.suburb);
  if (address.state) formattedAddress.push(address.state);
  if (address.postCode) formattedAddress.push(address.postCode);
  if (address.country) formattedAddress.push(address.country);

  return formattedAddress.join(', ');
};
