import tw from 'twin.macro';
import { useFormContext } from 'react-hook-form';
import { useContactService, useMembershipService } from 'services';
import { LargeButton } from 'components';
import { FormDataType } from '../type';
import { instalmentHelpers } from 'helpers';
import { PaymentGatewayTransactionRequestType } from 'type';

import usePaymentSettings from './hooks/usePaymentSettings';
import { useMemo } from 'react';

const StyledPaymentSettings = tw.section`
  flex flex-col w-full
  md:flex-row md:gap-x-4
`;

const PaymentSettingButtonWrapper = tw.div`
  mt-2.5 w-full
`;

interface IPaymentSettingsProps {
  handleSetPaymentData: (data: PaymentGatewayTransactionRequestType) => void;
}

const PaymentSettings = ({ handleSetPaymentData }: IPaymentSettingsProps) => {
  const { watch, getValues } = useFormContext<FormDataType>();

  const myMemberships = useContactService((s) => s.myMemberships);
  const paymentProfiles = useContactService((s) => s.paymentProfiles);
  const loading = useContactService((s) => s.loading);

  const offers = useMembershipService((s) => s.offers);
  const paymentPlans = useMembershipService((s) => s.paymentPlans);
  const purchaseLoading = useMembershipService((s) => s.loading.purchase);
  const calculatedInstalments = useMembershipService(
    (s) => s.calculatedInstalments,
  );

  const { handleSaveSettings } = usePaymentSettings({ handleSetPaymentData });

  const isTncChecked = watch('tnc');
  const paymentOption = watch('paymentOption');
  const paymentProfile = watch('paymentProfile');
  const paymentPlan = watch('paymentPlan');
  const overduePlan = watch('overduePlan');
  const selectedInstalments = watch('selectedInstalments');
  const payBalanceRemaining = watch('payBalanceRemaining');
  const isNonFinancial = watch('offer.0.status') !== 'Purchased';
  const autoRenew = watch('autoRenew');
  const membership = myMemberships.data?.[0];

  const { payableInstalmentsAmount } =
    instalmentHelpers.getInstalmentPurchaseData(
      paymentPlan,
      calculatedInstalments.data,
      selectedInstalments,
      payBalanceRemaining,
      paymentOption,
    );

  const isNewPaymentProfile = useMemo(() => {
    const paymentProfilesData = paymentProfiles.data;
    const defaultPaymentProfile = paymentProfilesData?.find((p) => p.preferred);
    return paymentProfile?.id !== (defaultPaymentProfile?.id ?? '');
  }, [paymentProfile?.id, paymentProfiles.data]);

  const defaultAutoRenew = membership?.autoRenew;
  const isAutoRenewChanged =
    paymentOption === 'Pay in full' && autoRenew !== defaultAutoRenew;

  const enableSaveSettings = (() => {
    if (!isTncChecked) return false;
    if (isAutoRenewChanged && !isNonFinancial) return true;
    if (isNewPaymentProfile) return true;
    return false;
  })();

  const enablePayNow = (() => {
    if (!isTncChecked) return false;
    if (overduePlan) return true;
    if (paymentPlan) return !!payableInstalmentsAmount;
    if (!!getValues('totalAmount')) return true;
    return false;
  })();

  const showSaveSettingsButton = (() => {
    if (!enableSaveSettings && !enablePayNow) return true;
    if (enableSaveSettings && !enablePayNow) return true;
    if (!enableSaveSettings && enablePayNow) return false;
    if (enableSaveSettings && enablePayNow) {
      return isNonFinancial && defaultAutoRenew && isNewPaymentProfile;
    }
    return false;
  })();

  const showPayNowButton = (() => {
    if (!enableSaveSettings && !enablePayNow) return true;
    if (enablePayNow) return true;
    return false;
  })();

  const disabled =
    offers.loading ||
    paymentProfiles.loading ||
    paymentPlans.loading ||
    calculatedInstalments.loading;

  const saveSettingsLoading =
    loading.patchMembership ||
    loading.patchPaymentProfile ||
    loading.postPaymentProfile;

  return (
    <StyledPaymentSettings>
      {showSaveSettingsButton && (
        <PaymentSettingButtonWrapper key={`button1-${enableSaveSettings}`}>
          <LargeButton
            type="button"
            variant="secondary"
            label="SAVE PAYMENT SETTINGS"
            description="Payment will be processed on due dates"
            maxWidth="100%"
            disabled={!enableSaveSettings || disabled || purchaseLoading}
            loading={saveSettingsLoading}
            onClick={() =>
              handleSaveSettings({
                isPaymentProfileChanged: isNewPaymentProfile,
                isAutoRenewChanged: !!(
                  membership &&
                  !isNonFinancial &&
                  isAutoRenewChanged &&
                  autoRenew
                ),
              })
            }
          />
        </PaymentSettingButtonWrapper>
      )}
      {showPayNowButton && (
        <PaymentSettingButtonWrapper key={`button2-${enablePayNow}`}>
          <LargeButton
            type="submit"
            variant="primary"
            label="PAY NOW"
            description="Payment will be processed immediately"
            maxWidth="100%"
            disabled={!enablePayNow || disabled || saveSettingsLoading}
            loading={purchaseLoading}
          />
        </PaymentSettingButtonWrapper>
      )}
    </StyledPaymentSettings>
  );
};

export default PaymentSettings;
