import create from 'zustand';
import { EMPTY_GUID, http } from 'configs';
import { formatError } from 'helpers';
import {
  APIResponseType,
  DashboardType,
  ErrorType,
  MembershipType,
} from 'type';

const initialState = {
  data: null as null | DashboardType,
  errors: null as null | ErrorType | undefined | string,
};

export enum AnnouncementPageType {
  DASHBOARD = 'Dashboard',
  MEMBERSHIP = 'MembersPage',
}

type DashboardStoreState = typeof initialState;
type DashboardStore = DashboardStoreState & {
  getData: (membership: MembershipType) => Promise<void>;
  clearData: () => void;
  clearErrors: () => void;
};

const useDashboardService = create<DashboardStore>((set, get) => {
  return {
    ...initialState,
    getData: async (membership) => {
      if (get().data) return;
      try {
        const membershipId =
          membership.status === 'Active' || membership.status === 'Renewal'
            ? membership.id
            : EMPTY_GUID;
        const {
          data: { data, errors },
        }: APIResponseType<DashboardType> = await http.get(
          `/dashboard/${membershipId}`,
        );
        set({ data, errors });
      } catch (error) {
        set({ errors: formatError(error) });
      }
    },
    clearData: () => set(initialState),
    clearErrors: () => set({ errors: null }),
  };
});

export default useDashboardService;
