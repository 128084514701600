import tw from 'twin.macro';
import styled from 'styled-components';
import 'styled-components/macro';
import { ElementRef, useReducer, useRef } from 'react';
import { useOutsideClick } from 'hooks';
import { Text } from 'styles';
import { ErrorMessageWrapper } from './FormShared';

interface IContainerProps {
  maxWidth?: string;
  maxHeight?: string;
}

const MainContainer = styled.div<IContainerProps>(({ maxWidth }) => [
  tw`relative w-full bg-white`,
  `max-width: ${maxWidth};
   margin-left: -1px;
   margin-right: -1px;`,
]);

interface IDropdownButtonProps {
  disabled?: boolean;
}

const DropdownButton = styled.button<IDropdownButtonProps>(({ disabled }) => [
  tw`
  relative
  flex
  justify-between
  text-base
  placeholder-secondaryMidGrey
  border
  px-4
  py-3
  w-full
  focus:outline-none
  `,
  disabled
    ? tw`bg-neutralGrey text-secondaryMidGrey border-neutralGrey`
    : tw`text-primaryPurple border-primaryPurple`,
]);

interface IDropdownContainerProps {
  show: boolean;
  maxHeight?: string;
}

const DropDownContainer = styled.div<IDropdownContainerProps>(
  ({ show, maxHeight }) => [
    tw`
  absolute
  left-0
  right-0
  z-10
  bg-white
  border-b border-l border-r
  border-primaryPurple
  divide-y
  divide-secondaryKeyline
  focus:outline-none
  origin-top
  transition
  duration-100
`,
    show
      ? tw`transform opacity-100 scale-y-100`
      : tw`transform opacity-0 scale-y-0`,
    maxHeight && `max-height: ${maxHeight}; overflow-y: auto;`,
  ],
);

const Selections = tw.button`
  block
  w-full
`;

const SelectedText = tw(Text.Normal)`
  w-full
`;

const SelectionText = tw(Text.Normal)`
  text-left
  text-primaryPurple
  px-4
  py-3
  hover:bg-secondaryKeyline
`;

interface IDropdownProps<T> extends IContainerProps {
  selections: T[];
  selection: T;
  defaultSelection?: T;
  onSelected: (selection: T) => void;
  disabled?: boolean;
  required?: boolean;
  keyExtractor?: T extends string ? never : (selection: T) => string;
  nameExtractor?: (selection: T, index: number) => string | JSX.Element;
}

const Dropdown = <T extends string | Record<string, unknown>>({
  selections,
  selection,
  onSelected,
  required = false,
  maxWidth = '200px',
  maxHeight = '225px',
  disabled,
  defaultSelection = selections[0],
  keyExtractor,
  nameExtractor,
}: IDropdownProps<T>) => {
  const ref = useRef<ElementRef<'div'>>(null);
  const [show, toggleShow] = useReducer((s) => !s, false);

  useOutsideClick({ refs: [ref], callback: show ? toggleShow : () => {} });

  const handleSelected = (selected: T) => {
    toggleShow();
    onSelected(selected);
  };

  return (
    <MainContainer ref={ref} maxWidth={maxWidth}>
      <DropdownButton
        type="button"
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        disabled={disabled}
        onClick={toggleShow}>
        <SelectedText>
          {nameExtractor?.(selection, selections.indexOf(selection)) ??
            selection.toString()}
        </SelectedText>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
          tw="flex-shrink-0 h-6 w-6 transition"
          style={{ transform: show ? 'rotate(180deg)' : 'rotate(0deg)' }}>
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
        {required && selection === defaultSelection && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            tw="absolute top-1/2 right-2 transform -translate-y-1/2 h-6 w-6 text-errorRed">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        )}
      </DropdownButton>

      <DropDownContainer
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        show={show}
        maxHeight={maxHeight}>
        {selections.map((selection, index) => {
          const key =
            typeof selection === 'string'
              ? `${selection}-${index}`
              : keyExtractor?.(selection) ?? JSON.stringify(selection);
          return (
            <Selections key={key} type="button">
              <SelectionText onClick={() => handleSelected(selection)}>
                {nameExtractor?.(selection, index) ?? selection.toString()}
              </SelectionText>
            </Selections>
          );
        })}
      </DropDownContainer>

      {required && selection === defaultSelection && (
        <ErrorMessageWrapper>Must be selected</ErrorMessageWrapper>
      )}
    </MainContainer>
  );
};

export default Dropdown;
