import React, { useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import 'styled-components/macro';
import { useFormContext } from 'react-hook-form';
import { ErrorMessageWrapper } from './FormShared';

interface IInputGeneralProps {
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: string | number;
  value?: string | number;
  errorMessage?: string;
  focused?: boolean;
}

interface IInputProps extends IInputGeneralProps {
  handleChangeText?: (value: any) => any;
  onFocus?: () => any;
  onBlur?: () => any;
  required?: boolean;
}

const MainContainer = tw.div`
  flex
  flex-col
  w-full
`;

const InputContainer = tw.div`
  relative
  flex
  flex-col
  w-full
`;

const StyledInput = styled.input<IInputProps | IInputGeneralProps>(
  ({ disabled, errorMessage, focused }) => [
    tw`
  text-sm sm:text-base
  text-primaryPurple
  placeholder-secondaryMidGrey
  pl-2 md:pl-4
  pr-2 md:pr-4
  py-3
  w-full
  focus:outline-none
  `,
    `
   box-shadow:0 0 0 ${focused ? '2px' : '1px'} ${
      errorMessage ? '#BE1313' : '#201547'
    };
  `,
    disabled ? `box-shadow:0 0 0 1px #E9E9EA;` : ``,
  ],
);

const Input: React.FC<IInputProps> = (props) => {
  const {
    name,
    errorMessage,
    handleChangeText,
    onFocus,
    onBlur,
    required = false,
  } = props;
  const { register } = useFormContext();
  const [focused, setFocused] = useState<boolean>(false);

  const handleFocused = () => {
    setFocused(true);
    onFocus?.();
  };

  const handleBlurred = () => {
    setFocused(false);
    onBlur?.();
  };

  return (
    <MainContainer>
      <InputContainer>
        {name && (
          <StyledInput
            {...props}
            {...register(name)}
            focused={focused}
            required={required}
          />
        )}
        {!name && (
          <StyledInput
            {...props}
            onChange={(e) => handleChangeText?.(e.target.value)}
            onFocus={handleFocused}
            onBlur={handleBlurred}
            focused={focused}
            required={required}
          />
        )}
        {!!errorMessage && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            tw="absolute top-1/2 right-2 transform -translate-y-1/2 h-6 w-6 text-errorRed"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        )}
      </InputContainer>
      {!!errorMessage && (
        <ErrorMessageWrapper>{errorMessage}</ErrorMessageWrapper>
      )}
    </MainContainer>
  );
};

export default Input;
