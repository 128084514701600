import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useMembershipService } from 'services';
import { useDebounce } from 'hooks';
import { MemberPaymentPlanType, OfferProductType } from 'type';
import { paymentPlanHelpers, preferenceHelpers } from 'helpers';
import { FormDataType } from '../../type';

const usePaymentPlan = () => {
  const { debounce } = useDebounce();
  const { membershipId } = useParams();
  const { setValue, getValues, watch } = useFormContext<FormDataType>();

  const [totalAmount, setTotalAmount] = useState<number>(0);

  const offersPackageData = useMembershipService((s) => s.offers.data);
  const paymentPlansData = useMembershipService((s) => s.paymentPlans.data);
  const preferredPlanData = useMembershipService((s) => s.preferredPlan.data);

  const postCalculateInstalments = useMembershipService(
    (s) => s.postCalculateInstalments,
  );
  const clearCalculatedInstalments = useMembershipService(
    (s) => s.clearCalculatedInstalments,
  );

  const paymentOption = watch('paymentOption');
  const isAutoRenew = watch('autoRenew');
  const currentTotalAmount = watch('totalAmount');

  const activePlan = useMemo(() => {
    return paymentPlanHelpers.getActivePlan(paymentPlansData ?? []);
  }, [paymentPlansData]);

  const isPayInInstalmentDefault = useMemo(() => {
    preferenceHelpers.paymentPlan.setDefault(preferredPlanData);
    return (preferredPlanData?.numberOfInstalments ?? 0) > 1;
  }, [preferredPlanData]);

  if (totalAmount === 0 && currentTotalAmount > 0) {
    setTotalAmount(currentTotalAmount);
  }

  const updateCalculatedPaymentPlans = async () => {
    await debounce();
    // This will be triggered when these change:
    // - Select different payment option
    // - Select different payment plan from dropdown
    // - Select optional products
    setValue('selectedInstalments', []);
    const paymentPlan = getValues('paymentPlan');
    const paymentOption = getValues('paymentOption');
    const itemsToAdd = [
      ...getValues('offer'),
      ...getValues('JoiningFee'),
      ...getValues('AllMandatory'),
      ...getValues('OneMandatory'),
      ...getValues('AllOptional'),
      ...getValues('OneOptional'),
    ].filter(
      (i) => i?.status === 'Available' && i.priceIncGst > 0 && i.quantity > 0,
    );
    if (membershipId && paymentPlan) {
      const currentPlan = {
        ...paymentPlan,
        instalments: paymentPlan.instalments ?? [],
      };
      const res = await postCalculateInstalments(membershipId, paymentPlan.id, {
        currentPlan,
        itemsToAdd: itemsToAdd as OfferProductType[],
      });
      if (!res) return;
      if (paymentOption === 'Pay in full') {
        const selectedInstalments =
          res.newOrUpdatedPaymentPlan.instalments?.filter(
            (i) => i.status !== 'Paid',
          ) ?? [];
        setValue('selectedInstalments', selectedInstalments);
      } else if (paymentOption === 'Pay in instalments') {
        const { isPreferenceChanged } = preferenceHelpers.isDirty(getValues);
        const hasOverdue = res.newOrUpdatedPaymentPlan.instalments?.some(
          (i) => i.status === 'Overdue',
        );
        const firstPendingIndex =
          res.newOrUpdatedPaymentPlan.instalments?.findIndex(
            (i) => i.status !== 'Paid',
          );
        setValue(
          'selectedInstalments',
          res.newOrUpdatedPaymentPlan.instalments?.filter((i, index) => {
            if (i.status === 'Overdue') return true;
            if (
              !hasOverdue &&
              isPreferenceChanged &&
              index === firstPendingIndex
            ) {
              return true;
            }
            return false;
          }) ?? [],
        );
      }
    }
  };

  const handleUpdatePaymentPlan = (
    paymentPlan: MemberPaymentPlanType | null,
  ) => {
    if (!paymentPlan) clearCalculatedInstalments();
    setValue('paymentPlan', paymentPlan);
    updateCalculatedPaymentPlans();
  };

  useEffect(
    () => {
      if (!currentTotalAmount) {
        setValue('payBalanceRemaining', true);
        if (!isPayInInstalmentDefault) {
          setValue('paymentOption', 'Pay in full');
        }
      }
      updateCalculatedPaymentPlans();
    },
    // eslint-disable-next-line
    [currentTotalAmount, isPayInInstalmentDefault, setValue],
  );

  useEffect(() => {
    if (isPayInInstalmentDefault) {
      setValue('paymentOption', 'Pay in instalments');
    }
  }, [isPayInInstalmentDefault, setValue]);

  useEffect(() => {
    const overduePlan = paymentPlansData?.find((p) => p.status === 'Overdue');
    if (overduePlan) setValue('overduePlan', overduePlan);
  }, [paymentPlansData, setValue]);

  useEffect(() => {
    if (!offersPackageData) return;
    if (activePlan) {
      if (paymentOption === 'Pay in instalments') {
        setValue('autoRenew', true);
        setValue('payBalanceRemaining', true);
      }
      handleUpdatePaymentPlan(activePlan);
      return;
    }
    if (paymentOption === 'Pay in full') {
      if (isAutoRenew) {
        if (paymentPlansData) {
          const onePlan = paymentPlanHelpers.getOnePlan(paymentPlansData);
          if (onePlan) handleUpdatePaymentPlan(onePlan);
        }
      } else {
        handleUpdatePaymentPlan(null);
      }
    }
    if (paymentOption === 'Pay in instalments') {
      setValue('autoRenew', true);
      setValue('payBalanceRemaining', true);
      if (paymentPlansData) {
        if (preferredPlanData && preferredPlanData.numberOfInstalments > 1) {
          return handleUpdatePaymentPlan(preferredPlanData);
        }
        const fourPlan = paymentPlanHelpers.getFourPlan(paymentPlansData);
        if (fourPlan) return handleUpdatePaymentPlan(fourPlan);
        const noOnePlans = paymentPlanHelpers.getNoOnePlans(paymentPlansData);
        const firstPlan = noOnePlans[0];
        if (firstPlan) handleUpdatePaymentPlan(firstPlan);
      }
    }
    // eslint-disable-next-line
  }, [
    offersPackageData,
    preferredPlanData,
    activePlan,
    paymentOption,
    isAutoRenew,
    paymentPlansData,
    setValue,
  ]);

  return { handleUpdatePaymentPlan };
};

export default usePaymentPlan;
