import React from 'react';
import tw from 'twin.macro';
import { Text } from 'styles';

const StyledCancelButton = tw.div`
  flex
  justify-end
  px-3 md:px-7
  mb-2
`;

const StyledText = tw(Text.Normal)`
  underline
  transition
  duration-300
  hover:text-primaryBlue
`;

interface IMemberEditCancelButtonProps {
  handleCancel: () => void;
}

const MemberEditCancelButton: React.FC<IMemberEditCancelButtonProps> = ({
  handleCancel,
}) => {
  return (
    <StyledCancelButton>
      <button type="button" onClick={handleCancel}>
        <StyledText>Cancel</StyledText>
      </button>
    </StyledCancelButton>
  );
};

export default MemberEditCancelButton;
