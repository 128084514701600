import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useMsal,
  useIsAuthenticated,
  useMsalAuthentication,
} from '@azure/msal-react';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { usePersistentService } from 'services';
import { passwordResetRequest } from 'configs';
import { ScreenLoader } from 'components';
import { useResetStores } from 'hooks';

// Reference:
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/FAQ.md#how-do-i-handle-the-forgot-password-flor-in-a-react-app

const FORGOT_PASSWORD_CODE = 'AADB2C90118';

interface ILoginProps {}

const Login = (_: ILoginProps) => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const loginRedirectPath = usePersistentService((s) => s.loginRedirectPath);
  const setAuthority = usePersistentService((s) => s.setAuthority);
  const { error } = useMsalAuthentication(InteractionType.None);
  const { instance, inProgress } = useMsal();
  const { resetStores } = useResetStores();

  const isForgotPassword = useMemo(() => {
    return !!error && error.errorMessage.indexOf(FORGOT_PASSWORD_CODE) > -1;
  }, [error]);

  useEffect(() => {
    if (isAuthenticated) {
      return loginRedirectPath ? navigate(loginRedirectPath) : navigate('/');
    }
    if (inProgress !== InteractionStatus.None) return;
    if (isForgotPassword) {
      setAuthority('passwordReset');
      instance.loginRedirect(passwordResetRequest);
    } else {
      setAuthority('signIn');
      instance.loginRedirect();
    }
    resetStores();
  }, [
    isAuthenticated,
    inProgress,
    loginRedirectPath,
    error,
    isForgotPassword,
    instance,
    navigate,
    setAuthority,
    resetStores,
  ]);

  return <ScreenLoader visible />;
};

export default Login;
