import { Checkbox } from 'components';
import { AddressKind } from 'helpers';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import tw from 'twin.macro';
import { ContactType } from 'type';
import {
  EditFieldWrapper,
  EditFieldList,
  Label,
  LabelWrapper,
} from '../../../../styles';
import { FormValues } from '../../types';
import { MemberAddressEdit } from './MemberAddressEdit';
import { Text } from 'styles';

const CheckboxContainer = tw.label`flex items-center`;
const CheckboxInput = tw.input`hidden`;
const CheckboxLabel = styled(Text.Normal)`
  ${tw`
    text-sm md:text-md ml-4 cursor-pointer
  `}
`;

interface IAddressProps {
  data: ContactType;
}

const Address: React.FC<IAddressProps> = ({ data }) => {
  const { control, setValue, getValues, watch } = useFormContext<FormValues>();

  const watchPhysicalAddress = watch('addresses.physical');
  const watchPostalAddressSameAsPhysical = watch(
    'addresses.postalAddressSameAsPhysical',
  );
  const watchBillingAddressSameAsPhysical = watch(
    'addresses.billingAddressSameAsPhysical',
  );

  const handleManualEdit =
    (addressKind: AddressKind) => (manualEdit: boolean) => {
      if (manualEdit) {
        setValue(`addresses.${addressKind}.dpid`, '');
      } else if (!manualEdit && data.addresses) {
        setValue(`addresses.${addressKind}`, data.addresses[addressKind]);
      }
    };

  useEffect(() => {
    if (watchPostalAddressSameAsPhysical) {
      setValue('addresses.postal', {
        ...watchPhysicalAddress,
        label: data.addresses.postal.label,
      });
    }
    if (watchBillingAddressSameAsPhysical) {
      setValue('addresses.billing', {
        ...watchPhysicalAddress,
        label: data.addresses.billing.label,
      });
    }
  }, [
    data.addresses,
    watchPhysicalAddress,
    watchPostalAddressSameAsPhysical,
    watchBillingAddressSameAsPhysical,
    setValue,
  ]);

  return (
    <EditFieldList>
      <MemberAddressEdit
        inputName="physical"
        onManualEdit={handleManualEdit('physical')}
      />

      <EditFieldWrapper>
        <LabelWrapper>
          <Label>{data.addresses.postal.label}</Label>
        </LabelWrapper>

        <Controller
          control={control}
          name="addresses.postalAddressSameAsPhysical"
          render={({ field: { onChange, value, name } }) => (
            <CheckboxContainer htmlFor={name}>
              <CheckboxInput
                type="checkbox"
                id={name}
                name={name}
                checked={value}
                onChange={onChange}
              />
              <Checkbox checked={value} />
              <CheckboxLabel>Same as Physical Address</CheckboxLabel>
            </CheckboxContainer>
          )}
        />
      </EditFieldWrapper>

      {!getValues('addresses.postalAddressSameAsPhysical') && (
        <EditFieldWrapper>
          <MemberAddressEdit
            inputName="postal"
            showLabel={false}
            onManualEdit={handleManualEdit('postal')}
          />
        </EditFieldWrapper>
      )}

      <EditFieldWrapper>
        <LabelWrapper>
          <Label>{data.addresses.billing.label}</Label>
        </LabelWrapper>
        <Controller
          control={control}
          name="addresses.billingAddressSameAsPhysical"
          render={({ field: { onChange, value, name } }) => (
            <CheckboxContainer htmlFor={name}>
              <CheckboxInput
                type="checkbox"
                id={name}
                name={name}
                checked={value}
                onChange={onChange}
              />
              <Checkbox checked={value} />
              <CheckboxLabel>Same as Physical Address</CheckboxLabel>
            </CheckboxContainer>
          )}
        />
      </EditFieldWrapper>

      {!getValues('addresses.billingAddressSameAsPhysical') && (
        <EditFieldWrapper>
          <MemberAddressEdit
            inputName="billing"
            showLabel={false}
            onManualEdit={handleManualEdit('billing')}
          />
        </EditFieldWrapper>
      )}
    </EditFieldList>
  );
};

export default Address;
