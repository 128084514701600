import { IMenu } from 'configs';
import { useOutsideClick } from 'hooks';
import { ElementRef, useRef, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { DropdownMenuItem } from './DropdownMenuItem';

const DropdownMenuStyle = tw.div`relative`;

const DropdownValueStyle = tw.button`
  text-left
  w-full
  relative
  h-16
  px-9
  bg-primaryBlue text-white
  font-bold
  leading-[4rem] tracking-widest
  shadow-[0 4px 54px 0 rgba(0, 0, 0, 0.25)]
`;

const DropdownArrowContainerStyle = tw.div`
  absolute
  top-1/2
  right-[2.35rem]
  translate-y--1/2
  pointer-events-none
`;

const DropdownArrowStyle = styled.p`
  ${tw`text-white transition duration-300 transform`}
  &:after {
    content: '\f078';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
  }
`;

const MenuStyle = styled.ul<{ isOpen: boolean }>(({ isOpen }) => [
  tw`
    absolute
    top-full
    list-none
    bg-white
    left-0
    right-0
    text-xs
    px-8
    justify-center
    items-center
    transition transition-all duration-300 overflow-hidden`,
  isOpen ? tw`max-h-[800px] py-4` : tw`max-h-0`,
]);

type IDropdownMenu = {
  items: IMenu[];
  value: string;
};

export const DropdownMenu: React.FC<IDropdownMenu> = ({ items, value }) => {
  const ref = useRef<ElementRef<'div'>>(null);

  const [isOpen, setIsOpen] = useState(false);

  function toggleDropdown() {
    setIsOpen((prev) => !prev);
  }

  useOutsideClick({ refs: [ref], callback: () => setIsOpen(false) });

  const activeItem = items.find((item) => item.id === value);

  return (
    <DropdownMenuStyle ref={ref}>
      <DropdownValueStyle onClick={toggleDropdown}>
        <span>{activeItem?.label}</span>
        <DropdownArrowContainerStyle>
          <DropdownArrowStyle
            style={{
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        </DropdownArrowContainerStyle>
      </DropdownValueStyle>

      <MenuStyle isOpen={isOpen}>
        {items.map((item) => {
          return (
            <DropdownMenuItem
              key={item.id}
              isActive={item.id === value}
              {...item}
            />
          );
        })}
      </MenuStyle>
    </DropdownMenuStyle>
  );
};
