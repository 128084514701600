import tw from 'twin.macro';
import styled from 'styled-components';
import { useMemo } from 'react';
import { Text } from 'styles';
import { useLocation } from 'react-router-dom';
import { getSideNavRoutes } from 'routes/ProtectedRouteList';
import { omit, drop } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { deviceSize } from './types';
import { getMobileMenus } from '../../configs/constants';
import { CustomLink, DropdownMenu } from 'components';
import { useContactService } from 'services';
import { trackGTMEvent } from 'helpers/trackGTMEvent';
import { MembershipType } from 'type';

const SideContainer = styled.div`
  ${tw`
    w-full
    bg-white
    items-center
    top-[0px]
    z-20
    lg:z-0
    sticky
  `}
  @media (min-width: 1025px) {
    width: 20.125rem;
    padding-top: 0.8rem;
  }
`;

const NavItems = tw.ul`
    list-none
    text-xs
    mx-2.5
    justify-center
    items-center
    sticky
    top-[calc(152px + 0.8rem)]
`;
interface INavItemSelectedBgProps {
  pathname: string;
  myPathname: string;
}

const NavItem = styled.li`
  color: #4c4581;
  height: calc(3.3rem + 10px);
  letter-spacing: 0.1em;
  ${tw`
    relative
    flex
    items-center
    pl-5
    cursor-pointer
    text-xs
    transition-colors
    transition-duration[300ms]
    hover:text-gray-200
    font-semibold
  `}
`;

const NavLinkContainer = styled.div<INavItemSelectedBgProps>(
  ({ pathname, myPathname }) => [
    tw`
      w-full h-full transition
      after:absolute after:w-full after:h-full after:bg-primaryBlue after:top-0 after:left-0 after:rounded
      after:opacity-0 hover:after:opacity-[0.05] after:transition after:duration-[0.8s]
    `,
    isCurrentPath(pathname, myPathname)
      ? tw`after:opacity-[0.15]`
      : tw`bg-transparent`,
  ],
);

export const SideBar = () => {
  const isLaptop = useMediaQuery({ maxWidth: deviceSize.laptop });
  const myMembershipsData = useContactService((s) => s.myMemberships.data);
  const membership = myMembershipsData?.[0];

  return (
    <SideContainer>
      {isLaptop ? (
        <MenusDropdown membership={membership} />
      ) : (
        <MenusSideBar membership={membership} />
      )}
    </SideContainer>
  );
};

interface IMenusProps {
  membership: MembershipType | undefined;
}

const MenusSideBar = ({ membership }: IMenusProps) => {
  const { pathname } = useLocation();
  const nonMobileMenus = getSideNavRoutes(membership?.id ?? '');
  const allMenus = menusToShow(membership, nonMobileMenus, 'name');
  return (
    <NavItems>
      {allMenus.map(({ path, name }) => (
        <NavItem
          key={path}
          onClick={() => trackGTMEvent('main_menu_click', { menu_item: name })}>
          <CustomLink to={path}>
            <NavLinkContainer pathname={pathname} myPathname={path}>
              <Text.XSmallBold
                color={isCurrentPath(pathname, path) ? '#201547' : '#4C4581'}>
                {name}
              </Text.XSmallBold>
            </NavLinkContainer>
          </CustomLink>
        </NavItem>
      ))}
    </NavItems>
  );
};

const MenusDropdown = ({ membership }: IMenusProps) => {
  const { pathname } = useLocation();
  const mobileMenus = getMobileMenus(membership?.id ?? '');
  const allMenus = menusToShow(membership, mobileMenus, 'label');

  const currentMenu = useMemo(() => {
    const menus =
      pathname === '/'
        ? [allMenus[0]]
        : drop(allMenus, 1).filter((m) => pathname.indexOf(m.to) !== -1);
    const menu = menus.length ? menus[0] : allMenus[0];
    return omit(menu, ['to']);
  }, [allMenus, pathname]);

  return (
    <DropdownMenu
      items={allMenus.map((menu) => ({ ...menu }))}
      value={currentMenu.id}
    />
  );
};

const isCurrentPath = (pathname: string, myPathname: string) => {
  return (
    pathname === myPathname ||
    (pathname.includes(myPathname) && myPathname !== '/')
  );
};

const menusToShow = <
  TMenu extends
    | ReturnType<typeof getSideNavRoutes>[0]
    | ReturnType<typeof getMobileMenus>[0],
>(
  membership: MembershipType | undefined,
  menus: TMenu[],
  filterKey: keyof TMenu,
) => {
  const navsToHide: string[] = [];

  if (membership?.status === 'Offer') navsToHide.push('MY MEMBERSHIP');

  const newMenus = menus.filter((m) => {
    const value = m[filterKey];
    return typeof value === 'string' ? !navsToHide.includes(value) : false;
  });

  return newMenus;
};
