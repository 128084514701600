import { PromptModal } from 'components';
import React from 'react';

interface IConfirmAddUpdateModalProps {
  show: boolean;
  loading: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

export const ConfirmAddUpdateModal: React.FC<IConfirmAddUpdateModalProps> = ({
  show,
  loading,
  handleClose,
  handleConfirm,
}) => {
  return (
    <PromptModal
      title="Unsaved changes"
      description={
        'Payment cards are managed on a new page.  All unsaved changes will be lost.'
      }
      buttonText="Continue"
      show={show}
      loading={loading}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
    />
  );
};
