import { PageContainer } from 'components';
import TransactionDetailsContent from './TransactionDetailsContent';

interface ITransactionDetailsProps {}

const TransactionDetails: React.FC<ITransactionDetailsProps> = () => {
  return (
    <PageContainer>
      <TransactionDetailsContent />
    </PageContainer>
  );
};

export default TransactionDetails;
