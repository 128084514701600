import tw from 'twin.macro';
import styled from 'styled-components';

export const Container = tw.section`
  px-3
  sm:px-16
  xl:px-32
  py-8
  sm:py-16
`;

export const HeaderWrapper = tw.div`
  flex
  justify-between
  items-center
  mt-8
  sm:mt-16
`;

export const HeaderLeftWrapper = tw.div`
  flex
  flex-col
`;

export const TransactionNumberWrapper = tw.div`
  mb-2
  sm:mb-3
`;

export const TransactionNumber = styled.p``;
export const TransactionDate = styled.p`
  ${tw`
    flex items-center
  `}
`;

interface IDescriptionProps {
  variant: 'header' | 'row';
}

export const DescriptionWrapper = styled.div<{ variant: 'desktop' | 'mobile' }>(
  ({ variant }) => [
    tw``,
    variant === 'desktop' && tw`hidden md:block`,
    variant === 'mobile' && tw`block md:hidden`,
  ],
);

export const DescriptionRow = styled.div<IDescriptionProps>(({ variant }) => [
  tw`
  grid
  grid-cols-12
  gap-x-4
  `,
  variant === 'header' ? tw`mb-6 sm:mb-10` : tw`mb-6 sm:mb-8`,
]);

export const MemberNoColumn = styled.div<IDescriptionProps>(({ variant }) => [
  tw`
    col-span-3
  `,
  variant === 'header'
    ? tw`text-primaryPurple font-bold`
    : tw`text-secondaryWarmDarkGrey`,
]);

export const DescriptionColumn = styled.div<IDescriptionProps>(
  ({ variant }) => [
    tw`
    col-span-5
    `,
    variant === 'header'
      ? tw`text-primaryPurple font-bold`
      : tw`text-secondaryWarmDarkGrey`,
  ],
);

export const QuantityColumn = styled.div<IDescriptionProps>(({ variant }) => [
  tw`
  col-span-2
  `,
  variant === 'header'
    ? tw`text-primaryPurple font-bold`
    : tw`text-secondaryWarmDarkGrey`,
]);

export const CashTotalColumn = styled.div<IDescriptionProps>(({ variant }) => [
  tw`
  col-span-2
  text-right
  `,
  variant === 'header'
    ? tw`text-primaryPurple font-bold`
    : tw`text-secondaryWarmDarkGrey`,
]);

export const Label = styled.div`
  ${tw`text-primaryPurple font-bold`}
`;
export const Value = styled.div`
  ${tw`text-secondaryWarmDarkGrey`}
`;

export const MobileItemsWrapper = tw.div`flex gap-3 flex-col`;

export const MobileItem = tw.div`bg-white px-3 py-2 divide-y divide-neutralMidGrey`;

export const MobileItemRow = tw.div`grid grid-template-columns[100px 1fr] py-3`;

export const ExtraDetailsWrapper = tw.div`
  flex
  justify-between
`;

export const PriceName = styled.div`
  ${tw`capitalize`}
`;

export const PriceValue = styled.div``;
