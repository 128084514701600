import React, { useEffect, useRef, useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import 'styled-components/macro';
import { Text } from 'styles';

interface IExpand {
  expand: boolean;
}

interface IHeight {
  height: number | undefined;
}

const MainContainer = tw.section`flex flex-col`;
const Title = tw(Text.LargeBold)`select-none mt-2 mb-0.5`;

const RowContainer = tw.div`
  flex flex-row justify-between items-center
  cursor-pointer
  mb-2
`;

const ChevronContainer = styled.div<IExpand>(({ expand }) => [
  tw`transition duration-300`,
  expand ? tw`rotate-180` : tw`rotate-0`,
]);

const ContentContainer = styled.div<IExpand & IHeight>(({ expand, height }) => [
  tw`overflow-hidden transition-all duration-300`,
  expand ? tw`mt-6` : tw`pt-0`,
  height === undefined ? '' : `max-height: ${expand ? `${height}px` : 0};`,
]);

interface IRenewalAccordionProps {
  title: string;
  defaultExpanded?: boolean;
}

const RenewalAccordion: React.FC<IRenewalAccordionProps> = ({
  title,
  defaultExpanded = true,
  children,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const [expand, setExpand] = useState<boolean>(defaultExpanded);
  const [height, setHeight] = useState<IHeight['height']>(undefined);

  const toggleExpansion = () => setExpand(!expand);

  const calculateHeight = () => {
    if (contentRef.current) setHeight(contentRef.current.scrollHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', calculateHeight);
    return () => window.removeEventListener('resize', calculateHeight);
  }, []);

  useEffect(calculateHeight, [children]);

  return (
    <MainContainer>
      <RowContainer onClick={toggleExpansion}>
        <Title>{title}</Title>
        <ChevronContainer expand={expand}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            tw="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </ChevronContainer>
      </RowContainer>
      <ContentContainer ref={contentRef} expand={expand} height={height}>
        {children}
      </ContentContainer>
    </MainContainer>
  );
};

export default RenewalAccordion;
