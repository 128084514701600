import create from 'zustand';
import { ErrorType, ToastState } from 'type';

const initialState = {
  state: 'error' as ToastState | undefined,
  errors: null as null | ErrorType | undefined | string,
};

type ErrorStoreState = typeof initialState;
type ErrorStoreStore = ErrorStoreState & {
  setError: (
    errors: ErrorType | null | undefined | string,
    state?: ToastState | undefined,
  ) => void;
  clearError: () => void;
};

const useErrorService = create<ErrorStoreStore>((set, _get) => {
  return {
    ...initialState,
    setError: (
      errors: ErrorType | null | undefined | string,
      state: ToastState | undefined = 'error',
    ) => {
      set({ errors: errors, state: state });
    },
    clearError: () => {
      set({ errors: null, state: undefined });
    },
  };
});

export default useErrorService;
