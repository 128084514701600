const preview = (base64: string): void => {
  const byteCharacters = window.atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new File([byteArray], 'testing', {
    type: 'application/pdf;base64',
  });
  const fileUrl = URL.createObjectURL(file);
  window.open(fileUrl);
};

const download = (base64: string, fileName: string): void => {
  const linkSource = `data:pdf;base64,${base64}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const pdfHelpers = {
  preview,
  download,
};
