import { Routes, Route, Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { PROTECTED_ROUTE_LIST } from './ProtectedRouteList';
import { useLocation } from 'react-router-dom';
import { useContactService, usePersistentService } from 'services';
import Login from 'pages/auth/Login';

const EXCLUDED_PATHS_REGEX = [
  /^\/login(?:\?[^/]*)?$/,
  /^\/membership\/[^/]+\/renewal(?:\?[^/]*)?$/,
];

export const Routers = () => {
  const { pathname, search } = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const getMyContact = useContactService((s) => s.getMyContact);
  const getMyMemberships = useContactService((s) => s.getMyMemberships);
  const myContactData = useContactService((s) => s.myContact.data);
  const setLoginRedirectPath = usePersistentService(
    (s) => s.setLoginRedirectPath,
  );

  useEffect(() => {
    const currentPath = `${pathname}${search}`;
    if (!EXCLUDED_PATHS_REGEX.some((regex) => regex.test(currentPath))) {
      setLoginRedirectPath(currentPath);
    }
  }, [pathname, search, setLoginRedirectPath]);

  useEffect(() => {
    if (isAuthenticated) getMyContact();
  }, [isAuthenticated, getMyContact]);

  useEffect(() => {
    if (myContactData) getMyMemberships();
  }, [myContactData, getMyMemberships]);

  return (
    <Routes>
      {PROTECTED_ROUTE_LIST.map(({ path, component }) => (
        <Route
          key={path}
          path={path}
          element={isAuthenticated ? component : <Navigate to="/login" />}
        />
      ))}
      <Route path="/login" element={<Login />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
};
