import tw from 'twin.macro';
import styled from 'styled-components';
import { Text } from 'styles';
import { PaymentSettings } from './PaymentSettings/PaymentSettings';
import { PersonalInformation } from './PersonalInformation/PersonalInformation';
import { useSearchParams } from 'react-router-dom';

const Wrapper = tw.div`
  flex flex-col gap-8 
  px-5 md:px-8
  mt-8 md:mt-10
`;

const Box = tw.div`bg-white p-6 md:p-16`;

const TabHeader = tw.div`flex gap-7 overflow-x-auto border-b border-[#D9D9D9]`;

const TabButton = styled(Text.Normal)<{ active: boolean }>(({ active }) => [
  tw`cursor-pointer whitespace-nowrap pb-1`,
  active ? tw`font-bold border-b-2 border-primaryPurple` : tw`opacity-60`,
]);

const TabContent = tw.div`mt-8 md:mt-10`;

const TAB_PARAM_KEY = 'tabId';
const TABS = [
  {
    id: 'personal-information',
    name: 'Personal Information',
    content: <PersonalInformation />,
  },
  {
    id: 'payment-settings-and-preferences',
    name: 'Payment Settings and Preferences',
    content: <PaymentSettings />,
  },
] as const;

export const MyDetails = () => {
  const [params, setParams] = useSearchParams();
  const tabId = params.get(TAB_PARAM_KEY);

  const activeTab = tabId || TABS[0].id;
  const activeTabContent = TABS.find((tab) => tab.id === activeTab)?.content;

  const handleTabClick = (tabId: string) => {
    setParams({ [TAB_PARAM_KEY]: tabId });
  };

  return (
    <Wrapper>
      <Box>
        <TabHeader>
          {TABS.map((tab) => (
            <TabButton active={activeTab === tab.id} key={tab.id}>
              <button onClick={() => handleTabClick(tab.id)}>{tab.name}</button>
            </TabButton>
          ))}
        </TabHeader>
        {activeTabContent && <TabContent>{activeTabContent}</TabContent>}
      </Box>
    </Wrapper>
  );
};
