import styled from 'styled-components';
import tw from 'twin.macro';
import { slide as Menu } from 'react-burger-menu';
import { styles } from './topMenuStyles';
import { CenterWrapper } from '../CommonWrappers';
import { BorderButton } from '../Button';
import { useIsAuthenticated } from '@azure/msal-react';
import { VRCMemberHubPhoneLogo } from '../svgs/logo/VRCMemberHubPhoneLogo';
import { BASE_ABSOLUTE_URL } from 'configs/constants';
import { logout } from 'helpers';

const HamburgerContainer = styled.div`
  width: 90%;
  ${tw`
    absolute cursor-pointer left-5 block sm:hidden top-0
  `}
`;

const NavItems = tw.ul`
  list-none
  w-full
  h-auto
  lg:w-auto
  lg:h-full
  flex
  lg:ml-20
  justify-center
  items-center
`;

const NavItem = styled.li`
  color: #e1dbd2;
  height: 3.125rem;
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem;
  letter-spacing: 0.1em;
  &:hover {
    ${tw`
      opacity-50
    `}
  }
  ${tw`
    flex
    items-center
    pl-5
    cursor-pointer
    text-xs
    transition-colors
    transition-duration[300ms]
    hover:text-gray-200
    font-bold
  `}
`;

const navItems = (
  <NavItems>
    <NavItem>
      <a href="https://vrc.com.au" target="_blank" rel="noreferrer noopener">
        VRC WEBSITE
      </a>
    </NavItem>
  </NavItems>
);

const ImageInner = styled.div`
  ${tw`
    mb-11
  `}
`;

const LogoutButton = styled(BorderButton)`
  border-color: white;
  letter-spacing: 0.1em;
  ${tw`
    h-11 w-11/12 mt-7
  `}
`;

export const TopHamburgerMenu = () => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <HamburgerContainer>
      <Menu
        styles={styles}
        customBurgerIcon={
          <img alt="VRC Logo" src={`${BASE_ABSOLUTE_URL}/images/icon.svg`} />
        }>
        <CenterWrapper>
          <CenterWrapper>
            <ImageInner>
              <VRCMemberHubPhoneLogo />
            </ImageInner>
          </CenterWrapper>
        </CenterWrapper>
        {navItems}
        <CenterWrapper>
          <CenterWrapper>
            {isAuthenticated && (
              <LogoutButton onClick={logout}>LOGOUT</LogoutButton>
            )}
          </CenterWrapper>
        </CenterWrapper>
      </Menu>
    </HamburgerContainer>
  );
};
