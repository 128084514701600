import * as yup from 'yup';
import { BUSINESS_PHONE_REGEX, MOBILE_NUMBER_REGEX } from 'configs';

const AddressSchema = yup.object({
  street1: yup
    .string()
    .transform((value) => (value ? value : null))
    .nullable()
    .required('Address Line 1 is required.'),
  suburb: yup
    .string()
    .transform((value) => (value ? value : null))
    .nullable()
    .required('Suburb is required.'),
  state: yup
    .string()
    .transform((value) => (value ? value : null))
    .nullable()
    .required('State is required.'),
  postCode: yup
    .string()
    .transform((value) => (value ? value : null))
    .nullable()
    .required('Postcode is required.'),
  country: yup
    .string()
    .transform((value) => (value ? value : null))
    .nullable()
    .required('Country is required.'),
});

const AddressesSchema = yup.object().shape({
  physical: AddressSchema,
  postal: AddressSchema,
  billing: AddressSchema,
});

const PhoneSchema = yup.object().shape({
  personalMobile: yup.object().when('preferredPhone', {
    is: 'Personal Mobile',
    then: yup.object({
      label: yup.string(),
      value: yup
        .string()
        .nullable()
        .required('Preferred phone cannot be blank.')
        .matches(MOBILE_NUMBER_REGEX, {
          excludeEmptyString: true,
        }),
    }),
    otherwise: yup.object({
      label: yup.string(),
      value: yup.string().nullable().matches(MOBILE_NUMBER_REGEX, {
        excludeEmptyString: true,
      }),
    }),
  }),
  businessPhone: yup.object().when('preferredPhone', {
    is: 'Business Phone',
    then: yup.object({
      label: yup.string(),
      value: yup
        .string()
        .nullable()
        .required('Preferred phone cannot be blank.')
        .matches(BUSINESS_PHONE_REGEX, {
          excludeEmptyString: true,
        }),
    }),
    otherwise: yup.object({
      label: yup.string(),
      value: yup.string().nullable().matches(BUSINESS_PHONE_REGEX, {
        excludeEmptyString: true,
      }),
    }),
  }),

  businessMobile: yup.object().when('preferredPhone', {
    is: 'Business Mobile',
    then: yup.object({
      label: yup.string(),
      value: yup
        .string()
        .nullable()
        .required('Preferred phone cannot be blank.')
        .matches(MOBILE_NUMBER_REGEX, {
          excludeEmptyString: true,
        }),
    }),
    otherwise: yup.object({
      label: yup.string(),
      value: yup.string().nullable().matches(MOBILE_NUMBER_REGEX, {
        excludeEmptyString: true,
      }),
    }),
  }),

  preferredPhone: yup.string(),
});

export const schema = yup.object().shape({
  phoneNumbers: PhoneSchema,
  addresses: AddressesSchema,
  occupation: yup.string().nullable(),
  industry: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
    })
    .nullable(),
});
