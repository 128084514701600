import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { offerHelpers } from 'helpers';
import { Container, Price, PriceText } from './styles';
import { Divider } from 'components';
import { useMembershipService } from 'services';
import { OfferType } from 'type';
import {
  RenewalMembershipLoader,
  FormDataType,
  ItemType,
  RadioCheckboxType,
} from 'pages/membership';
import {
  useResponsivePrice,
  CHECKBOX_UPGRADES,
  RADIO_UPGRADES,
  PRICES,
} from '../utils';

import RenewalMembershipNonFinancial from './renewal/RenewalMembershipNonFinancial';
import RenewalMembershipFinancial from './renewal/RenewalMembershipFinancial';
import RenewalOptionalProducts from './renewal/RenewalOptionalProducts';

interface IRenewalMembershipProps {}

const RenewalMembership: React.FC<IRenewalMembershipProps> = () => {
  const { responsivePriceLabel, responsivePriceValue } = useResponsivePrice();
  const { watch, getValues, setValue, resetField } =
    useFormContext<FormDataType>();

  const offersPackage = useMembershipService((s) => s.offers.data);
  const offersData = offersPackage?.offers;
  const totalAmount = +watch('totalAmount');

  const [selected, setSelected] = useState<OfferType>();

  const recalculatePrices = useCallback(() => {
    let subtotal = 0;
    let gst = 0;
    let amount = 0;
    const sum = (upgrade: ItemType | null) => {
      if (!upgrade) return;
      const purchased = upgrade.status === 'Purchased';
      subtotal += purchased ? 0 : upgrade.priceExcGst * upgrade.quantity || 0;
      gst += purchased ? 0 : upgrade.gst * upgrade.quantity || 0;
      amount += purchased ? 0 : upgrade.priceIncGst * upgrade.quantity || 0;
    };
    getValues(RADIO_UPGRADES).forEach((field) => field.forEach(sum));
    getValues(CHECKBOX_UPGRADES).forEach((field) => field.forEach(sum));
    setValue('subtotal', subtotal);
    setValue('gst', gst);
    setValue('totalAmount', amount);
  }, [getValues, setValue]);

  const resetGroups = useCallback(() => {
    CHECKBOX_UPGRADES.forEach((upgrade) => resetField(upgrade));
  }, [resetField]);

  const handleOfferSelected = useCallback(
    (selected: RadioCheckboxType) => {
      const offer = selected as OfferType;
      setSelected(offer);
      resetGroups();
      recalculatePrices();
    },
    [resetGroups, recalculatePrices],
  );

  if (!offersData) return <RenewalMembershipLoader />;

  const hasPurchasedOffer = offerHelpers.getPurchasedOffer(offersData);

  return (
    <Container>
      {!hasPurchasedOffer ? (
        <RenewalMembershipNonFinancial
          offersPackage={offersPackage}
          selectedOffer={selected}
          handleOfferSelected={handleOfferSelected}
          recalculatePrices={recalculatePrices}
        />
      ) : (
        <RenewalMembershipFinancial
          offersPackage={offersPackage}
          handleOfferSelected={handleOfferSelected}
        />
      )}
      {selected && (
        <RenewalOptionalProducts
          selected={selected}
          recalculatePrices={recalculatePrices}
        />
      )}
      <Divider height="3px" marginVertical="2rem" bgColor="#201547" />
      {!!totalAmount &&
        PRICES.map(({ label, field, isShown }, index) => {
          return isShown ? (
            <Price key={`price_${index}`}>
              <PriceText as={responsivePriceLabel}>{label}</PriceText>
              <PriceText as={responsivePriceValue}>{`$${(+(
                watch(field) ?? 0
              )).toFixed(2)}`}</PriceText>
            </Price>
          ) : null;
        })}
    </Container>
  );
};

export default RenewalMembership;
