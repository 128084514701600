import { useMediaQuery } from 'react-responsive';
import { deviceSize } from 'components/shared/types';
import { RadioCheckbox, RenewalAccordion } from 'pages/membership';
import { OfferProductType, OfferType, OffersPackageType } from 'type';
import { offerHelpers, preferenceHelpers } from 'helpers';
import { useContactService } from 'services';
import { Divider } from 'components';

interface IRenewalMembershipNonFinancialProps {
  offersPackage: OffersPackageType;
  selectedOffer: OfferType | undefined;
  handleOfferSelected: (selected: OfferType | OfferProductType) => void;
  recalculatePrices: () => void;
}

const RenewalMembershipNonFinancial = ({
  offersPackage,
  selectedOffer,
  handleOfferSelected,
  recalculatePrices,
}: IRenewalMembershipNonFinancialProps) => {
  const { available, offersComparator } = offerHelpers;
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });
  const offers = offersPackage.offers.filter(available).sort(offersComparator);
  const joiningFee = offersPackage.joiningFee;
  const allMandatory = selectedOffer?.additionalProductGroups?.find(
    ({ groupingType }) => groupingType === 'AllMandatory',
  );

  const myMembershipsData = useContactService((s) => s.myMemberships.data);
  const myMembership = myMembershipsData?.[0];
  const isPreseason = myMembership?.seasonStatus === 'Preseason';
  const autoRenew = myMembership?.autoRenew;
  const isOldMemberMidseason = !isPreseason && autoRenew;
  const statusCardText = isOldMemberMidseason ? 'MISSED' : '';

  return (
    <RenewalAccordion title="Your Membership">
      {offers.map((offer) => {
        const { subscriptionProductCode } = offer;
        const hasManyOffers = offers.length > 1;
        const defaultSelected = offer === offers[0];
        if (offer.status !== 'Available') return null;
        if (defaultSelected) preferenceHelpers.offer.setDefault([offer]);
        return (
          <RadioCheckbox
            key={subscriptionProductCode}
            field="offer"
            upgrade={offer}
            onClick={handleOfferSelected}
            defaultSelected={defaultSelected}
            showSelect={hasManyOffers}
            statusCardText={statusCardText}
            showTick={isOldMemberMidseason}
          />
        );
      })}
      {allMandatory?.products?.filter(available)?.map((product) => {
        return (
          <RadioCheckbox
            key={product.id}
            field="AllMandatory"
            upgrade={product}
            onClick={recalculatePrices}
            defaultSelected
            showSelect={false}
            statusCardText={statusCardText}
            showTick={isOldMemberMidseason}
            multiSelectable
          />
        );
      })}
      {joiningFee?.status === 'Available' && (
        <>
          <Divider marginVertical="1.5rem" height="2px" bgColor="#E1E1DF" />
          <RadioCheckbox
            key={joiningFee.id}
            field="JoiningFee"
            upgrade={joiningFee}
            upgradeInfo={joiningFee.message}
            onClick={recalculatePrices}
            defaultSelected
            showSelect={false}
            statusCardText={statusCardText}
            showTick={isOldMemberMidseason}
            showCount={false}
          />
          <Divider
            height="0"
            bgColor="transparent"
            marginVertical={isMobile ? '-1.4rem' : '-2.2rem'}
          />
        </>
      )}
    </RenewalAccordion>
  );
};

export default RenewalMembershipNonFinancial;
